<template>
  <a-card>
    <div class="flex-1">
      <div>
        <div class="mb-6">
          <div class="bg-white rounded-lg px-5 pb-5">
            <div class="flex justify-start items-center h-[68px] border-b-[1px] border-[#e5e5e5] capitalize">
              <div class="flex-auto flex justify-start items-center">
                <div></div>
                <div class="font-bold text-[#fe802d] text-2xl" style="color: darkorange;">淘宝小号透视</div>
                <div class="flex-1">
                  <div class="tool-header-content">
                    <p>优惠套餐：购买套餐可低至 0.5 积分/次</p>
                  </div>
                </div>
              </div>
              <div>
                <div class="tool-header-right text-base text-[#333]">
                  <div class="flex justify-start items-center mr-3"><img class="w-6 h-6 mr-2"
                      src="/zhaoyaojing_files/biaoti-icon1.png" alt=""><span>单次：积分</span></div>
                  <div class="flex justify-start items-center"><img class="w-6 h-6 mr-2"
                      src="/zhaoyaojing_files/biaoti-icon2.png" alt=""><span>会员当日次数：0/0次</span></div>
                </div>
              </div>
            </div>
            <div class="flex justify-between py-8 px-4">
              <div class="w-[300px] mr-5 scan">
                <img src="/zhaoyaojing_files/zyj-tbxh-img1.png" alt="">
              </div>
              <div class="right-side">
                <div class="content">
                  <div class="item">
                    <div class="qrcode-box"><img alt="" :src="taoqiImg ">
                    </div><strong>淘气值查询</strong>
                    <p>淘气值可解读为账户购买力</p>
                  </div>
                  <div class="item">
                    <div class="qrcode-box">
                      <img alt="" :src="commentImg">
                    </div><strong>评价管理</strong>
                    <p>查看买家累计信用，好评率</p>
                  </div>
                  <div class="item">
                    <div class="qrcode-box">
                      <img alt="" :src="xinyupingjiImg">
                    </div><strong>信誉评级</strong>
                    <p>查看账号信用是否优质</p>
                  </div>
                  <div class="item">
                    <div class="qrcode-box"> <img alt="" :src="sexAgeImg"></div><strong>性别和年龄</strong>
                    <p>真实性别不能修改</p>
                  </div>
                  <div class="item">
                    <div class="qrcode-box">
                      <img alt="" :src="tiyanImg">
                    </div><strong>体验中心</strong>
                    <p>查看账号是否存在违规</p>
                  </div>
                  <div class="item">
                    <div class="qrcode-box">
                      <img :src="buyLogImg" alt="购买记录二维码" />
                    </div><strong>购买记录</strong>
                    <p>查看已买到的宝贝清单</p>
                  </div>
                </div>
                <div
                  class="w-full h-[40px] bg-[#fff6e6] text-[#fd802e] rounded-md flex justify-center items-center cursor-pointer">
                  <span>点击返回照妖镜-全网最新降权骗子</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </a-card>
</template>

<script setup>
import { getXiaoHaoList } from '@api'
import { reactive, onMounted, ref } from 'vue'
import QRCode from 'qrcode'

const form = reactive({
  taoqi_check: '',
  comment: '',
  xinyupingji: '',
  sex_age: '',
  tiyan_center: '',
  buy_log: ''
})

// 用于存储二维码图片的 URL
const taoqiImg = ref('')
const commentImg = ref('')
const xinyupingjiImg = ref('')
const sexAgeImg = ref('')
const tiyanImg = ref('')
const buyLogImg = ref('')

const generateQRCode = async (value, imgRef) => {
  try {
    const url = await QRCode.toDataURL(value, { errorCorrectionLevel: 'H' })
    imgRef.value = url
  } catch (error) {
    console.error(error)
  }
}

const getQrcode = async () => {
  try {
    const res = await getXiaoHaoList()
    const data = res.data.data
    form.taoqi_check = data.taoqi_check || ''
    form.comment = data.comment || ''
    form.xinyupingji = data.xinyupingji
    form.sex_age = data.sex_age || ''
    form.tiyan_center = data.tiyan_center
    form.buy_log = data.buy_log || ''
  } catch (error) {
    console.log(error)
  }
}

onMounted(async () => {
  await getQrcode()
  if (form.taoqi_check) await generateQRCode(form.taoqi_check, taoqiImg)
  if (form.comment) await generateQRCode(form.comment, commentImg)
  if (form.xinyupingji) await generateQRCode(form.xinyupingji, xinyupingjiImg)
  if (form.sex_age) await generateQRCode(form.sex_age, sexAgeImg)
  if (form.tiyan_center) await generateQRCode(form.tiyan_center, tiyanImg)
  if (form.buy_log) await generateQRCode(form.buy_log, buyLogImg)
})
</script>

<style>
.right-side .content .item {
    align-items: center;
    border: 1px solid #e5e5e5;
    border-radius: 6px;
    display: inline-block;
    flex-direction: column;
    height: 220px;
    justify-content: center;
    margin-bottom: 18px;
    margin-right: 16px;
    width: 220px;
}
.right-side {
    flex: 1 1 0%;
}
.scan img{
    height: 500px
}
.right-side .content[data-v-1d01e75d] {
    display: flex;
    flex-wrap: wrap;
}
.right-side .content .item .qrcode-box {
    background-image: url(/public/zhaoyaojing_files/zyj-tbxh-img2.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 50%;
    margin: 24px auto 16px;
    width: 50%;
}
.right-side .content .item strong {
    color: var(--primary-color-3);
    font-size: 20px;
    line-height: 30px;
}
.right-side .content .item p {
    color: #666;
    font-size: 12px;
    line-height: 18px;
}
.tool-header-content {
    background-image: url(/public/zhaoyaojing_files/coupon-bg.png);
    color: var(--bg-color);
    font-size: 14px;
    font-weight: 400;
    height: 20px;
    line-height: 20px;
    text-align: center;
    width: 260px;
}
.tool-header-right {
    align-items: center;
    display: flex;
}
.text-\[\#fd802e\] {
    --tw-text-opacity: 1;
    color: rgb(253 128 46 / var(--tw-text-opacity));
}
.bg-\[\#fff6e6\] {
    --tw-bg-opacity: 1;
    background-color: rgb(255 246 230 / var(--tw-bg-opacity));
}
.rounded-md {
    border-radius: .375px;
}
.justify-center {
    justify-content: center;
}
.items-center {
    align-items: center;
}
.cursor-pointer {
    cursor: pointer;
}
.w-full {
    width: 100%;
}
.h-\[40px\] {
    height: 40px;
}
.flex {
    display: flex;
}
</style>
