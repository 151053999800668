<template>
  <a-button @click="handleClick" type="outline" status="warning" style="width: 125px;height: 40px;border-radius: 5px;font-size: large;margin-right: 30px;"  >查看</a-button>
  <a-modal v-model:visible="visible" @cancel="handleCancel" :on-before-ok="handleBeforeOk" unmountOnClose>
    <p>
      <strong>clientId :</strong>
      <span style="justify-content: center;">{{ userInfo.client_id }}</span>
      <a-button style="float: right;"
      type="primary"
      status="warning"
      v-clipboard="userInfo.client_id"
      v-clipboard:success="clipboardSuccessHandler"
      v-clipboard:error="clipboardErrorHandler"
      >复制</a-button>
    </p>
    <p style="padding-top: 3rem;"><strong>clientSecret :</strong>
      <span style="justify-content: center;">
      {{ userInfo.client_secret }}</span>
      <a-button style="float: right;"
      type="primary"
      status="warning"
      v-clipboard="userInfo.client_secret"
      v-clipboard:success="clipboardSuccessHandler"
      v-clipboard:error="clipboardErrorHandler"
      >
      复制
    </a-button>
    </p>
  </a-modal>
</template>
<script>
import { ref, reactive } from 'vue';
import { Message } from '@arco-design/web-vue';
import { getUserInfo } from '@api'

export default {
  setup () {
    const visible = ref(false);
    const userInfo = reactive({
      client_id: '',
      client_secret: ''
    });

    const fetchData = async () => {
      try {
        const userRes = await getUserInfo();
        Object.assign(userInfo, userRes.data);
        console.log(userInfo.client_id);
      } catch (error) {
        console.log(error);
      }
    };

    const clipboardSuccessHandler = async () => {
      Message.success('已成功复制到剪贴板')
    }

    const clipboardErrorHandler = async () => {
      Message.error('复制失败，请联系管理员处理')
    }

    const handleClick = () => {
      visible.value = true;
      fetchData();
    };
    const handleBeforeOk = async () => {
      await new Promise(resolve => setTimeout(resolve, 3000));
      return true;
    };
    const handleCancel = () => {
      visible.value = false;
    }

    return {
      visible,
      handleClick,
      userInfo,
      clipboardSuccessHandler,
      clipboardErrorHandler,
      handleBeforeOk,
      handleCancel,
      fetchData
    }
  }
}
</script>
