import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ArcoVue from '@arco-design/web-vue'
import '@arco-design/web-vue/dist/arco.css'
import './assets/css/tailwind.css'
import './assets/css/global.css'
import './assets/css/global2.css'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import Clipboard from 'v-clipboard'
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import ArcoVueIcon from '@arco-design/web-vue/es/icon';
import axios from 'axios';
import { getInfo } from './api/api'
import { useAuthStore } from './stores/auth'
import './assets/css/htmlView.css'

const pinia = createPinia();

pinia.use(piniaPluginPersistedstate)

createApp(App).use(pinia).use(Clipboard).use(router).use(ArcoVue).use(ElementPlus).use(ArcoVueIcon).mount('#app')

const debounce = (fn, delay) => {
  let timer
  return (...args) => {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      fn(...args)
    }, delay)
  }
}
axios.interceptors.request.use(
  (config) => {
    const authStore = useAuthStore();
    const token = authStore.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

function setFavicon (url) {
  const link = document.querySelector("link[rel='icon']") || document.createElement('link');
  link.rel = 'icon';
  link.href = url;
  document.head.appendChild(link);
}

async function fetchFavicon () {
  try {
    const response = await getInfo();
    const faviconUrl = response.data.plate_icon;
    setFavicon(faviconUrl);
  } catch (error) {
    console.error('Failed to fetch favicon:', error);
  }
}

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/sw.js').then(registration => {
      console.log('Service Worker registered with scope:', registration.scope);
    }).catch(error => {
      console.error('Service Worker registration failed:', error);
    });
  });
}

fetchFavicon();

const _ResizeObserver = window.ResizeObserver
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor (callback) {
    callback = debounce(callback, 200)
    super(callback)
  }
}
