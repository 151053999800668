<template>
  <div class="flex-1">
    <div class="mb-6">
      <div class="bg-white rounded-lg px-5 pb-5">
        <div class="flex justify-start items-center h-[68px] border-b-[1px] border-[#e5e5e5] capitalize"
          style="padding: 10px;">
          <div class="flex-auto flex justify-start items-center">
            <div></div>
            <div class="font-bold text-[#fe802d] text-2xl mr-4" style="color: #fe802d;">淘宝卡首屏</div>
            <div class="flex-1">
              <div class="tool-header-content">
                <p>优惠套餐：购买套餐可低至 0.5 积分/次</p>
              </div>
            </div>
          </div>
          <div>
            <div class="tool-header-right text-base text-[#333]">
              <div class="flex justify-start items-center mr-3">
                <img class="w-6 h-6 mr-2" src="/zhaoyaojing_files/biaoti-icon1.png" alt="" />
                <span>单次：0积分</span>
              </div>
              <div class="flex justify-start items-center">
                <img class="w-6 h-6 mr-2" src="/zhaoyaojing_files/biaoti-icon2.png" alt="" />
                <span>会员当日次数：0/0次</span>
              </div>
            </div>
          </div>
        </div>
        <div class="pt-5 pb-7 flex justify-between items-start">
          <div class="left-side flex-1">
            <el-form label-position="right" class="mt-5">
              <el-form-item class="asterisk-left border-b border-[#efecec]" label="场景">
                <el-form-item class="asterisk-left border-[#efecec] border-b" :label="''" style="padding-left: 27px;width:1200px;">
                  <template v-for="(scene, index) in scenes" :key="index">
                    <a-space>
                      <el-button style="margin:5px 5px 5px 5px;border: 1px solid #DE6E22;padding: 15px 15px 15px 15px ;"
                        :type="scene.isActive ? 'primary' : ''" @click="selectScene(index)">
                        {{ scene.name }}
                      </el-button>
                    </a-space>
                  </template>
                </el-form-item>
              </el-form-item>
              <template v-if="scenes.length > 0 && currentSceneIndex !== null">
                <template v-for="(inputField, inputIndex) in scenes[currentSceneIndex].json"
                  :key="`field-${inputIndex}`">
                  <el-form-item :label="inputField.name" :required="inputField.required"
                    class="is-required asterisk-left flex items-center w-[60%] mx-auto">
                    <el-input v-model="inputField.value" :placeholder="`请输入${inputField.name}`" style="height: 40px"></el-input>

                  </el-form-item>
                </template>
                <el-form-item class="asterisk-left flex w-[60%] mx-auto" style="color: red;">
                <div v-html="generatedTooltips"></div>
              </el-form-item>
              </template>

              <el-form-item class="asterisk-left flex items-center w-[60%] mx-auto">
                <p class="text-sm text-[#e63400]" style="color:#e63400;">{{ errorMessage }}</p>
              </el-form-item>

              <el-form-item class="asterisk-left flex items-center w-[60%] mx-auto">
                <el-button type="primary" size="large" style="width: 200px" @click="generateQRCode">生成二维码</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div class="right-side flex justify-center items-center flex-col" style="margin-top: 20px;">
            <div class="qrcode w-[260px] h-[260px]" style="width: 260px;height: 260px;">
              <div v-if="qrCodeData && qrCodeData.imgSrc">
                <img :src="qrCodeData.imgSrc" style="width: 260px;height: 260px;" />
                <span style="color: #fe802d;padding-top: 5px;">打开淘宝App 扫一扫</span>
              </div>
              <div v-else>二维码将显示在这里</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mb-6">
      <div class="bg-white rounded-lg px-5 pb-5">
        <div class="flex justify-start items-center h-[68px] border-[#e5e5e5] capitalize border-none">
          <div class="flex-auto flex justify-start items-center">
            <div>
              <div class="flex justify-start items-center"><img src="../../assets/img/zyj-icon1-jilu.png" alt=""><span
                  class="text-xl text-[#333] font-bold p-2">历史记录</span></div>
            </div>
            <div class="font-bold text-[#fe802d] text-2xl mr-4"></div>
            <div class="flex-1"></div>
          </div>
          <div></div>
        </div>
        <div class="mt-5 bg-white rounded-lg px-5 pb-5">
          <a-table :columns="columns" :data="inviteDetails2" row-key="id" pagination="false">
            <template #qrcode="{ record }">
              <showQrCode :url="record.qr" />
            </template>
          </a-table>
          <el-pagination :total="total" v-model:current-page="currentPage" :page-size="pageSize"
            @size-change="handlePageSizeChange" @current-change="handlePageChange"></el-pagination>
        </div>
        <!-- Tab选项卡部分 -->

      </div>
    </div>
    <div class="mb-6" style="text-align: left;">
      <div class="bg-white rounded-lg px-5 pb-5">
        <el-tabs v-model="activeTab" class="mt-4 tip-text">
          <el-tab-pane label="功能介绍">
            <p>1.用户扫描二维码后，会在生意参谋中，显示该访客是通过免费来源访问到你的商品的，商品也会在手套首页商品列表靠前的位置</p>
            <p>2.增加商品成交的途径，提升商品出现首页概率，可通过使用此功能提高排名</p>
            <p>3.非搜渠道，增加基础权重（用户反馈）</p>
            <p>4.配合旺旺打标，标签推送下单能实现较小的成本以提升交易后的权重</p>
            <p>5.通过这样卡位的方式将商品卡在手套首页的商品列表中，提升商品的搜索路径的权重值</p>
          </el-tab-pane>
          <el-tab-pane label="注意事项">
            <p>1.已屏蔽拉黑的商品很难卡出来，即使卡成功对于提升权重也无需矮柜。</p>
            <p>2.新品使用该功能的时候，要确保店铺有一定的权重，零基础的店铺或新品卡到商品较难</p>
            <p>3.入池的商品卡首屏才能被手淘首页收录入池</p>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, computed } from 'vue'
import { getTbScreen, tbScreen, getScreenList } from '@api'
import showQrCode from '../order/component/showQrCode.vue'
import QRCode from 'qrcode';
import { Message } from '@arco-design/web-vue';

export default {
  name: 'TaobaoCardFront',
  components: {
    showQrCode
  },
  setup () {
    const scenes = ref([
    ])
    const currentSceneIndex = ref(null);
    const generatedTooltips = computed(() => {
      return scenes.value.map(scene => {
        if (scene.isActive && scene.remark.length) {
          return `
            <div class="remarks">
                <p>${scene.remark}</p>
              </div>
          `;
        }
        return '';
      }).join('');
    });
    const columns = ref([
      {
        title: '生成时间',
        dataIndex: 'create_time',
        key: 'create_time',
        align: 'center'
      },
      {
        title: '类型',
        dataIndex: 'type',
        key: 'type',
        align: 'center'
      },
      {
        title: 'ID',
        dataIndex: 'url',
        key: 'url',
        align: 'center'
      },
      {
        title: '查看',
        dataIndex: 'qr',
        key: 'qr',
        slotName: 'qrcode',
        align: 'center'
      }
    ])
    const inputField = ref('')
    const inviteDetails = ref([])
    const inviteDetails2 = ref([])
    const errorMessage = ref('')
    const qrCodeData = reactive({
      imgSrc: ''
    })

    const getTable = async () => {
      try {
        const res = await getScreenList({ platform_id: 1 })
        inviteDetails2.value = res.data.data.list
      } catch (error) {
        console.log(error)
      }
    }
    getTable();
    const getTYpe = async () => {
      try {
        const typeRes = await getTbScreen();
        scenes.value = typeRes.data.data;
        if (scenes.value.length > 0) {
          selectScene(0);
        }
      } catch (error) {
        console.error(error);
      }
    }

    getTYpe();
    const selectScene = (index) => {
      scenes.value.forEach((scene, idx) => {
        scene.isActive = idx === index;
      });
      currentSceneIndex.value = index;
    };

    const generateQRCode = async () => {
      if (currentSceneIndex.value === null) {
        errorMessage.value = '请选择一个场景';
        return;
      }
      const currentScene = scenes.value[currentSceneIndex.value];

      const params = { type: currentScene.type };
      let allFieldsFilled = true;

      for (const inputField of currentScene.json) {
        if (inputField.required && !inputField.value) {
          errorMessage.value = `请输入${inputField.name}`;
          allFieldsFilled = false;
          break;
        }
        params[inputField.field] = inputField.value;
      }

      if (!allFieldsFilled) return;

      errorMessage.value = '';

      try {
        const tbRes = await tbScreen(params);

        if (tbRes.data.code !== 20000) {
          Message.warning(tbRes.data.msg)
        } else {
          Message.success(tbRes.data.msg)
        }

        const qrCodeDataString = tbRes.data.data; // 获取二维码数据

        // 确保数据有效
        if (!qrCodeDataString) {
          errorMessage.value = '二维码数据无效';
          return;
        }

        const canvas = document.createElement('canvas');
        await QRCode.toCanvas(canvas, qrCodeDataString, { errorCorrectionLevel: 'H' });

        qrCodeData.imgSrc = canvas.toDataURL(); // 将二维码转换为图片源

        console.log(qrCodeData.imgSrc); // 确保引用正确的变量
        inviteDetails.value = tbRes.data.details; // 获取邀请详情
        getTable();
      } catch (error) {
        console.error(error); // 打印错误信息
        errorMessage.value = error.message || '生成二维码失败';
      }
    };

    return {
      scenes,
      inputField,
      errorMessage,
      qrCodeData,
      columns,
      inviteDetails,
      inviteDetails2,
      currentSceneIndex,
      selectScene,
      getTYpe,
      generateQRCode,
      getTable,
      generatedTooltips
    }
  }
}
</script>

<style scoped>
 /deep/ .el-form-item__label{

font-size: 1rem;

}

.tip-text {
    color: #333;
    font-size: 16px !important;
    line-height: 30px;
    padding: 10px 0 20px;
}
</style>
