<template>
  <div class="flex-1">
    <div class="mb-6">
      <div class="bg-white rounded-lg px-5 pb-5">
        <div class="flex justify-start items-center h-[68px] border-b-[1px] border-[#e5e5e5] capitalize">
              <div class="flex-auto flex justify-start items-center">
                <div></div>
                <div class="font-bold text-[#fe802d] text-2xl" style="color: darkorange;">旺旺号</div>
                <span class="px-4 border-l border-[#e5e5e5] font-500 text-[#333] text-xl">QQ号/微信号</span>
                <div class="flex-1">
                  <div class="tool-header-content">
                    <p>优惠套餐：购买套餐可低至 0.5 积分/次</p>
                  </div>
                </div>
              </div>
              <div>
                <div class="tool-header-right text-base text-[#333]">
                  <div class="flex justify-start items-center mr-3"><img class="w-6 h-6 mr-2"
                      src="/zhaoyaojing_files/biaoti-icon1.png" alt=""><span>单次：积分</span></div>
                  <div class="flex justify-start items-center"><img class="w-6 h-6 mr-2"
                      src="/zhaoyaojing_files/biaoti-icon2.png" alt=""><span>会员当日次数：0/0次</span></div>
                </div>
              </div>
            </div>
        <a-form class="w-[50%]" label-position="right" label-width="120px" style="margin-top: 59px; margin-left: -215px;">
          <a-form-item label="旺旺号" required>
            <a-textarea
              placeholder="输入旺旺号，可查询买家信誉"
              v-model="wangwangNumber"
              style="background-color: white; height: 200px; border: 1px solid #c4c4c4;">
            </a-textarea>
          </a-form-item>
          <a-form-item class="ml-12.5">
            <span class="text-[#fd673e]">一行一个旺旺号，按Enter键换行，最多可输入10个</span>
          </a-form-item>
          <a-form-item class="ml-12.5">
            <a-button
              type="primary"
              size="large"
              @click="getAccounts"
              style="width: 200px; height: 40px; border-radius: 5px; background-color: darkorange;">
              查询
            </a-button>
            <a-button
              size="large"
              class="ml-5"
              style="width: 200px; height: 40px; border-radius: 5px; --el-color-primary: #999999; --el-border-color: #e5e5e5;"
              @click="clearInput">
              清空
            </a-button>
            <a-button
              size="large"
              class="ml-5"
              style="width: 200px; height: 40px; border-radius: 5px; border-color: darkorange; background-color: rgb(255, 244, 235); color: darkorange;"
              @click="goToShopTools(12)">
              黑号打标
            </a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>

    <div class="mb-6">
      <div class="bg-white rounded-lg px-5 pb-5">
        <div class="text-left pt-7.5">
          <a class="font-bold text-lg" style="flex: 0%;">查询记录:</a>
          <span class="text-red-600 ml-2.5">(该记录只保留1天)</span>
        </div>
        <div class="mt-5">
          <a-table :columns="columns" :data="tableData" bordered :scroll="{ x: 2300 }">
            <!-- 用于插槽内容 -->
            <template #null>
              <div class="text-red-600">无购买记录</div>
            </template>
            <template #nullname>
              <div class="text-red-600">-</div>
            </template>
            <template #buyWeek>
              <div class="text-red-600">0</div>
            </template>
            <template #zero>
              <div>0</div>
            </template>
          </a-table>
        </div>
      </div>
    </div>

    <div class="mb-6">
      <div class="bg-white rounded-lg px-5 pb-5">
        <a-tabs class="mt-4 text-left tip-text">
          <a-tab-pane key="1" title="平台建议">
            <p>1、信誉（建议三心起）</p>
            <p>2、淘龄（建议一年起，300多天）</p>
            <p>3、实名认证（必须认证，建议普通会员以上）</p>
            <p>4、看标记情况（不是0建议不要用）</p>
            <p>5、降权处置（被查过虚假交易，被删除过评价，建议不用）</p>
            <p>6、看一周被几个商家查过数据（一般超过10就注意是不是周超了）</p>
            <p>7、收到好评率低于95%建议不要用</p>
            <p>8、信誉区间查询（周均点数）周平均收货单数超过5, 建议不要用</p>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { checkAccounts, getCheckList } from '@api';
import { Message } from '@arco-design/web-vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const wangwangNumber = ref('');
const columns = ref([
  { title: '旺旺号', dataIndex: 'wangwang' },
  { title: '近期购买记录', dataIndex: 'null', slotName: 'null' },
  { title: '曾用名', dataIndex: 'null', slotName: 'nullname' },
  { title: '性别', dataIndex: 'sex' },
  { title: '买家信誉', dataIndex: 'buyer_reputation' },
  { title: '商家信誉', dataIndex: 'seller_reputation' },
  { title: '给别人的好评率', dataIndex: 'send_good_rate' },
  { title: '买家周平均', dataIndex: 'buyWeek', slotName: 'buyWeek' },
  { title: '淘龄', dataIndex: 'tao_ling' },
  { title: '实名认证', dataIndex: 'is_real' },
  { title: '注册天数', dataIndex: 'regist_day' },
  { title: '跑单', dataIndex: 'type' },
  { title: '骚扰', dataIndex: 'type_1' },
  { title: '骗子', dataIndex: 'type_2' },
  { title: '打假', dataIndex: 'type_3' },
  { title: '差评', dataIndex: 'type_4' },
  { title: '淘客拍单', dataIndex: 'type_5' },
  { title: '降权', dataIndex: 'type_6' },
  { title: '本周', dataIndex: 'regist_day', slotName: 'zero' },
  { title: '上周', dataIndex: 'regist_day', slotName: 'zero' },
  { title: '周单量', dataIndex: 'regist_day', slotName: 'zero' },
  { title: '月单量', dataIndex: 'regist_day', slotName: 'zero' }
]);

const tableData = ref([]);

const clearInput = () => {
  wangwangNumber.value = '';
};

const goToShopTools = (key) => {
  router.push({ name: 'shopTools', params: { key } });
};

const getAccounts = async () => {
  try {
    const searchValue = wangwangNumber.value;
    const accRes = await checkAccounts({ wangwangs: searchValue });
    if (accRes.data.code !== 20000) {
      Message.warning(accRes.data.msg);
    } else {
      Message.success(accRes.data.msg);
    }
    getTableData();
  } catch (error) {
    Message.warning('系统错误');
  }
};

const getTableData = async () => {
  try {
    const tableRes = await getCheckList({ type: 2 });
    tableData.value = tableRes.data.data.list;
  } catch (error) {
    console.error(error);
  }
};

// 初始化时获取表格数据
getTableData();
</script>

<style scoped>
.el-textarea__inner {
  min-height: 31px;
  width: 500px;
}
</style>
