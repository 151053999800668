<template>
  <footer class="bg-[#1e2334] zkzx-index-footer h-[380px] md:h-[380px]" style="width: 100%;height: 25rem;">
    <div class="flex flex-col  mx-auto">
      <div class="flex-1 flex justify-between w-full">
        <div class="flex justify-start">
          <div class="mr-[136px]" style="margin-left: 230px;">
            <div class="w-[180px] h-[50px] mt-[48px] mb-[16px]">
              <img
                class="w-full h-full"
                alt=""
                :src="footerLogo"
              />
            </div>
            <ul>
              <li>客服微信：{{ weixin }}</li>
              <li>客服QQ：{{ qq }}</li>
              <li>服务时间：{{ workTime }}</li>
            </ul>
          </div>
          <div class="mt-[58px]">
            <div class="text-[#f8f8f8] text-base mb-[20px]" style="min-width: 200px;">
              <strong>验号中心 </strong>
            </div>
            <ul>
              <li class="cursor-pointer" @click="goToShopTools(7)">
                照妖镜验号
              </li>
              <li class="cursor-pointer" @click="goToShopTools(9)">防P图验号</li>
              <li class="cursor-pointer" @click="goToShopTools(11)">小号透视</li>
              <li class="cursor-pointer" @click="goToShopTools(12)">黑号打标</li>
            </ul>
          </div>
          <div class="mt-[58px]">
            <div class="text-[#f8f8f8] text-base mb-[20px]" style="min-width: 200px;">
              <strong>卡首屏工具 </strong>
            </div>
            <ul>
              <li class="cursor-pointer" @click="goToShopTools(13)">淘宝卡首屏</li>
              <li class="cursor-pointer" @click="goToShopTools(15)">抖音卡首屏</li>
              <li class="cursor-pointer" @click="goToShopTools(16)">拼多多卡首屏</li>
            </ul>
          </div>
          <div class="mt-[58px]">
            <div class="text-[#f8f8f8] text-base mb-[20px]" style="min-width: 200px;">
              <strong>无痕秒单 </strong>
            </div>
            <ul>
              <li class="cursor-pointer" @click="goToShopTools(17)">淘宝无痕</li>
              <li class="cursor-pointer" @click="goToShopTools(19)">拼多多无痕</li>
            </ul>
          </div>
        </div>
        <div class="" style="margin-right: 14%;min-width: 200px;">
          <div class="mt-[58px]">
            <div class="text-[#f8f8f8] text-base mb-[16px]">
              <strong>联系我们</strong>
            </div>
            <div class="flex justify-start text-[#8f919a]">
              <div>
                <img
                  class="w-[100px] h-[100px] mb-3"
                  alt=""
                  :src="kfQr"
                /><span>扫一扫添加客服</span>
              </div>
              <div style="margin-left: 20px;">
                <img
                  class="w-[100px] h-[100px] ml-[20px] mb-3"
                  alt=""
                  :src="gzhQr"
                /><span>关注微信公众号</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full bg-[#1e2334]  border-t border-[#999] text-[#999] py-2" style="margin-top: 80px;">
        <div class="my-[18px] mx-auto text-center" style="margin-top: 20px;">{{ footerContent }}</div>
        <div class="my-[18px] mx-auto text-center" style="margin-top: 10px;" >
          {{  copyright }} {{ record  }}
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { getInfo } from '@api'
import { useRouter } from 'vue-router';

export default {
  data () {
    return {
      banner: '',
      copyright: '',
      des: '',
      footerContent: '',
      footerLogo: '',
      gzhQr: '',
      keyword: '',
      kfKey: '',
      kfQr: '',
      name: '',
      plateIcon: '',
      plateLogo: '',
      qq: '',
      record: '',
      registCode: '',
      statisticsCode: '',
      weixin: '',
      workTime: ''
    }
  },
  methods: {
    async fetchData () {
      try {
        const response = await getInfo()
        this.banner = response.data.data.banner
        this.copyright = response.data.data.copyright
        this.des = response.data.data.des
        this.footerContent = response.data.data.footer_content
        this.footerLogo = response.data.data.footer_logo
        this.gzhQr = response.data.data.gzh_qr
        this.keyword = response.data.data.keyword
        this.kfKey = response.data.data.kf_key
        this.kfQr = response.data.data.kf_qr
        this.name = response.data.data.name
        this.plateIcon = response.data.data.plate_icon
        this.plateLogo = response.data.data.plate_logo
        this.qq = response.data.data.qq
        this.record = response.data.data.record
        this.registCode = response.data.data.regist_code
        this.statisticsCode = response.data.data.statistics_code
        this.weixin = response.data.data.weixin
        this.workTime = response.data.data.work_time
      } catch (error) {
        console.error(error)
      }
    }
  },
  setup () {
    const router = useRouter();
    function goToShopTools (key) {
      router.push({ name: 'shopTools', params: { key } });
      console.log(key);
    }
    return {
      goToShopTools
    }
  },
  mounted () {
    this.fetchData()
  }
}
</script>

<style>
@media (min-width: 768px) {
    .md\:h-\[380px\] {
        height: 300px;
    }
}
.mt-\[58px\] {
    margin-top: 58px;
}
.mr-\[136px\] {
    margin-right: 136px;
}
.bg-\[\#1e2334\] {
    --tw-bg-opacity: 1;
    background-color: rgb(30 35 52 / var(--tw-bg-opacity));
}
.h-\[380px\] {
    height: 380px;

}
.w-\[100px\] {
    width: 100px;
}
.h-\[100px\] {
    height: 100px;
}
.mb-3 {
    margin-bottom: .75rem;
}
img {
    border-style: none;
}
img {
    border-style: none;
}
img {
    border-style: none;
}
img, video {
    height: auto;
    max-width: 100%;
}
audio, canvas, embed, iframe, img, object, svg, video {
    display: block;
    vertical-align: middle;
}
.w-full {
    width: 100%;
}
.h-full {
    height: 100%;
}
.zkzx-index-footer ul li{
    color: var(--footer-sub-title-color);
    font-size: 14px;
    line-height: 30px;
}
.w-\[180px\] {
    width: 180px;
}
.h-\[50px\] {
    height: 50px;
}
.mt-\[48px\] {
    margin-top: 48px;
}
.mb-\[16px\] {
    margin-bottom: 16px;
}
.zkzx-index-footer ul li{
    color: var(--footer-sub-title-color);
    font-size: 14px;
    line-height: 30px;
}menu, ol, ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.justify-start {
    justify-content: flex-start;
}
.flex {
    display: flex;
}.mr-\[136px\] {
    margin-right: 136px;
}.text-\[\#f8f8f8\] {
    --tw-text-opacity: 1;
    color: rgb(248 248 248 / var(--tw-text-opacity));
}
.text-base {
    font-size: 1px;
    line-height: 1.5px;
}
.mb-\[20px\] {
    margin-bottom: 20px;
}
text-\[\#999999\], .text-\[\#999\] {
    --tw-text-opacity: 1;
    color: rgb(153 153 153 / var(--tw-text-opacity));
}

.py-2 {
    padding-bottom: .5px;
    padding-top: .5px;
}
.border-\[\#999\] {
    --tw-border-opacity: 1;
    border-color: rgb(153 153 153 / var(--tw-border-opacity));
}
.border-t, .border-t-\[1px\] {
    border-top-width: 1px;
}
.w-full {
    width: 100%;
}.text-\[\#8f919a\] {
    --tw-text-opacity: 1;
    color: rgb(143 145 154 / var(--tw-text-opacity));
}
.justify-start {
    justify-content: flex-start;
}
.flex {
    display: flex;
}
@media (min-width: 768px) {
    .md\:h-\[380px\] {
        height: 380px;
    }
}

.bg-\[\#1e2334\] {
    --tw-bg-opacity: 1;
    background-color: rgb(30 35 52 / var(--tw-bg-opacity));
}
.h-\[380px\] {
    height: 380px;
}
</style>
