<template>
  <div class="flex-1">
    <div class="mb-5 bg-white rounded-lg px-5 pb-5">
      <div class="flex justify-start items-center h-[68px] border-b-[1px] border-[#e5e5e5] capitalize"
        style="padding: 10px;">
        <div class="flex-auto flex justify-start items-center" style="margin-top: 20px">
          <div class="font-bold text-[#fe802d] text-2xl mr-4" style="color: #fe802d">人工流量</div>
        </div>
        <div class="tool-header-right text-base text-[#333]">
        </div>
      </div>
      <div class="pt-5 pb-7" style="text-align:left;
      ">
        <div class="tool-form">
          <div class="tool-form-item">
            <div class="label label-required" style="text-align:left;color: #606266; font-size: 15px;
      ">优化平台:</div>
            <div class="content gap-10" style="
    margin-left: -16px;
">
              <el-button v-for="platform in platforms" :key="platform.key" :class="[
            'line-button',
            { 'active-button': activePlatform === platform.name },
          ]" @click="setActivePlatform(platform.name)">
                <img :src="platform.icon" alt="" class="mr-3" />
                {{ platform.text }}
              </el-button>
            </div>
          </div>

          <div class="tool-form-item" v-if="projects.length">
            <div class="label label-required" style="text-align:left;color: #606266; font-size: 15px;
      ">优化项目:</div>
            <div class="content gap-10" style="
    margin-left: -16px;
">
              <el-button v-for="project in projects" :key="project.id" :class="[
            'line-button',
            { 'active-button': activeProject === project },
          ]" @click="setActiveProject(project)">
                {{ project }}
              </el-button>
            </div>
          </div>

          <div class="tool-form-item" v-if="optimizationTypes.length" style="align-items: start">
            <div class="label label-required" style="text-align:left;color: #606266; font-size: 15px;">优化类型:</div>
            <div class="content gap-10" style="
    margin-left: -16px;
">
              <el-button v-for="type in optimizationTypes" :key="type" :class="[
            'line-button',
            { 'active-button': activeType === type }
          ]" @click="setActiveType(type)">
                {{ type }}
              </el-button>
            </div>
          </div>

          <div class="tool-form-item" style="">
            <div class="label" style="text-align:left;color: #606266; font-size: 15px;">业务介绍:</div>
            <div
              class="flex flex-row items-center w-[45rem] py-2.5 px-2.5 rounded border border-[#fe802d] border-solid bg-[#fdf6e9] text-xs"
              style="height: 35px; border-radius: 5px; ">
              <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor"
                class="arco-icon arco-icon-exclamation-circle-fill mr-2" stroke-width="4" stroke-linecap="butt"
                stroke-linejoin="miter" style="color: rgb(254, 128, 45);">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M24 44c11.046 0 20-8.954 20-20S35.046 4 24 4 4 12.954 4 24s8.954 20 20 20Zm-2-11a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v2Zm4-18a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V15Z"
                  fill="currentColor" stroke="none"></path>
              </svg>
              <div>访客通过【搜索关键词】进入浏览您的宝贝</div>
            </div>
          </div>

          <div class="tool-form-item" style="align-items: start" v-if="selectedFlowInc">
            <div class="label" style="text-align:left;color: #606266; font-size: 15px;">增值功能:</div>
            <a-checkbox-group v-model="selectedInc"
              v-if="selectedFlowInc.length > 0 && activeType === 'tb_app_pv_spec'">
              <a-checkbox v-for="item in selectedFlowInc" :key="item.inc_key" :value="item.inc_key">
                {{ item.inc_name }}(<span style="color: red;"> {{ item.cost }}元</span>)
              </a-checkbox>
            </a-checkbox-group>

            <div v-if="selectedFlowInc.length > 0 && activeType === 'tb_app_renqun'">
              <a-card>
                <div v-for="item in selectedFlowInc" :key="item.inc_key" style="display: flex;">
                  <p style="padding: 1rem 0 2rem 0;"><span style="float: left;">{{ item.inc_name }}</span>

                    <a-radio-group v-if="item.inc_name === '访客男女性别'" v-model="item.cost"
                      style="float: left;margin-left: 2rem;">
                      <a-radio>
                        随机男女
                      </a-radio>
                      <a-radio value="1.1">
                        只要男号
                      </a-radio>
                      <a-radio value="1.10">
                        只要女号
                      </a-radio>
                    </a-radio-group>

                    <a-switch v-if="item.inc_name === '货比三家'" style="float: left;margin-left: 2rem;"></a-switch>
                    <a-switch v-if="item.inc_name === '高消费人群'" style="float: left;margin-left: 2rem;"></a-switch>
                    <a-switch v-if="item.inc_name === '优质买号'" style="float: left;margin-left: 2rem;"></a-switch>

                    <a-radio-group v-if="item.inc_name === '访客人群标签'" v-model="item.cost"
                      style="float: left;width: 84%;margin-left: 2rem;">
                      <a-radio value="随机默认">随机默认</a-radio>
                      <a-radio value="时尚男装">时尚男装</a-radio>
                      <a-radio value="潮流女装">潮流女装</a-radio>
                      <a-radio value="服饰箱包">服饰箱包</a-radio>
                      <a-radio value="美食特产">美食特产</a-radio>
                      <a-radio value="汽车用品">汽车用品</a-radio>
                      <a-radio value="数码家电">数码家电</a-radio>
                      <a-radio value="宠物用品">宠物用品</a-radio>
                      <a-radio value="内衣内裤">内衣内裤</a-radio>
                      <a-radio value="护肤彩妆">护肤彩妆</a-radio>
                      <a-radio value="母婴串品">母婴串品</a-radio>
                      <a-radio value="居家日用">居家日用</a-radio>
                      <a-radio value="家纺家装">家纺家装</a-radio>
                      <a-radio value="文娱运动">文娱运动</a-radio>
                      <a-radio value="珠宝首饰">珠宝首饰</a-radio>
                      <a-radio value="其他商品">其他商品</a-radio>
                    </a-radio-group>
                  </p>
                </div>
                <div  class="screen-form-item">
                    <div  class="label"></div>
                    <div
                      class="flex flex-row items-center w-[45rem] py-2.5 px-2.5 rounded bg-[#fdf6e9] text-xs"><span
                        >筛选人群需要额外收费</span><span
                        class="font-medium text-red">1.200元</span></div>
                  </div>
              </a-card>
            </div>
          </div>

          <div class="tool-form-item">
            <div class="label label-required" style="text-align:left;color: #606266; font-size: 15px;">商品宝贝链接:</div>
            <a-input v-model="form.product_url" placeholder="请输入商品链接"></a-input>
          </div>

          <div class="tool-form-item">
            <div class="label" style="text-align:left;color: #606266; font-size: 15px;">商品标题:</div>
            <a-input v-model="form.products_title" placeholder="请手动输入宝贝标题"></a-input>
          </div>

          <div class="tool-form-item" style="align-items: start">
            <div class="label label-required" style="text-align:left;color: #606266; font-size: 15px;">搜索关键词:</div>
            <div class="flex flex-col">
              <div class="flex flex-row items-center mb-3" style="margin-left: -12px;">
                <a-input placeholder="请输入搜索关键词" v-model="searchKeyword"></a-input>
                <div
                  class="default-button line-button flex flex-row items-center justify-center w-fit active-button ml-2.5"
                  v-if="keywords.length > 0" @click="editKeyword(index)">
                  <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor"
                    class="arco-icon arco-icon-clock-circle" stroke-width="4" stroke-linecap="butt"
                    stroke-linejoin="miter">
                    <path d="M24 14v10h9.5m8.5 0c0 9.941-8.059 18-18 18S6 33.941 6 24 14.059 6 24 6s18 8.059 18 18Z">
                    </path>
                  </svg>
                  配置
                </div>
                <div
                  class="default-button line-button flex flex-row items-center justify-center w-fit active-button ml-2.5"
                  @click="addKeyword(searchKeyword)">
                  <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor"
                    class="arco-icon arco-icon-plus mr-2" stroke-width="4" stroke-linecap="butt"
                    stroke-linejoin="miter">
                    <path d="M5 24h38M24 5v38"></path>
                  </svg>
                  <span>添加</span>
                </div>
              </div>
              <div v-if="keywords.length > 0">
                <ul>
                  <li v-for="(keyword, index) in keywords" :key="index" class="flex items-center mb-2">
                    <a-input-number v-model="keywords[index]" placeholder="关键词" class="mr-2" />
                    <div
                      class="default-button line-button flex flex-row items-center justify-center w-fit active-button ml-2.5"
                      @click="editKeyword(index)">
                      <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor"
                        class="arco-icon arco-icon-clock-circle" stroke-width="4" stroke-linecap="butt"
                        stroke-linejoin="miter">
                        <path
                          d="M24 14v10h9.5m8.5 0c0 9.941-8.059 18-18 18S6 33.941 6 24 14.059 6 24 6s18 8.059 18 18Z">
                        </path>
                      </svg>
                      配置
                    </div>
                    <div
                      class="default-button line-button flex flex-row items-center justify-center w-fit active-button ml-2.5"
                      @click="removeKeyword(index)">
                      <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor"
                        class="arco-icon arco-icon-plus mr-2" stroke-width="4" stroke-linecap="butt"
                        stroke-linejoin="miter">
                        <path d="M5 24h38M24 5v38"></path>
                      </svg>
                      <span>删除</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="tool-form-item" v-if="!showTimeAllocation && keywords.length === 0">
            <div class="content gap-10 rounded-lg from-gray-50 to-gray-100">
              <div class="tool-form-item mb-4">
                <div class="label label-required">每天数量:</div>
                <div class="flex items-center">
                  <a-input-number v-model="dailyCount" placeholder="请输入每日数量" :min="0" :max="99999" />
                </div>
              </div>

              <div class="button-group flex gap-4 mb-4">
                <div
                  class="default-button line-button bg-[#f9f9f9] flex flex-row items-center justify-center w-fit ml-2.5"
                  @click="selectAll">24小时完成</div>
                <div
                  class="default-button line-button bg-[#f9f9f9] flex flex-row items-center justify-center w-fit ml-2.5"
                  type="outline" @click="selectToday">当天完成</div>
                <div
                  class="default-button line-button bg-[#f9f9f9] flex flex-row items-center justify-center w-fit ml-2.5"
                  type="outline" @click="enableManual">手动设置</div>
              </div>

              <div class="tool-form-item" style="align-items:start">
                <div class="label label-required">24小时分配:</div>
                <div class="content gap-10">
                  <div v-for="(value, index) in values" :key="index" class="time-box bg-[#fdf8ed] rounded flex">
                    <span class="flex items-center justify-center w-1/2 h-8 rounded bg-[#f3b554]"
                      style="width:50%;height: 2rem;color: aliceblue;background-color: #f3b554;border-radius: 5px;"
                      @click="toggleSelection(index)">
                      {{ index }}点
                    </span>
                    <a-input-number class=" w-1/2 h-8" v-model="values[index]" :min="0" :max="99999"
                      style="width: 80px" />
                    <div v-if="conSvg.includes(index)" class="selected-indicator ml-2">
                      <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor"
                        class="arco-icon arco-icon-check icon-check" stroke-width="4" stroke-linecap="butt"
                        stroke-linejoin="miter" style="width: 17px; height: 24px;">
                        <path d="M41.678 11.05 19.05 33.678 6.322 20.95"></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <a-modal title="24小时分配" v-model:visible="showTimeAllocation" @cancel="resetValues" :width="1700">
            <div class="tool-form-item">
            <div class="content gap-10 rounded-lg from-gray-50 to-gray-100">
              <div class="tool-form-item mb-4">
                <div class="label label-required">每天数量:</div>
                <div class="flex items-center">
                  <a-input-number v-model="dailyCount" placeholder="请输入每日数量" :min="0" :max="99999" />
                </div>
              </div>

              <div class="button-group flex gap-4 mb-4">
                <div
                  class="default-button line-button bg-[#f9f9f9] flex flex-row items-center justify-center w-fit ml-2.5"
                  @click="selectAll">24小时完成</div>
                <div
                  class="default-button line-button bg-[#f9f9f9] flex flex-row items-center justify-center w-fit ml-2.5"
                  type="outline" @click="selectToday">当天完成</div>
                <div
                  class="default-button line-button bg-[#f9f9f9] flex flex-row items-center justify-center w-fit ml-2.5"
                  type="outline" @click="enableManual">手动设置</div>
              </div>

              <div class="tool-form-item" style="align-items:start">
                <div class="label label-required">24小时分配:</div>
                <div class="content gap-10">
                  <div v-for="(value, index) in values" :key="index" class="time-box bg-[#fdf8ed] rounded flex clockInput ">
                    <span class="flex items-center justify-center w-1/2 h-8 rounded bg-[#f3b554]"
                      style="width:50%;height: 2rem;color: aliceblue;background-color: #f3b554;border-radius: 5px;"
                      @click="toggleSelection(index)">
                      {{ index }}点
                    </span>
                    <a-input-number class=" w-1/2 h-8" v-model="values[index]" :min="0" :max="99999"
                      style="width: 80px" />
                    <div v-if="conSvg.includes(index)" class="selected-indicator ml-2">
                      <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor"
                        class="arco-icon arco-icon-check icon-check" stroke-width="4" stroke-linecap="butt"
                        stroke-linejoin="miter" style="width: 17px; height: 24px;">
                        <path d="M41.678 11.05 19.05 33.678 6.322 20.95"></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
            <template #footer>
              <el-button @click="showTimeAllocation = false">取消</el-button>
              <el-button type="primary" @click="submitAllocation">确定</el-button>
            </template>
          </a-modal>
          <div class="tool-form-item">
            <el-form-item label="优化天数:" required>
              <a-input-number v-model="form.optimizationDays" controls-position="right" :min="-Infinity" :max="Infinity"
                style="width: 285px;margin-left: 52px" placeholder="请输入每日数量" />
              <div
                class="default-button line-button bg-[#f9f9f9] flex flex-row items-center justify-center w-fit ml-2.5"
                :class="{ 'active-button': form.optimizationDays === 7 }" @click="form.optimizationDays = 7">7天</div>
              <div
                class="default-button line-button bg-[#f9f9f9] flex flex-row items-center justify-center w-fit ml-2.5"
                :class="{ 'active-button': form.optimizationDays === 15 }" @click="form.optimizationDays = 15">15天</div>
              <div
                class="default-button line-button bg-[#f9f9f9] flex flex-row items-center justify-center w-fit ml-2.5"
                :class="{ 'active-button': form.optimizationDays === 30 }" @click="form.optimizationDays = 30">30天</div>
            </el-form-item>

          </div>

          <div class="tool-form-item">
            <el-form-item label="开始日期:" required>
              <a-date-picker v-model="form.startDate" type="date" placeholder="请选择日期"
                style="width: 220px;margin-left: 52px" />
            </el-form-item>
          </div>

          <div class="tool-form-item">
            <el-form-item label="滞留时间:" required>
              <!-- Assuming form model 'form' with field 'retentionTime' -->
              <el-radio-group v-model="form.retentionTime" class="custom-radio-group" style="margin-left: 52px;">
                <el-radio :label="1">60秒~120秒 (<span style="color: red;">+0.300元</span>)</el-radio>
                <el-radio :label="2">100秒~180秒 (<span style="color: red;">+0.600元</span>)</el-radio>
                <el-radio :label="3">180秒~280秒 (<span style="color: red;">+0.800元</span>)</el-radio>
                <el-radio :label="4">280秒~380秒 (<span style="color: red;">+1.000元</span>)</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>

          <div class="tool-form-item">
            <el-form-item label="浏览副宝贝">
              <!-- Assuming form model 'form' with field 'secondItem' -->
              <el-radio-group v-model="form.secondItem" class="custom-radio-group" style="margin-left: 52px;">
                <el-radio :label="1">随机游览</el-radio>
                <el-radio :label="2">深度游览</el-radio>
                <el-radio :label="0">不游览</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
          <div class="tool-form-item">
            <div class="label"></div>
            <div class="flex flex-col">
              <div
                class="flex flex-row items-center w-[45rem] py-2.5 px-2.5 rounded border border-[#fe802d] border-solid bg-[#fdf6e9] text-xs">
                <div class="flex flex-col basis-1/4 leading-6">
                  <span>优化天数: <span class="text-[#fe802d]">{{ form.optimizationDays }}</span>天</span><span>优化数量: <span
                      class="text-[#fe802d]">{{ dailyCount }}</span>个</span><span>合计金额: <span class="text-[#fe802d]">{{
                      totalAmount }}</span>元</span>
                </div>
                <div class="flex flex-col basis-1/3">
                  <span>优化单价: <span class="text-[#fe802d]">{{selectedTypeCost}}</span>元</span>
                </div>
              </div>
              <div class="flex mt-6">
                <a-button @click="pushData" type="primary" class="px-8 py-2 rounded-lg text-sm" status="warning">
                  提交订单
                </a-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, watch, computed } from 'vue';
import { getFlowCategory, pushFlow } from '@api';

export default {
  setup () {
    const activePlatform = ref(1);
    const activeProject = ref('流量任务');
    const activeType = ref('tb_taobao_index');
    const searchKeyword = ref([]);
    const keywords = ref([]);
    const values = ref(new Array(24).fill(0));
    const currentKeywordIndex = ref(null);
    const flowIncs = ref([]);
    const dailyCount = ref(0);
    const selectedFlowInc = ref(null);
    const selectedIndexes = ref([]);
    const conSvg = ref([]);

    const selectedTypeCost = ref(0);

    // 监听 activeType 和 flowIncs 的变化
    watch(
      [activeType, flowIncs],
      () => {
        const selectedType = flowIncs.value.find(type => type.type_key === activeType.value);
        // 添加空值检查
        if (selectedType) { // 确保 selectedType 存在
          selectedTypeCost.value = selectedType.cost ? parseFloat(selectedType.cost) : 0;
        } else {
          selectedTypeCost.value = 0;
        }
      },
      { immediate: true }
    );

    const retentionTimeCost = computed(() => {
      switch (form.retentionTime) {
        case 1:
          return 0.300;
        case 2:
          return 0.600;
        case 3:
          return 0.800;
        case 4:
          return 1.000;
        default:
          return 0;
      }
    });

    const totalAmount = computed(() => {
      const baseAmount = selectedTypeCost.value * dailyCount.value * form.optimizationDays;
      console.log(baseAmount)
      return (baseAmount + retentionTimeCost.value).toFixed(2);
    });

    // 将每日数量分配到选中的框内
    const distributeDailyCount = () => {
      const totalSelected = conSvg.value.length;

      if (totalSelected === 0) return;

      const average = Math.floor(dailyCount.value / totalSelected);
      const remainder = dailyCount.value % totalSelected;

      conSvg.value.forEach((index, idx) => {
        values.value[index] = average + (idx < remainder ? 1 : 0);
      });
    };

    // 根据值更新每日数量
    const updateDailyCount = () => {
      dailyCount.value = values.value.reduce((sum, val) => sum + val, 0);
    };

    // 监听 values 的变化以更新每日数量
    watch(values, updateDailyCount);

    // 选中所有时间框
    const selectAll = () => {
      conSvg.value = Array.from({ length: 24 }, (_, i) => i);
      distributeDailyCount();
    };

    // 选中当天的时间框
    const selectToday = () => {
      // 获取当前时间的小时数
      const currentHour = new Date().getHours();

      // 从当前小时开始，选中到当天结束的时间框
      conSvg.value = Array.from({ length: 24 - currentHour }, (_, i) => currentHour + i);

      // 重新分配每日数量
      distributeDailyCount();
    };

    // 启用手动设置
    const enableManual = () => {
      conSvg.value = [];
      values.value.fill(0); // 重置值
    };

    // 切换选择状态
    const toggleSelection = (index) => {
      if (conSvg.value.includes(index)) {
        conSvg.value = conSvg.value.filter(i => i !== index);
      } else {
        conSvg.value.push(index);
      }
      distributeDailyCount(); // 切换选择时重新分配每日数量
    };

    const resetValues = () => {
      searchKeyword.value = '';
      dailyCount.value = 0;
      values.value = Array(24).fill(0);
      selectedIndexes.value = [];
    };

    const submitAllocation = () => {
      console.log('提交分配:', values.value, selectedIndexes.value);
    };

    const platforms = reactive([
      { name: 'taobao', text: '淘宝', icon: '/order_files/4gmlp-icon1.png', key: 1 },
      { name: 'jingdong', text: '京东', icon: '/order_files/4gmlp-icon4.png', key: 2 },
      { name: 'pdd', text: '拼多多', icon: '/order_files/4gmlp-icon3.png', key: 3 }
    ]);

    const projects = ref([]);
    const optimizationTypes = ref([]);

    const form = reactive({
      optimizationDays: null,
      startDate: null,
      retentionTime: null,
      secondItem: null,
      product_url: ''
    });

    const setActivePlatform = async (platformName) => {
      activePlatform.value = platformName;
      await getFlowType();
    };

    const setActiveProject = async (project) => {
      activeProject.value = project;
      await getFlowType(project)
    };

    const setActiveType = (type) => {
      const flowType = flowIncs.value.find(optType => optType.type_name === type);

      if (flowType) {
        activeType.value = flowType.type_key;
        selectedFlowInc.value = flowType.flow_inc;
        console.log(activeType.value)
        // if (Object.keys(selectedFlowInc.value).length === 0) {
        //   activeType.value = null;
        //   selectedFlowInc.value = null;
        // }
      }
    };

    const showTimeAllocation = ref(false);

    const addKeyword = (keyword) => {
      if (keyword && !keywords.value.includes(keyword)) {
        keywords.value.push(keyword);
        searchKeyword.value = '';
      }
    };

    const editKeyword = (index) => {
      currentKeywordIndex.value = index;
      showTimeAllocation.value = true;
    };

    const removeKeyword = (index) => {
      keywords.value.splice(index, 1);
    };

    const getFlowType = async () => {
      try {
        const platId = platforms.find(p => p.name === activePlatform.value).key;
        const typeRes = await getFlowCategory({ platform_id: platId });
        const flowTypes = typeRes.data.data.find(item => item.category_name === activeProject.value);
        console.log(activeProject.value)
        console.log(flowTypes)

        if (flowTypes) {
          projects.value = typeRes.data.data.map(item => item.category_name);
          optimizationTypes.value = flowTypes.flow_type.map(type => type.type_name);
          flowIncs.value = flowTypes.flow_type.map(type => ({
            type_name: type.type_name,
            type_key: type.type_key,
            flow_inc: type.flow_inc,
            cost: type.cost
          }));
        } else {
          optimizationTypes.value = [];
          flowIncs.value = [];
        }

        if (!optimizationTypes.value.includes(activeType.value)) {
          activeType.value = null;
        }
      } catch (error) {
        console.log(error);
      }
    };

    const pushData = async () => {
      const platform = platforms.find(p => p.name === activePlatform.value);
      const type = flowIncs.value.find(t => t.type_key === activeType.value);

      const payload = {
        platform_id: platform.key,
        flow_type_id: type.type_key,
        request: form.product_url,
        title: form.products_title,
        keywords: keywords.value,
        days: form.optimizationDays,
        tasknum: dailyCount.value,
        hours: conSvg.value,
        sdate: form.startDate,
        timeout: form.retentionTime,
        attach: form.secondItem,
        added: form.added
      };

      console.log('发送数据:', payload);

      const response = await pushFlow(payload);
      if (response.success) {
        console.log('数据推送成功:', response);
      } else {
        console.error('数据推送失败:', response.message);
      }
    };

    return {
      activePlatform,
      activeProject,
      activeType,
      searchKeyword,
      keywords,
      values,
      platforms,
      projects,
      optimizationTypes,
      showTimeAllocation,
      flowIncs,
      selectedTypeCost,
      selectedFlowInc,
      form,
      currentKeywordIndex,
      dailyCount,
      conSvg,
      totalAmount,
      pushData,
      selectAll,
      selectToday,
      toggleSelection,
      resetValues,
      editKeyword,
      enableManual,
      submitAllocation,
      setActivePlatform,
      setActiveProject,
      setActiveType,
      addKeyword,
      removeKeyword,
      getFlowType
    };
  }
}
</script>

<style scoped>
@import url(../../assets/css/order.css);

.clockInput .arco-input-wrapper .arco-input.arco-input-size-medium {
    font-size: 14px;
    width: 50px !important;
    line-height: 1.5715;
    padding-bottom: 4px;
    padding-top: 4px;
}

.time-box div:first-child {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 8px;
}

.time-box div:first-child {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 8px;
}
.bg-\[\#f3b554\] {
    --tw-bg-opacity: 1;
    background-color: rgb(243 181 84 / var(--tw-bg-opacity));
}
.rounded {
    border-radius: .25rem;
}
.justify-center {
    justify-content: center;
}
.items-center {
    align-items: center;
}
.w-1\/2 {
    width: 50%;
}
.h-8 {
    height: 2rem;
}
.flex {
    display: flex;
}

.time-box {
    min-width: 100px;
    overflow: hidden;
    padding: 5px;
    position: relative;
}
.time-box {
    min-width: 100px;
    overflow: hidden;
    padding: 5px;
    position: relative;
}
.rounded {
    border-radius: .25rem;
}
.flex {
    display: flex;
}
.switch-slide-text-enter-from {
    left: -100%!important
}

.switch-slide-text-enter-to {
    left: 8px!important
}

.switch-slide-text-enter-active {
    transition: left .2s cubic-bezier(.34,.69,.1,1)
}

.switch-slide-text-leave-from {
    left: 100%!important
}

.switch-slide-text-leave-to {
    left: 26px!important
}

.switch-slide-text-leave-active {
    transition: left .2s cubic-bezier(.34,.69,.1,1)
}

.arco-switch {
    background-color: var(--color-fill-4);
    border: none;
    border-radius: 12px;
    box-sizing: border-box;
    cursor: pointer;
    height: 24px;
    line-height: 24px;
    min-width: 40px;
    outline: none;
    overflow: hidden;
    padding: 0;
    position: relative;
    transition: background-color .2s cubic-bezier(.34,.69,.1,1);
    vertical-align: middle
}

.arco-switch-handle {
    align-items: center;
    background-color: var(--color-bg-white);
    border-radius: 50%;
    color: var(--color-neutral-3);
    display: flex;
    font-size: 12px;
    height: 16px;
    justify-content: center;
    left: 4px;
    position: absolute;
    top: 4px;
    transition: all .2s cubic-bezier(.34,.69,.1,1);
    width: 16px
}

.arco-switch-checked {
    background-color: rgb(var(--primary-6))
}

.arco-switch-checked .arco-switch-handle {
    color: rgb(var(--primary-6));
    left: calc(100% - 20px)
}

.arco-switch[disabled] .arco-switch-handle {
    color: var(--color-fill-2)
}

.arco-switch[disabled].arco-switch-checked .arco-switch-handle {
    color: var(--color-primary-light-3)
}

.arco-switch-text-holder {
    font-size: 12px;
    margin: 0 8px 0 26px;
    opacity: 0
}

.arco-switch-text {
    color: var(--color-white);
    font-size: 12px;
    left: 26px;
    position: absolute;
    top: 0
}

.arco-switch-checked .arco-switch-text-holder {
    margin: 0 26px 0 8px
}

.arco-switch-checked .arco-switch-text {
    color: var(--color-white);
    left: 8px
}

.arco-switch[disabled] {
    background-color: var(--color-fill-2);
    cursor: not-allowed
}

.arco-switch[disabled] .arco-switch-text {
    color: var(--color-white)
}

.arco-switch[disabled].arco-switch-checked {
    background-color: var(--color-primary-light-3)
}

.arco-switch[disabled].arco-switch-checked .arco-switch-text {
    color: var(--color-white)
}

.arco-switch-loading {
    background-color: var(--color-fill-2)
}

.arco-switch-loading .arco-switch-handle {
    color: var(--color-neutral-3)
}

.arco-switch-loading .arco-switch-text {
    color: var(--color-white)
}

.arco-switch-loading.arco-switch-checked {
    background-color: var(--color-primary-light-3)
}

.arco-switch-loading.arco-switch-checked .arco-switch-handle {
    color: var(--color-primary-light-3)
}

.arco-switch-loading.arco-switch-checked .arco-switch-text {
    color: var(--color-primary-light-1)
}

.arco-switch-small {
    height: 16px;
    line-height: 16px;
    min-width: 28px
}

.arco-switch-small.arco-switch-checked {
    padding-left: -2px
}

.arco-switch-small .arco-switch-handle {
    border-radius: 8px;
    height: 12px;
    left: 2px;
    top: 2px;
    width: 12px
}

.arco-switch-small .arco-switch-handle-icon {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%) scale(.66667)
}

.arco-switch-small.arco-switch-checked .arco-switch-handle {
    left: calc(100% - 14px)
}

.arco-switch-type-round {
    border-radius: var(--border-radius-small);
    min-width: 40px
}

.arco-switch-type-round .arco-switch-handle {
    border-radius: 2px
}

.arco-switch-type-round.arco-switch-small {
    border-radius: 2px;
    height: 16px;
    line-height: 16px;
    min-width: 28px
}

.arco-switch-type-round.arco-switch-small .arco-switch-handle {
    border-radius: 1px
}

.arco-switch-type-line {
    background-color: transparent;
    min-width: 36px;
    overflow: unset
}

.arco-switch-type-line:after {
    background-color: var(--color-fill-4);
    border-radius: 3px;
    content: "";
    display: block;
    height: 6px;
    transition: background-color .2s cubic-bezier(.34,.69,.1,1);
    width: 100%
}

.arco-switch-type-line .arco-switch-handle {
    background-color: var(--color-bg-white);
    border-radius: 10px;
    box-shadow: 0 1px 3px var(--color-neutral-6);
    height: 20px;
    left: 0;
    top: 2px;
    width: 20px
}

.arco-switch-type-line.arco-switch-checked {
    background-color: transparent
}

.arco-switch-type-line.arco-switch-checked:after {
    background-color: rgb(var(--primary-6))
}

.arco-switch-type-line.arco-switch-custom-color {
    --custom-color: var(--color-fill-4)
}

.arco-switch-type-line.arco-switch-custom-color:after {
    background-color: var(--custom-color)
}

.arco-switch-type-line.arco-switch-custom-color.arco-switch-checked {
    --custom-color: rgb(var(--primary-6))
}

.arco-switch-type-line.arco-switch-checked .arco-switch-handle {
    left: calc(100% - 20px)
}

.arco-switch-type-line[disabled] {
    background-color: transparent;
    cursor: not-allowed
}

.arco-switch-type-line[disabled]:after {
    background-color: var(--color-fill-2)
}

.arco-switch-type-line[disabled].arco-switch-checked {
    background-color: transparent
}

.arco-switch-type-line[disabled].arco-switch-checked:after {
    background-color: var(--color-primary-light-3)
}

.arco-switch-type-line.arco-switch-loading {
    background-color: transparent
}

.arco-switch-type-line.arco-switch-loading:after {
    background-color: var(--color-fill-2)
}

.arco-switch-type-line.arco-switch-loading.arco-switch-checked {
    background-color: transparent
}

.arco-switch-type-line.arco-switch-loading.arco-switch-checked:after {
    background-color: var(--color-primary-light-3)
}

.arco-switch-type-line.arco-switch-small {
    height: 16px;
    line-height: 16px;
    min-width: 28px
}

.arco-switch-type-line.arco-switch-small.arco-switch-checked {
    padding-left: 0
}

.arco-switch-type-line.arco-switch-small .arco-switch-handle {
    border-radius: 8px;
    height: 16px;
    top: 0;
    width: 16px
}

.arco-switch-type-line.arco-switch-small .arco-switch-handle-icon {
    transform: translate(-50%,-50%) scale(1)
}

.arco-switch-type-line.arco-switch-small.arco-switch-checked .arco-switch-handle {
    left: calc(100% - 16px)
}

.screen-form {
    display: flex;
    flex-direction: column
}

.screen-form-item {
    align-items: flex-start;
    display: flex;
    margin-bottom: 20px
}

.screen-form-item .label {
    color: var(--font-color);
    font-size: 14px;
    font-weight: 400;
    width: 120px
}

.screen-form-item .content {
    align-items: center;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    justify-content: flex-start
}

.screen-form-item .gap-10 {
    gap: 10px
}

.time-box {
    min-width: 100px;
    overflow: hidden;
    padding: 5px;
    position: relative
}

.time-box div:first-child {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 8px
}

.time-box div:last-child {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    line-height: 8px
}

.time-box .selected-indicator {
    background-color: #f3b5541a;
    box-shadow: 0 1px 1px #f9f9f9;
    height: 1rem;
    position: absolute;
    right: -24px;
    text-align: center;
    top: 5px;
    transform: rotate(45deg);
}

.time-box .selected-indicator .icon-check {
    color: #ff8500;
    font-size: 12px;
    margin-top: 12px;
    transform: rotate(-45deg)
}

.tool-form-item .label {
    width: 145px
}

.tool-header-right {
    align-items: center;
    display: flex
}

.tool-form {
    display: flex;
    flex-direction: column
}

.tool-form-item {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px
}

.tool-form-item .label {
    color: var(--font-color);
    font-size: 16px;
    font-weight: 400;
    width: 145px
}

.tool-form-item .label-required:before {
    color: #f24;
    content: "*";
    margin-right: 4px
}

.tool-form-item .content {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start
}

.tool-form-item .gap-10 {
    gap: 10px
}

.tool-form-item {
  margin-bottom: 16px;
}

.label {
  font-weight: 600;
  margin-bottom: 8px;
  font-size: 1.1rem;
  color: #444;
}

.line-button.active-button, .line-button:focus, .line-button:hover {
    border-color: #fe802d;
    color: #fe802d;
}

.input-search, .input-number, .input-time {
  border-radius: 8px;
  border: 1px solid #ccc;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.default-button {
    height: 36px !important;
    line-height: 36px !important;
}

.input-search:focus, .input-number:focus, .input-time:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.modern-button {
  border-radius: 0.5rem;
  margin-top: 0.3rem;
  width: 4rem;
  background: linear-gradient(90deg, #f3b554, #e6a540);
  color: white;
  font-weight: bold;
  padding: 10px;
  transition: transform 0.2s, box-shadow 0.3s;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.modern-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
}

.selected-indicator {
  margin-left: 10px;
  display: flex;
  align-items: center;
}

.arco-modal {
  width: 100rem;
}

span {
  font-size: 0.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.py-2\.5 {
  padding-bottom: .625rem;
  padding-top: .625rem;
}

.px-2\.5 {
  padding-left: .625rem;
  padding-right: .625rem;
}

.bg-\[\#fdf6e9\] {
  --tw-bg-opacity: 1;
  background-color: rgb(253 246 233 / var(--tw-bg-opacity));
}

.border-\[\#fe802d\] {
  --tw-border-opacity: 1;
  border-color: rgb(254 128 45 / var(--tw-border-opacity));
}

.border-solid {
  border-style: solid;
}

.border {
  border-width: 1px;
}

.rounded {
  border-radius: .25rem;
}

.items-center {
  align-items: center;
}

.flex-row {
  flex-direction: row;
}

.w-\[45rem\] {
  width: 45rem;
}

.flex {
  display: flex;
}

.leading-6 {
  line-height: 1.5rem;
}

.btn-orange {
  background-color: #ff8500;
  color: #fff;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.py-2 {
  padding-bottom: .5rem;
  padding-top: .5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.rounded-lg {
  border-radius: .5rem;
}

[type=button],
[type=reset],
[type=submit],
button {
  -webkit-appearance: button;
}

button,
select {
  text-transform: none;
}

button,
input {
  overflow: visible;
}

.flex-col {
  flex-direction: column;
}

.basis-1\/4 {
  flex-basis: 25%;
}

.flex {
  display: flex;
}

.line-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

/* ...其他样式... */
</style>

<style scoped>
.time-box .selected-indicator .icon-check {
  color: #ff8500;
  font-size: 12px;
  margin-top: 12px;
  transform: rotate(-45deg);
}

.line-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.tool-form-item .content {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.tool-form-item {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.btn-orange:hover {
  background-color: #cc6a00;
}

.btn-orange {
  background-color: #ff8500;
  color: #fff;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

.tool-form-item .label {
  color: var(--font-color);
  font-size: 16px;
  font-weight: 400;
  width: 145px;
}

.line-button {
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  height: 50px;
  line-height: 50px;
  min-width: 100px;
  padding: 0 20px;
  text-align: center;
  transition: border-color 0.3s ease-in-out;
}

.line-button.active-button,
.line-button:focus,
.line-button:hover {
  border-color: #fe802d;
}

.tool-form {
  display: flex;
  flex-direction: column;
}

.tool-form-item .label {
  color: var(--font-color);
  font-size: 16px;
  font-weight: 400;
  width: 145px;
}

.bg-\[\#fdf6e9\] {
  --tw-bg-opacity: 1;
  background-color: rgb(253 246 233 / var(--tw-bg-opacity));
}

.border-\[\#fe802d\] {
  --tw-border-opacity: 1;
  border-color: rgb(254 128 45 / var(--tw-border-opacity));
}

.border-solid {
  border-style: solid;
}

.border {
  border-width: 1px;
}

.rounded {
  border-radius: .25px;
}

.items-center {
  align-items: center;
}

.flex-row {
  flex-direction: row;
}

.w-\[45px\] {
  width: 45px;
}

.flex {
  display: flex;
}

.border-solid {
  border-style: solid;
}

.border {
  border-width: 1px;
}

.rounded {
  border-radius: 0.25px;
}

.items-center {
  align-items: center;
}

.flex-row {
  flex-direction: row;
}

.w-\[45px\] {
  width: 45px;
}

.time-box div:first-child {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 8px;
}

.time-box {
  min-width: 100px;
  overflow: hidden;
  padding: 5px;
  position: relative;
}

.w-1\/2 {
  width: 50%;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.flex {
  display: flex;
}
</style>
