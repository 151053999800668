<template>
  <div class="flex-1">
      <div class="mb-6 h-full">
          <div class="bg-white rounded-lg px-5 pb-5 flex flex-col h-full">
              <div class="flex justify-start items-center h-[68px] border-b-[1px] border-[#e5e5e5] capitalize" style="padding: 10px;">
                  <div class="flex-auto flex justify-start items-center">
                      <div></div>
                      <div class="font-bold text-2xl mr-4" style="color: darkorange;">我的订单</div>
                      <div class="flex-1"></div>
                  </div>
                  <div></div>
              </div>
              <div class="" style="float: left;">
                  <!-- 单选按钮组 -->
                  <a-space direction="vertical" size="large" style="float: inline-start;padding-top: 20px">
                      <a-radio-group type="button" v-model="radioValue">
                          <a-radio value="0">全部</a-radio>
                          <a-radio value="1">进行中</a-radio>
                          <a-radio value="2">已完成</a-radio>
                          <a-radio value="3">取消中</a-radio>
                          <a-radio value="4">已退款</a-radio>
                      </a-radio-group>
                  </a-space>
                  <!-- 表单搜索区域 -->
                  <div class="p-3 rounded mt-2.5">
                      <a-form layout="inline" style="margin-left: -40px;padding-top: 50px">
                          <a-form-item>
                              <div class="label">订单号</div>
                              <a-input placeholder="请输入订单号" v-model="orderNumber" style="width: 150px;"></a-input>
                          </a-form-item>
                          <a-form-item>
                              <div class="label">关键字</div>
                              <a-input placeholder="请输入关键字" v-model="keyword" style="width: 150px;"></a-input>
                          </a-form-item>
                          <a-form-item >
                              <div class="label">商品标题</div>
                              <a-input placeholder="请输入商品标题" v-model="productTitle" style="width: 150px;"></a-input>
                          </a-form-item>
                          <a-form-item>
                              <a-button type="primary" status="warning" @click="getSend">查询</a-button>
                              <a-button type="outline" status="warning" class="ml-2.5">重置</a-button>
                          </a-form-item>
                      </a-form>
                  </div>
                  <!-- 表格区域 -->
                  <div class="flex flex-1 w-full" style="overflow: auto;padding-top: 70px">
                      <a-table :columns="columns" :data="tableData" :loading="loading" row-key="id" bordered hoverable :current-page="currentPage" :total="totalItems"
                          class="arco-table-size-large arco-table-border arco-table-hover arco-table-empty arco-table-layout-fixed w-full h-full">
                      </a-table>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue';
import { getSendGiftList } from '@api';

export default {
  setup () {
    const radioValue = ref('');
    const orderNumber = ref('');
    const keyword = ref('');
    const productTitle = ref('');
    const loading = ref(false);
    const tableData = ref([]);
    const currentPage = ref(1);
    const totalItems = ref(50); // 示例总数

    const columns = ref([
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index'
      },
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id'
      },
      {
        title: '商品',
        dataIndex: 'gift',
        key: 'gift'
      },
      {
        title: '店铺',
        dataIndex: 'store',
        key: 'store'
      },
      {
        title: '销售额',
        dataIndex: 'sale',
        key: 'sale'
      },
      {
        title: '收件人姓名',
        dataIndex: 'receiv_name',
        key: 'receiv_name'
      },
      {
        title: '收件人手机号',
        dataIndex: 'receiv_mobile',
        key: 'receiv_mobile'
      },
      {
        title: '收件地址',
        dataIndex: 'receiv_addres',
        key: 'receiv_addres'
      },
      {
        title: '创建时间',
        dataIndex: 'create_time',
        key: 'create_time'
      },
      {
        title: '状态',
        dataIndex: 'status',
        key: 'status'
      },
      {
        title: '操作',
        dataIndex: 'operation',
        key: 'operation'
      }
    ]);

    const getSend = async () => {
      loading.value = true; // 开始加载
      try {
        const res = await getSendGiftList({
          status: radioValue.value,
          order_number: orderNumber.value,
          start_time: '',
          end_time: '',
          page: currentPage.value,
          size: 20
        });
        tableData.value = res.data.data.list;
      } catch (error) {
        console.error(error);
      } finally {
        loading.value = false; // 加载结束
      }
    };
    watch(radioValue, (newValue) => {
      console.log('Radio value changed to:', newValue);
      getSend(); // 调用获取数据的函数
    });

    onMounted(() => {
      getSend();
    });

    return {
      radioValue,
      orderNumber,
      keyword,
      productTitle,
      loading,
      columns,
      tableData,
      currentPage,
      totalItems,
      getSend
    };
  }
}
</script>
<style>
.arco-radio-button, .arco-radio-group-button {
    border-radius: var(--border-radius-small);
    line-height: 26px;
}
a-input{
    width: 25%;
}

.arco-radio-group-button {
    background-color: var(--color-fill-2);
    display: inline-flex;
    padding: 1.5px;
}
.arco-radio-button.arco-radio-checked {
    color: rgb(255, 151, 22) !important;
    background-color: rgb(var(--orange-1));
    border-color: rgb(255, 151, 22) !important; /* 确保边框颜色也变化 */
}.arco-radio-button>input[type=radio], .arco-radio>input[type=radio] {
    height: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 0;
}.arco-radio-button-content {
    display: block;
    padding: 0 12px;
    position: relative;
}
.label{
    width: 70px;
}
</style>
