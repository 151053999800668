<template>
  <a-layout-header
    class="header flex fixed backdrop-filter backdrop-blur-md top-0 z-40 w-full flex-none transition-colors duration-300 h-[300px] min-w-[1380px]"
    style="background-color: #fff; box-shadow: 0 2px 8px #f0f1f2;height: 71px;">
    <div class="flex w-full">
      <a aria-current="page" href="/"
        class="router-link-active router-link-exact-active font-bold text-lg text-[#fd802e] pr-5 flex justify-start items-center">
        <div class="w-8 h-8" style="margin-left: 20px;width: 50px">
          <img class="w-full h-full" style="width: 100px;height: 50px;margin-top: -15px;" alt="" :src="banner.plateIcon">
        </div>
        <span style="min-width:50px; padding-left: 13px;">{{banner.plateName}}</span>
      </a>
      <div class="flex-auto menu" style="font-size:14px;margin-top: 5px;">
        <a-menu mode="horizontal" theme="light" class="menu" style="font-size:14px; margin: 10px;color: #626C7A;">
          <router-link to="/">
          <a-menu-item key="1">
            首页
          </a-menu-item>
        </router-link>
          <a-menu-item key="2" @click="goToShopTools(8)">
            电商工具
          </a-menu-item>
          <a-menu-item key="3" @click="goToShopTools(23)">
            礼品代发
          </a-menu-item>
          <a-menu-item key="4" @click="goToShopTools(20)">
            人工流量
          </a-menu-item>
          <router-link to="/news">
          <a-menu-item key="5">
            电商资讯
          </a-menu-item>
        </router-link>
        <router-link to="/api">
          <a-menu-item key="api">
            API文档
          </a-menu-item>
        </router-link>
        <router-link to="/jiameng">
          <a-menu-item key="affiliate">
            分站加盟
          </a-menu-item>
        </router-link>
        </a-menu>
      </div>
      <div class="right-section flex justify-start items-center text-[#fd802e]" style="z-index:9999">
        <a-space size="middle">
          <div class="flex justify-start items-center border-r-[2px] border-[#fd802e] mr-2 pr-2 cursor-pointer" >
            <router-link to="/vip" class="flex items-center">
              <img class="mr-1 vertical-align-middle" src="../../../public/index_files/home-icon1.png" alt="" />
              <span style="font-size: 1rem;">会员套餐</span>
            </router-link>
          </div>
          <div class="flex justify-start items-center cursor-pointer" style="margin-left:-20px">
            <router-link to="#" class="flex items-center">
              <img class="mr-1" src="../../../public/index_files/home-icon2.png" alt="" />
              <span style="font-size: 1rem; margin-left:-20px"><recharge :avatar="banner.avatar" /></span>
            </router-link>
          </div>
          <div class="w-[1.5rem] h-[1.5rem] mx-4 cursor-pointer relative" @click="goToShopTools(4)">
            <img src="../../../public/index_files/tb-xiaoxi.png" v-if="isUserLoggedIn" alt="" class="messages-icon" />
          </div>
          <div style="margin-right: 50px;">
            <component :is="isUserLoggedIn ? 'popUser' : 'rigster'" :username="banner.username" :endTime="banner.memberEndTime" />
          </div>
        </a-space>
      </div>
    </div>
  </a-layout-header>
</template>

<script>
import { Layout, Space } from 'ant-design-vue';
import rigster from './component/rigster.vue';
import popUser from './component/popUser.vue';
import recharge from './component/recharge2.vue';
import { useRouter, useRoute } from 'vue-router';
import { reactive, onMounted, computed } from 'vue';
import { getInfo, getUserInfo } from '@api';

export default {
  name: 'HeaderComponent',
  components: {
    'a-layout-header': Layout.Header,
    'a-space': Space,
    recharge,
    rigster,
    popUser
  },
  setup () {
    const banner = reactive({
      username: '',
      memberEndTime: '',
      plateIcon: '',
      plateName: ''
    });

    const getBannerImg = async () => {
      try {
        const infoData = await getUserInfo();
        banner.username = infoData.data.username;
        banner.memberEndTime = infoData.data.member_end_time;
        banner.avatar = infoData.data.avatar;
        banner.score = infoData.data.score;
      } catch (error) {
        console.log(error);
      }
    };
    const router = useRouter();
    const route = useRoute();

    const goToShopTools = (key) => {
      router.push({ name: 'shopTools', params: { key } });
      console.log(key);
    };

    const getImg = async () => {
      const bannerData = await getInfo();
      banner.plateIcon = bannerData.data.data.plate_icon;
      banner.plateName = bannerData.data.data.name;
    };

    const isUserLoggedIn = computed(() => {
      // 如果当前路由是 /jiameng，不检测 token
      if (route.path === '/jiameng') {
        return true;
      }
      return !!localStorage.getItem('token');
    });

    onMounted(() => {
      getImg();
      getBannerImg();
    });

    return {
      banner,
      goToShopTools,
      getBannerImg,
      getImg,
      isUserLoggedIn
    };
  }
}
</script>

<style scoped>
.arco-menu-overflow-wrap {
  width: 100%;
  margin-left: -268px;
  margin-top: -7px;
}
.arco-menu-selected-label {
  position: absolute;
  right: 12px;
  bottom: -14px;
  left: 12px;
  height: 3px;
  background-color: #FD802E !important;
}
.arco-menu-light .arco-menu-item.arco-menu-selected,
.arco-menu-light .arco-menu-group-title.arco-menu-selected,
.arco-menu-light .arco-menu-pop-header.arco-menu-selected,
.arco-menu-light .arco-menu-inline-header.arco-menu-selected {
  color: #FD802E !important;
}
</style>
