<template>
  <a-button shape="round" size="medium" @click="handleClick" type="outline" status="warning">
  <img src="../../../../public/acount_files/15grzx-icon6.png" alt="修改密码" /> 修改密码
</a-button>
<a-modal v-model:visible="visible2" title="修改密码" @cancel="handleCancel" @before-ok="handleBeforeOk">
    <a-form :model="form">
      <a-form-item field="newPassword" label="新密码">
        <a-input v-model="form.newPassword" type="password" />
      </a-form-item>
      <a-form-item field="rePassword" label="重复密码">
        <a-input v-model="form.rePassword" type="password" />
      </a-form-item>
      <a-form-item field="password" label="旧密码">
        <a-input v-model="form.password" type="password" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup>
import { ref, reactive } from 'vue';
import { resetpwd } from '@api';
import { Message } from '@arco-design/web-vue';

const handleClick = () => {
  visible2.value = true;
};

const visible2 = ref(false);

const form = reactive({
  password: '',
  newPassword: '',
  rePassword: ''
});

const handleBeforeOk = async (done) => {
  try {
    const res = await resetpwd({
      oldpassword: form.password,
      newpassword: form.newPassword,
      renewpassword: form.rePassword
    });

    if (res.data.code === 20000) {
      Message.success('修改成功，请重新登录');
      // 清除 token
      localStorage.removeItem('token');
      location.reload()
    } else {
      Message.error(res.data.msg);
    }

    setTimeout(() => {
      done();
      visible2.value = false; // 关闭模态框
    }, 3000);
  } catch (err) {
    console.error(err);
  }
};

const handleCancel = () => {
  visible2.value = false;
};

</script>
