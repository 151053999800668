<template>
  <div class="flex-1">
    <div class="mb-6 h-full w-full">
      <div class="bg-white rounded-lg px-5 pb-5 flex flex-col h-full w-full">
        <div class="header flex justify-between items-center h-16 border-b border-gray-200 p-4">
          <div class="flex justify-start items-center h-[68px] border-[#e5e5e5] capitalize">
            <div class="flex-auto flex justify-start items-center">
              <div></div>
              <div class="font-bold text-[#fe802d] text-2xl mr-4">创建批量订单</div>
              <div class="flex-1"></div>
            </div>
            <div></div>
          </div>
        </div>
        <div class="table-container mt-4 flex flex-col overflow-y-auto">
          <table class="w-full text-left">
            <thead>
              <tr class="text-gray-700 bg-gray-50">
                <th class="p-2">选择</th>
                <th class="p-2">序号</th>
                <th class="p-2">类型选择</th>
                <th class="p-2">链接、店铺链接</th>
                <th class="p-2">关键词</th>
                <th class="p-2">天数</th>
                <th class="p-2">数量</th>
                <th class="p-2">单价</th>
                <th class="p-2">总价</th>
                <th class="p-2">开始日期</th>
                <th class="p-2">设置</th>
                <th class="p-2">操作</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(order, index) in orders" :key="index" class="border-b border-gray-200">
                <td class="p-2">
                  <input type="checkbox" v-model="order.selected" />
                </td>
                <td class="p-2">{{ index + 1 }}</td>
                <td class="p-2">
                  <a-tree-select :data="treeData" placeholder="请选择..." style="width: 200px" v-model="order.type" />
                </td>
                <td class="p-2">
                  <input type="text" v-model="order.link" style="width: 300px"
                    class="border border-gray-300 rounded px-2 py-1 w-full" />
                </td>
                <td class="p-2">
                  <input type="text" v-model="order.keyword" style="width: 100px"
                    class="border border-gray-300 rounded px-2 py-1 w-full" />
                </td>
                <td class="p-2">
                  <input type="text" v-model="order.days" style="width: 50px"
                    class="border border-gray-300 rounded px-2 py-1 w-full" />
                </td>
                <td class="p-2">
                  <input type="number" v-model="order.quantity" style="width: 50px"
                    class="border border-gray-300 rounded px-2 py-1 w-24" />
                </td>
                <td class="p-2">
                  {{ order.unitPrice || '0.00' }}
                </td>
                <td class="p-2 font-medium text-[#fe802d]">
                  {{ ((parseFloat(order.unitPrice || 0) + parseFloat(num.second || 0)) * (order.quantity || 0) * (order.days ||
                0)).toFixed(2) || '0.00' }}
                </td>
                <td class="p-2">
                  <a-date-picker v-model="order.starTime"></a-date-picker>
                </td>
                <td class="p-2">
                  <a-button type="outline" status="warning" @click="editKeyword(index)">高级设置</a-button>
                </td>
                <td class="p-2">
                  <a-button type="outline" status="warning"
                @click="deleteSelected(index)">删除</a-button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="footer mt-4 flex justify-end items-center"></div>
        <div>
          <div class="flex justify-between items-center pt-5">
            <div class="flex items-center">
              <a-checkbox class="mr-2.5">全选</a-checkbox>
              <a-button type="outline" status="warning" shape="square" size="mini" class="mr-2.5"
                @click="deleteSelected">删除</a-button>
              <a-button type="outline" status="warning" shape="square" size="mini" class="mr-2.5"
                @click="batchSet">批量设置</a-button>
            </div>
            <div class="flex items-center">
              <a-input-number class="mr-2.5" style="width: 80px;background-color: white;border:1px solid #c4c4c4"
                v-model="numberInput" :min="1" />
              <a-button type="dashed" shape="square" size="small" class="mr-2.5" style="background-color: white;"
                @click="addNewRow">添加新行</a-button>
            </div>
          </div>
          <div class="jiesuan" style="text-align: left;">
            <p class="p1">订单条数：<b>{{ orderCount }}</b>条，订单总数：<b>{{ totalCount }}</b>个</p>
            <p class="p3">优惠金额：<b>{{ discountAmount.toFixed(2) }}</b>元</p>
            <p class="p4">
              任务备注：
              <a-input style="width: 50%;" placeholder="点击输入订单备注，后期您后期方便管理.." v-model="taskNote" />
            </p>
            <a-button @click="submitOrder" style="margin-top: 20px;width: 150px;border-radius: 10px;" type="primary"
              status="warning">提交订单</a-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <a-modal title="24小时分配" v-model:visible="showTimeAllocation" @cancel="resetValues" width="1290px">
    <div class="tool-form-item">
      <div class="content gap-10 rounded-lg from-gray-50 to-gray-100">
        <div class="tool-form-item mb-4">
          <div class="label label-required">每天数量:</div>
          <div class="flex items-center">
            <a-input-number v-model="dailyCount" placeholder="请输入每日数量" :min="0" :max="99999" />
          </div>
        </div>

        <div class="button-group flex gap-4 mb-4">
          <div class="default-button line-button bg-[#f9f9f9] flex flex-row items-center justify-center w-fit ml-2.5"
            @click="selectAll">24小时完成</div>
          <div class="default-button line-button bg-[#f9f9f9] flex flex-row items-center justify-center w-fit ml-2.5"
            type="outline" @click="selectToday">当天完成</div>
          <div class="default-button line-button bg-[#f9f9f9] flex flex-row items-center justify-center w-fit ml-2.5"
            type="outline" @click="enableManual">手动设置</div>
        </div>

        <div class="tool-form-item" style="align-items:start">
          <div class="label label-required">24小时分配:</div>
          <div class="content gap-10">
            <div v-for="(value, index) in values" :key="index" class="time-box bg-[#fdf8ed] rounded flex">
              <span class="flex items-center justify-center w-1/2 h-8 rounded bg-[#f3b554]"
                style="width:50%;height: 2rem;color: aliceblue;background-color: #f3b554;border-radius: 5px;"
                @click="toggleSelection(index)">
                {{ index }}点
              </span>
              <a-input-number class=" w-1/2 h-8" v-model="values[index]" :min="0" :max="99999"
                style="width: 80px;height: 30px" />
              <div v-if="conSvg.includes(index)" class="selected-indicator ml-2">
                <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor"
                  class="arco-icon arco-icon-check icon-check" stroke-width="4" stroke-linecap="butt"
                  stroke-linejoin="miter" style="width: 17px; height: 24px;">
                  <path d="M41.678 11.05 19.05 33.678 6.322 20.95"></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
        </div>
    </div>
    <div class="tool-form-item" style="align-items:start;">
            <div class="label label-required">滞留时间::</div>
            <div class="content gap-10">
              <a-select v-model="num.second" placeholder="请选择滞留时间" style="width:20%">
                <a-option value="0.300">60秒~120秒 (+0.300元)</a-option>
                <a-option value="0.600">100秒~180秒 (+0.600元)</a-option>
                <a-option value="0.800">180秒~280秒 (+0.800元)</a-option>
                <a-option value="1.000">280秒~380秒 (+1.000元)</a-option>
              </a-select>
            </div>
            </div>

            <div class="tool-form-item" style="align-items:start">
            <div class="label label-required">浏览副宝贝:</div>
            <div class="content gap-10">
              <a-select v-model="num.view" placeholder="请选择" style="width:20%">
                <a-option value="0">随机游览</a-option>
                <a-option value="1">深度游览</a-option>
                <a-option value="2">不游览</a-option>
              </a-select>
            </div>
            </div>
    <template #footer>
      <el-button @click="showTimeAllocation = false">取消</el-button>
      <el-button type="primary" @click="submitAllocation">确定</el-button>
    </template>
  </a-modal>
</template>

<script setup>
import { ref, watch, reactive } from 'vue';
import { getFlowCategorys, pushFlow } from '@api';
import { Message } from '@arco-design/web-vue';

const numberInput = ref(1);
const orderCount = ref(1);
const totalCount = ref(1);
const discountAmount = ref(0);
const taskNote = ref('');
const orders = ref([{ selected: false, type: '', link: '', quantity: '1', second: '' }]);

const num = reactive({
  second: '',
  view: ''
})
const treeData = ref([]);

const dailyCount = ref(0);
const conSvg = ref([]);
const values = ref(new Array(24).fill(0));
const searchKeyword = ref([]);
const selectedIndexes = ref([]);
const showTimeAllocation = ref(false);

const editKeyword = (index) => {
  showTimeAllocation.value = true;
};

const distributeDailyCount = () => {
  const totalSelected = conSvg.value.length;

  if (totalSelected === 0) return;

  const average = Math.floor(dailyCount.value / totalSelected);
  const remainder = dailyCount.value % totalSelected;

  conSvg.value.forEach((index, idx) => {
    values.value[index] = average + (idx < remainder ? 1 : 0);
  });
};

// 根据值更新每日数量
const updateDailyCount = () => {
  dailyCount.value = values.value.reduce((sum, val) => sum + val, 0);
  console.log(values.value)
};

// 监听 values 的变化以更新每日数量
watch(values, updateDailyCount);

// 选中所有时间框
const selectAll = () => {
  conSvg.value = Array.from({ length: 24 }, (_, i) => i);
  distributeDailyCount();
};

// 选中当天的时间框
const selectToday = () => {
  conSvg.value = Array.from({ length: 4 }, (_, i) => 20 + i);
  distributeDailyCount();
};

// 启用手动设置
const enableManual = () => {
  conSvg.value = [];
  values.value.fill(0); // 重置值
};

// 切换选择状态
const toggleSelection = (index) => {
  if (conSvg.value.includes(index)) {
    conSvg.value = conSvg.value.filter(i => i !== index);
  } else {
    conSvg.value.push(index);
  }
  distributeDailyCount(); // 切换选择时重新分配每日数量
};

const resetValues = () => {
  searchKeyword.value = '';
  dailyCount.value = 0;
  values.value = Array(24).fill(0);
  selectedIndexes.value = [];
};

const submitAllocation = () => {
  console.log(111)
  if (selectedIndexes.value.length > 0) {
    // 获取选中的订单的索引
    const selectedIndex = selectedIndexes.value[0];

    // 获取选中的订单
    const selectedOrder = orders.value[selectedIndex];

    // 输出调试信息
    orders.value[selectedIndex].quantity = dailyCount.value
    console.log('Selected index:', selectedIndex);
    console.log('Selected order before update:', selectedOrder);
    console.log('Daily count value:', dailyCount.value);
    console.log('Values for 24-hour distribution:', values.value);

    // 更新订单的数量和 24 小时分配
    selectedOrder.quantity = dailyCount.value;
    selectedOrder.dailyDistribution = [...values.value];

    // 输出更新后的订单
    console.log('Selected order after update:', selectedOrder);

    // 确保 Vue 响应式系统能够检测到变化
    orders.value[selectedIndex] = { ...selectedOrder };
  }

  // 关闭模态框
  showTimeAllocation.value = false;
};

watch(orders, (newOrders) => {
  let totalCost = 0;
  newOrders.forEach(order => {
    const selectedType = treeData.value.flatMap(platform =>
      platform.children.flatMap(category =>
        category.children.filter(type => type.value === order.type)
      )
    );
    if (selectedType.length > 0) {
      totalCost += selectedType[0].cost * order.quantity;
    }
  });
  discountAmount.value = totalCost;
}, { deep: true });

orders.value.forEach((order, index) => {
  watch(() => order.type, (newVal, oldVal) => {
    if (newVal !== oldVal) {
      updateOrderPrice(index);
    }
  });

  watch(() => order.quantity, (newVal, oldVal) => {
    if (newVal !== oldVal) {
      updateOrderPrice(index);
    }
  });
});
const calculateUnitPrice = (orderType) => {
  let unitPrice = 0;

  // 遍历 treeData 查找匹配的 type 并获取其 cost
  treeData.value.forEach(platform => {
    platform.children.forEach(category => {
      category.children.forEach(type => {
        if (type.value === orderType) {
          unitPrice = type.cost;
        }
      });
    });
  });
  console.log(`Calculated unit price for type ${orderType}: ${unitPrice}`); // 调试日志
  return unitPrice;
};

const addNewRow = () => {
  // 获取当前订单的最后一行
  const lastOrder = orders.value[orders.value.length - 1];

  // 创建一个新的订单对象，复制最后一行的所有属性
  const newOrder = {
    ...lastOrder,
    selected: false,
    starTime: null
  };

  // 将复制的新订单推入 orders 数组
  orders.value.push(newOrder);
};
const updateOrderPrice = (index) => {
  const order = orders.value[index];
  // 确保 unitPrice 总是数字
  order.unitPrice = calculateUnitPrice(order.type) || 0;
  order.totalPrice = order.unitPrice * (Number(order.quantity) || 0);
};

const getType = async () => {
  try {
    const res = await getFlowCategorys();
    if (res.data && res.data.data) {
      treeData.value = res.data.data.map(platform => ({
        key: platform.id,
        title: platform.name,
        value: platform.id,
        children: platform.sub ? mapSecondLevelCategories(platform.sub) : [] // 处理二级数据
      }));
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

const mapSecondLevelCategories = (categories) => {
  return categories.map(category => ({
    key: category.id,
    title: category.category_name,
    value: category.id,
    children: category.sub ? mapCategoriesToTreeData(category.sub) : [] // 处理三级数据
  }));
};

const mapCategoriesToTreeData = (categories) => {
  return categories.map(category => ({
    key: category.type_key,
    title: category.type_name,
    value: category.type_key,
    cost: category.cost
  }));
};

const deleteSelected = (index) => {
  if (typeof index === 'number') {
    // 删除单个订单
    orders.value.splice(index, 1);
  } else {
    // 批量删除选中的订单
    orders.value = orders.value.filter(order => !order.selected);
  }
};

const batchSet = () => {
};

const submitOrder = async () => {
  const selectedOrders = orders.value.filter(order => order.selected && order.type);

  if (selectedOrders.length === 0) {
    Message.warning('没有选中的订单');
    return;
  }

  for (const order of selectedOrders) {
    const payload = {
      request: order.link,
      tasknum: order.quantity,
      keywords: order.keyword,
      days: order.days,
      sdate: order.starTime,
      timeout: num.view,
      attach: num.second,
      hours: conSvg.value
    };

    const selectedType = treeData.value.flatMap(platform =>
      platform.children.flatMap(category =>
        category.children.filter(type => type.value === order.type)
      )
    );

    if (selectedType.length > 0) {
      payload.flow_type_id = selectedType[0].key; // 使用对应的 flow_type_id
      const platform = treeData.value.find(platform =>
        platform.children.some(category =>
          category.children.some(type => type.value === order.type)
        )
      );
      if (platform) {
        payload.platform_id = platform.key; // 使用对应的 platform_id
      } else {
        Message.warning('未找到对应的 platform ID');
        continue;
      }
    } else {
      Message.warning(`未找到对应的类型 ID: ${order.type}`);
      continue;
    }

    try {
      await pushFlow(payload);
      Message.success('订单提交成功');
    } catch (error) {
      Message.error('订单提交失败', error.message || error);
    }
  }
};
getType();
</script>

<style scoped>
.jiesuan {
  background: #fff;
  border-radius: 3px;
  border-top: 1px dashed #ddd;
  color: #666;
  font-size: 13px;
  font-style: italic;
  line-height: 30px;
  margin: 30px 0 0;
  padding: 20px;
}

.jiesuan .p1 b {
  color: #555;
}

.jiesuan b {
  color: #07f;
}

b,
strong {
  font-weight: bolder;
}

.jiesuan .p4 .ivu-input-wrapper {
  display: inline-block;
  line-height: normal;
  position: relative;
  vertical-align: middle;
  width: 100%;
}
</style>
<style scoped>
.tool-form-item {
  margin-bottom: 16px;
}

.label {
  font-weight: 600;
  margin-bottom: 8px;
  font-size: 1.1rem;
  color: #444;
}

.default-button {
  padding: 10px 16px;
  border-radius: 8px;
  background-color: #007bff;
  color: white;
  font-weight: bold;
  transition: background-color 0.3s, transform 0.2s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.default-button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

.input-search,
.input-number,
.input-time {
  border-radius: 8px;
  border: 1px solid #ccc;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.input-search:focus,
.input-number:focus,
.input-time:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.time-box {
  display: flex;
  align-items: center;
  background-color: #fdfdfd;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.modern-button {
  border-radius: 0.5rem;
  margin-top: 0.3rem;
  width: 4rem;
  background: linear-gradient(90deg, #f3b554, #e6a540);
  color: white;
  font-weight: bold;
  padding: 10px;
  transition: transform 0.2s, box-shadow 0.3s;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.modern-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
}

.selected-indicator {
  margin-left: 10px;
  display: flex;
  align-items: center;
}

.arco-modal {
  width: 100rem;
}

span {
  font-size: 0.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.py-2\.5 {
  padding-bottom: .625rem;
  padding-top: .625rem;
}

.px-2\.5 {
  padding-left: .625rem;
  padding-right: .625rem;
}

.bg-\[\#fdf6e9\] {
  --tw-bg-opacity: 1;
  background-color: rgb(253 246 233 / var(--tw-bg-opacity));
}

.border-\[\#fe802d\] {
  --tw-border-opacity: 1;
  border-color: rgb(254 128 45 / var(--tw-border-opacity));
}

.border-solid {
  border-style: solid;
}

.border {
  border-width: 1px;
}

.rounded {
  border-radius: .25rem;
}

.items-center {
  align-items: center;
}

.flex-row {
  flex-direction: row;
}

.w-\[45rem\] {
  width: 45rem;
}

.flex {
  display: flex;
}

.leading-6 {
  line-height: 1.5rem;
}

.btn-orange {
  background-color: #ff8500;
  color: #fff;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.py-2 {
  padding-bottom: .5rem;
  padding-top: .5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.rounded-lg {
  border-radius: .5rem;
}

[type=button],
[type=reset],
[type=submit],
button {
  -webkit-appearance: button;
}

button,
select {
  text-transform: none;
}

button,
input {
  overflow: visible;
}

.flex-col {
  flex-direction: column;
}

.basis-1\/4 {
  flex-basis: 25%;
}

.flex {
  display: flex;
}

.line-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.active-button {
  color: #fff;
  background-color: #fe802d;
}

/* ...其他样式... */
</style>
<style scoped>
@import url(../../assets/css/order.css);

.time-box div:first-child {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 8px;
}

.time-box div:first-child {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 8px;
}
.bg-\[\#f3b554\] {
    --tw-bg-opacity: 1;
    background-color: rgb(243 181 84 / var(--tw-bg-opacity));
}
.rounded {
    border-radius: .25rem;
}
.justify-center {
    justify-content: center;
}
.items-center {
    align-items: center;
}
.w-1\/2 {
    width: 50%;
}
.h-8 {
    height: 2rem;
}
.flex {
    display: flex;
}

.time-box {
    min-width: 100px;
    overflow: hidden;
    padding: 5px;
    position: relative;
}
.time-box {
    min-width: 100px;
    overflow: hidden;
    padding: 5px;
    position: relative;
}
.rounded {
    border-radius: .25rem;
}
.flex {
    display: flex;
}
.switch-slide-text-enter-from {
    left: -100%!important
}

.switch-slide-text-enter-to {
    left: 8px!important
}

.switch-slide-text-enter-active {
    transition: left .2s cubic-bezier(.34,.69,.1,1)
}

.switch-slide-text-leave-from {
    left: 100%!important
}

.switch-slide-text-leave-to {
    left: 26px!important
}

.switch-slide-text-leave-active {
    transition: left .2s cubic-bezier(.34,.69,.1,1)
}

.arco-switch {
    background-color: var(--color-fill-4);
    border: none;
    border-radius: 12px;
    box-sizing: border-box;
    cursor: pointer;
    height: 24px;
    line-height: 24px;
    min-width: 40px;
    outline: none;
    overflow: hidden;
    padding: 0;
    position: relative;
    transition: background-color .2s cubic-bezier(.34,.69,.1,1);
    vertical-align: middle
}

.arco-switch-handle {
    align-items: center;
    background-color: var(--color-bg-white);
    border-radius: 50%;
    color: var(--color-neutral-3);
    display: flex;
    font-size: 12px;
    height: 16px;
    justify-content: center;
    left: 4px;
    position: absolute;
    top: 4px;
    transition: all .2s cubic-bezier(.34,.69,.1,1);
    width: 16px
}

.arco-switch-checked {
    background-color: rgb(var(--primary-6))
}

.arco-switch-checked .arco-switch-handle {
    color: rgb(var(--primary-6));
    left: calc(100% - 20px)
}

.arco-switch[disabled] .arco-switch-handle {
    color: var(--color-fill-2)
}

.arco-switch[disabled].arco-switch-checked .arco-switch-handle {
    color: var(--color-primary-light-3)
}

.arco-switch-text-holder {
    font-size: 12px;
    margin: 0 8px 0 26px;
    opacity: 0
}

.arco-switch-text {
    color: var(--color-white);
    font-size: 12px;
    left: 26px;
    position: absolute;
    top: 0
}

.arco-switch-checked .arco-switch-text-holder {
    margin: 0 26px 0 8px
}

.arco-switch-checked .arco-switch-text {
    color: var(--color-white);
    left: 8px
}

.arco-switch[disabled] {
    background-color: var(--color-fill-2);
    cursor: not-allowed
}

.arco-switch[disabled] .arco-switch-text {
    color: var(--color-white)
}

.arco-switch[disabled].arco-switch-checked {
    background-color: var(--color-primary-light-3)
}

.arco-switch[disabled].arco-switch-checked .arco-switch-text {
    color: var(--color-white)
}

.arco-switch-loading {
    background-color: var(--color-fill-2)
}

.arco-switch-loading .arco-switch-handle {
    color: var(--color-neutral-3)
}

.arco-switch-loading .arco-switch-text {
    color: var(--color-white)
}

.arco-switch-loading.arco-switch-checked {
    background-color: var(--color-primary-light-3)
}

.arco-switch-loading.arco-switch-checked .arco-switch-handle {
    color: var(--color-primary-light-3)
}

.arco-switch-loading.arco-switch-checked .arco-switch-text {
    color: var(--color-primary-light-1)
}

.arco-switch-small {
    height: 16px;
    line-height: 16px;
    min-width: 28px
}

.arco-switch-small.arco-switch-checked {
    padding-left: -2px
}

.arco-switch-small .arco-switch-handle {
    border-radius: 8px;
    height: 12px;
    left: 2px;
    top: 2px;
    width: 12px
}

.arco-switch-small .arco-switch-handle-icon {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%) scale(.66667)
}

.arco-switch-small.arco-switch-checked .arco-switch-handle {
    left: calc(100% - 14px)
}

.arco-switch-type-round {
    border-radius: var(--border-radius-small);
    min-width: 40px
}

.arco-switch-type-round .arco-switch-handle {
    border-radius: 2px
}

.arco-switch-type-round.arco-switch-small {
    border-radius: 2px;
    height: 16px;
    line-height: 16px;
    min-width: 28px
}

.arco-switch-type-round.arco-switch-small .arco-switch-handle {
    border-radius: 1px
}

.arco-switch-type-line {
    background-color: transparent;
    min-width: 36px;
    overflow: unset
}

.arco-switch-type-line:after {
    background-color: var(--color-fill-4);
    border-radius: 3px;
    content: "";
    display: block;
    height: 6px;
    transition: background-color .2s cubic-bezier(.34,.69,.1,1);
    width: 100%
}

.arco-switch-type-line .arco-switch-handle {
    background-color: var(--color-bg-white);
    border-radius: 10px;
    box-shadow: 0 1px 3px var(--color-neutral-6);
    height: 20px;
    left: 0;
    top: 2px;
    width: 20px
}

.arco-switch-type-line.arco-switch-checked {
    background-color: transparent
}

.arco-switch-type-line.arco-switch-checked:after {
    background-color: rgb(var(--primary-6))
}

.arco-switch-type-line.arco-switch-custom-color {
    --custom-color: var(--color-fill-4)
}

.arco-switch-type-line.arco-switch-custom-color:after {
    background-color: var(--custom-color)
}

.arco-switch-type-line.arco-switch-custom-color.arco-switch-checked {
    --custom-color: rgb(var(--primary-6))
}

.arco-switch-type-line.arco-switch-checked .arco-switch-handle {
    left: calc(100% - 20px)
}

.arco-switch-type-line[disabled] {
    background-color: transparent;
    cursor: not-allowed
}

.arco-switch-type-line[disabled]:after {
    background-color: var(--color-fill-2)
}

.arco-switch-type-line[disabled].arco-switch-checked {
    background-color: transparent
}

.arco-switch-type-line[disabled].arco-switch-checked:after {
    background-color: var(--color-primary-light-3)
}

.arco-switch-type-line.arco-switch-loading {
    background-color: transparent
}

.arco-switch-type-line.arco-switch-loading:after {
    background-color: var(--color-fill-2)
}

.arco-switch-type-line.arco-switch-loading.arco-switch-checked {
    background-color: transparent
}

.arco-switch-type-line.arco-switch-loading.arco-switch-checked:after {
    background-color: var(--color-primary-light-3)
}

.arco-switch-type-line.arco-switch-small {
    height: 16px;
    line-height: 16px;
    min-width: 28px
}

.arco-switch-type-line.arco-switch-small.arco-switch-checked {
    padding-left: 0
}

.arco-switch-type-line.arco-switch-small .arco-switch-handle {
    border-radius: 8px;
    height: 16px;
    top: 0;
    width: 16px
}

.arco-switch-type-line.arco-switch-small .arco-switch-handle-icon {
    transform: translate(-50%,-50%) scale(1)
}

.arco-switch-type-line.arco-switch-small.arco-switch-checked .arco-switch-handle {
    left: calc(100% - 16px)
}

.screen-form {
    display: flex;
    flex-direction: column
}

.screen-form-item {
    align-items: flex-start;
    display: flex;
    margin-bottom: 20px
}

.screen-form-item .label {
    color: var(--font-color);
    font-size: 14px;
    font-weight: 400;
    width: 120px
}

.screen-form-item .content {
    align-items: center;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    justify-content: flex-start
}

.screen-form-item .gap-10 {
    gap: 10px
}

.time-box {
    min-width: 100px;
    overflow: hidden;
    padding: 5px;
    position: relative
}

.time-box div:first-child {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 8px
}

.time-box div:last-child {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    line-height: 8px
}

.time-box .selected-indicator {
    background-color: #f3b5541a;
    box-shadow: 0 1px 1px #f9f9f9;
    height: 1rem;
    position: absolute;
    right: -24px;
    text-align: center;
    top: 5px;
    transform: rotate(45deg);
}

.time-box .selected-indicator .icon-check {
    color: #ff8500;
    font-size: 12px;
    margin-top: 12px;
    transform: rotate(-45deg)
}

.tool-form-item .label {
    width: 145px
}

.tool-header-right {
    align-items: center;
    display: flex
}

.tool-form {
    display: flex;
    flex-direction: column
}

.tool-form-item {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px
}

.tool-form-item .label {
    color: var(--font-color);
    font-size: 16px;
    font-weight: 400;
    width: 145px
}

.tool-form-item .label-required:before {
    color: #f24;
    content: "*";
    margin-right: 4px
}

.tool-form-item .content {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start
}

.tool-form-item .gap-10 {
    gap: 10px
}

.tool-form-item {
  margin-bottom: 16px;
}

.label {
  font-weight: 600;
  margin-bottom: 8px;
  font-size: 1.1rem;
  color: #444;
}

.line-button.active-button, .line-button:focus, .line-button:hover {
    border-color: #fe802d;
    color: #fe802d;
}

.input-search, .input-number, .input-time {
  border-radius: 8px;
  border: 1px solid #ccc;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.default-button {
    height: 36px !important;
    line-height: 36px !important;
}

.input-search:focus, .input-number:focus, .input-time:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.modern-button {
  border-radius: 0.5rem;
  margin-top: 0.3rem;
  width: 4rem;
  background: linear-gradient(90deg, #f3b554, #e6a540);
  color: white;
  font-weight: bold;
  padding: 10px;
  transition: transform 0.2s, box-shadow 0.3s;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.modern-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
}

.selected-indicator {
  margin-left: 10px;
  display: flex;
  align-items: center;
}

.arco-modal {
  width: 100rem;
}

span {
  font-size: 0.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.py-2\.5 {
  padding-bottom: .625rem;
  padding-top: .625rem;
}

.px-2\.5 {
  padding-left: .625rem;
  padding-right: .625rem;
}

.bg-\[\#fdf6e9\] {
  --tw-bg-opacity: 1;
  background-color: rgb(253 246 233 / var(--tw-bg-opacity));
}

.border-\[\#fe802d\] {
  --tw-border-opacity: 1;
  border-color: rgb(254 128 45 / var(--tw-border-opacity));
}

.border-solid {
  border-style: solid;
}

.border {
  border-width: 1px;
}

.rounded {
  border-radius: .25rem;
}

.items-center {
  align-items: center;
}

.flex-row {
  flex-direction: row;
}

.w-\[45rem\] {
  width: 45rem;
}

.flex {
  display: flex;
}

.leading-6 {
  line-height: 1.5rem;
}

.btn-orange {
  background-color: #ff8500;
  color: #fff;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.py-2 {
  padding-bottom: .5rem;
  padding-top: .5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.rounded-lg {
  border-radius: .5rem;
}

[type=button],
[type=reset],
[type=submit],
button {
  -webkit-appearance: button;
}

button,
select {
  text-transform: none;
}

button,
input {
  overflow: visible;
}

.flex-col {
  flex-direction: column;
}

.basis-1\/4 {
  flex-basis: 25%;
}

.flex {
  display: flex;
}

.line-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

/* ...其他样式... */
</style>

<style scoped>
.time-box .selected-indicator .icon-check {
  color: #ff8500;
  font-size: 12px;
  margin-top: 12px;
  transform: rotate(-45deg);
}

.line-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.tool-form-item .content {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.tool-form-item {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.btn-orange:hover {
  background-color: #cc6a00;
}

.btn-orange {
  background-color: #ff8500;
  color: #fff;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

.tool-form-item .label {
  color: var(--font-color);
  font-size: 16px;
  font-weight: 400;
  width: 145px;
}

.line-button {
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  height: 50px;
  line-height: 50px;
  min-width: 100px;
  padding: 0 20px;
  text-align: center;
  transition: border-color 0.3s ease-in-out;
}

.line-button.active-button,
.line-button:focus,
.line-button:hover {
  border-color: #fe802d;
}

.tool-form {
  display: flex;
  flex-direction: column;
}

.tool-form-item .label {
  color: var(--font-color);
  font-size: 16px;
  font-weight: 400;
  width: 145px;
}

.bg-\[\#fdf6e9\] {
  --tw-bg-opacity: 1;
  background-color: rgb(253 246 233 / var(--tw-bg-opacity));
}

.border-\[\#fe802d\] {
  --tw-border-opacity: 1;
  border-color: rgb(254 128 45 / var(--tw-border-opacity));
}

.border-solid {
  border-style: solid;
}

.border {
  border-width: 1px;
}

.rounded {
  border-radius: .25px;
}

.items-center {
  align-items: center;
}

.flex-row {
  flex-direction: row;
}

.w-\[45px\] {
  width: 45px;
}

.flex {
  display: flex;
}

.border-solid {
  border-style: solid;
}

.border {
  border-width: 1px;
}

.rounded {
  border-radius: 0.25px;
}

.items-center {
  align-items: center;
}

.flex-row {
  flex-direction: row;
}

.w-\[45px\] {
  width: 45px;
}

.time-box div:first-child {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 8px;
}

.time-box {
  min-width: 100px;
  overflow: hidden;
  padding: 5px;
  position: relative;
}

.w-1\/2 {
  width: 50%;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.flex {
  display: flex;
}
</style>
<style scoped>
.time-box .selected-indicator .icon-check {
  color: #ff8500;
  font-size: 12px;
  margin-top: 12px;
  transform: rotate(-45deg);
}

.line-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.tool-form-item .content {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.tool-form-item {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.btn-orange:hover {
  background-color: #cc6a00;
}

.btn-orange {
  background-color: #ff8500;
  color: #fff;
}

.text-sm {
  font-size: .875px;
  line-height: 1.25px;
}

.py-2 {
  padding-bottom: .5px;
  padding-top: .5px;
}

.px-8 {
  padding-left: 2px;
  padding-right: 2px;
}

.rounded-lg {
  border-radius: .5px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

.active-button {
  color: #fff;
  background-color: #fe802d;
}

.tool-form-item .label {
  color: var(--font-color);
  font-size: 16px;
  font-weight: 400;
  width: 145px;
}

.line-button {
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  height: 50px;
  line-height: 50px;
  min-width: 100px;
  padding: 0 20px;
  text-align: center;
  transition: border-color 0.3s ease-in-out;
}

.line-button.active-button,
.line-button:focus,
.line-button:hover {
  border-color: #fe802d;
}

.tool-form {
  display: flex;
  flex-direction: column;
}

.tool-form-item .label {
  color: var(--font-color);
  font-size: 16px;
  font-weight: 400;
  width: 145px;
}

.text-xs {
  font-size: 0.75px;
  line-height: 1px;
}

.py-2\.5 {
  padding-bottom: 0.625px;
  padding-top: 0.625px;
}

.px-2\.5 {
  padding-left: 0.625px;
  padding-right: 0.625px;
}

.bg-\[\#fdf6e9\] {
  --tw-bg-opacity: 1;
  background-color: rgb(253 246 233 / var(--tw-bg-opacity));
}

.border-\[\#fe802d\] {
  --tw-border-opacity: 1;
  border-color: rgb(254 128 45 / var(--tw-border-opacity));
}

.text-xs {
  font-size: .75px;
  line-height: 1px;
}

.py-2\.5 {
  padding-bottom: .625px;
  padding-top: .625px;
}

.px-2\.5 {
  padding-left: .625px;
  padding-right: .625px;
}

.bg-\[\#fdf6e9\] {
  --tw-bg-opacity: 1;
  background-color: rgb(253 246 233 / var(--tw-bg-opacity));
}

.border-\[\#fe802d\] {
  --tw-border-opacity: 1;
  border-color: rgb(254 128 45 / var(--tw-border-opacity));
}

.border-solid {
  border-style: solid;
}

.border {
  border-width: 1px;
}

.rounded {
  border-radius: .25px;
}

.items-center {
  align-items: center;
}

.flex-row {
  flex-direction: row;
}

.w-\[45px\] {
  width: 45px;
}

.flex {
  display: flex;
}

.border-solid {
  border-style: solid;
}

.border {
  border-width: 1px;
}

.rounded {
  border-radius: 0.25px;
}

.items-center {
  align-items: center;
}

.flex-row {
  flex-direction: row;
}

.w-\[45px\] {
  width: 45px;
}

.arco-icon {
  color: inherit;
  display: inline-block;
  font-style: normal;
  height: 1em;
  outline: none;
  vertical-align: -2px;
  width: 1em;
  stroke: currentColor;
}

.arco-icon {
  color: inherit;
  display: inline-block;
  font-style: normal;
  height: 1em;
  outline: none;
  vertical-align: -2px;
  width: 1em;
  stroke: currentColor;
}

.arco-icon {
  color: inherit;
  display: inline-block;
  font-style: normal;
  height: 1em;
  outline: none;
  vertical-align: -2px;
  width: 1em;
  stroke: currentColor;
}

.time-box div:first-child {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 8px;
}

.time-box {
  min-width: 100px;
  overflow: hidden;
  padding: 5px;
  position: relative;
}

.arco-input-wrapper:hover {
  background-color: var(--color-fill-3);
  border-color: transparent;
}

.arco-input-wrapper:hover {
  background-color: var(--color-fill-3);
  border-color: transparent;
}

.arco-input-wrapper:hover {
  background-color: var(--color-fill-3);
  border-color: transparent;
}

.arco-input-number {
  border-radius: var(--border-radius-small);
  box-sizing: border-box;
  position: relative;
  width: 100%;
}

.arco-input-wrapper {
  background-color: var(--color-fill-2);
  border: 1px solid transparent;
  border-radius: var(--border-radius-small);
  box-sizing: border-box;
  color: var(--color-text-1);
  cursor: text;
  display: inline-flex;
  font-size: 14px;
  padding-left: 12px;
  padding-right: 12px;
  transition: color .1s linear, border-color .1s linear, background-color .1s linear;
  width: 100%;
}

.arco-input-wrapper {
  background-color: var(--color-fill-2);
  border: 1px solid transparent;
  border-radius: var(--border-radius-small);
  box-sizing: border-box;
  color: var(--color-text-1);
  cursor: text;
  display: inline-flex;
  font-size: 14px;
  padding-left: 12px;
  padding-right: 12px;
  transition: color .1s linear, border-color .1s linear, background-color .1s linear;
  width: 100%;
}

.arco-input-number {
  border-radius: var(--border-radius-small);
  box-sizing: border-box;
  position: relative;
  width: 100%;
}

.arco-input-wrapper {
  background-color: var(--color-fill-2);
  border: 1px solid transparent;
  border-radius: var(--border-radius-small);
  box-sizing: border-box;
  color: var(--color-text-1);
  cursor: text;
  display: inline-flex;
  font-size: 14px;
  padding-left: 12px;
  padding-right: 12px;
  transition: color .1s linear, border-color .1s linear, background-color .1s linear;
  width: 100%;
}

.arco-input-number {
  border-radius: var(--border-radius-small);
  box-sizing: border-box;
  position: relative;
  width: 100%;
}

.w-1\/2 {
  width: 50%;
}

.h-8 {
  height: 2px;
}

.time-box div:first-child {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 8px;
}

.bg-\[\#f3b554\] {
  --tw-bg-opacity: 1;
  background-color: rgb(243 181 84 / var(--tw-bg-opacity));
}

.rounded {
  border-radius: .25px;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.w-1\/2 {
  width: 50%;
}

.h-8 {
  height: 2px;
}

.flex {
  display: flex;
}

.time-box {
  min-width: 100px;
  overflow: hidden;
  padding: 5px;
  position: relative;
}

.bg-\[\#fdf8ed\] {
  --tw-bg-opacity: 1;
  background-color: rgb(253 248 237 / var(--tw-bg-opacity));
}

.rounded {
  border-radius: .25px;
}

.flex {
  display: flex;
}

.time-box div:first-child {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 8px;
}

.bg-\[\#f3b554\] {
  --tw-bg-opacity: 1;
  background-color: rgb(243 181 84 / var(--tw-bg-opacity));
}

.rounded {
  border-radius: .25px;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.w-1\/2 {
  width: 50%;
}

.h-8 {
  height: 2px;
}

.flex {
  display: flex;
}
</style>
