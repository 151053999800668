<template>
  <div class="flex-1">
    <div class="mb-6">
      <div class="bg-white rounded-lg px-5 pb-5">
        <div class="flex justify-start items-center h-[68px] border-b-[1px] border-[#e5e5e5] capitalize" style="padding: 10px;">
          <div class="flex-auto flex justify-start items-center">
            <div></div>
            <div class="font-bold text-2xl mr-4" style="color: darkorange;">发件信息</div>
          </div>
        </div>
        <div class="pt-5 pb-7 flex justify-between items-start">
          <div class="left-side flex-1">
            <el-form label-position="right" class="mt-5">
              <el-form-item label="发货人名称" required>
                <el-input v-model="inputField.name" placeholder="请输入发货人名称" style="width: 30%;" label="发货人名称" ></el-input>
              </el-form-item>
              <el-form-item label="发货人手机" required>
                <el-input v-model="inputField.phone" placeholder="请输入发货人手机" style="width: 30%;" label="发货人手机"></el-input>
              </el-form-item>

              <el-form-item class="asterisk-left flex items-center w-[60%] mx-auto">
                <p class="text-sm text-[#e63400]">{{ errorMessage }}</p>
              </el-form-item>

              <el-form-item class="asterisk-left flex items-center w-[60%] mx-auto" style="margin-left: 80px;">
                <el-button type="primary" size="large" style="width: 100px;border-radius: 10px" @click="updateData">提交</el-button>
                 <el-button  size="large" style="width: 100px;border-radius: 10px;color: aliceblue;background-color: #97C2C8">重置</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { updateSend, getUserInfo } from '@api';

export default {
  name: 'TaobaoCardFront',
  setup () {
    const inputField = ref({
      name: '',
      phone: ''
    });

    const getsend = async () => {
      const sendRes = await getUserInfo();
      inputField.value.name = sendRes.data.send_user
      inputField.value.phone = sendRes.data.send_phone
      console.log(inputField.value.name)
    }
    getsend();

    const updateData = async () => {
      try {
        await updateSend({
          name: inputField.value.name,
          phone: inputField.value.phone
        });
      } catch (error) {
        console.log(error);
      }
    };

    return {
      updateData,
      getsend,
      inputField
    };
  }
};
</script>
