<template>
  <HeaderComponent />
  <a-layout class="layout">
    <a-layout-content class="flex-1 w-full mx-auto zkzx-index-container" style="background: #f5f5f5;width: 99%;">
      <a-carousel show-arrow="never" animation-name="fade" class="carousel-section" :auto-play="true" indicator-type="dot" style="width:138rem">
        <a-carousel-item v-for="(item, index) in banner" :key="index">
          <img :src="item.image" :alt="item.title" :style="{
        width: '100%',
      }" />
        </a-carousel-item>
      </a-carousel>
      <div class="message-container !w-full">
        <div class="max-w-[1380px] h-[64px] mx-auto flex justify-between items-center">
          <div class="flex-1 flex justify-start" style="line-height:
          50px; font-size: 3rem">
            <div class="p-2 border-r border-[#e5e5e5] flex justify-start items-center"><img class="pr-1"
                src="../../public/index_files/home-icon3.png" alt=""><span style="font-size: large;">公告</span></div>
            <div class="flex-1 p-2 border-r border-[#e5e5e5] flex justify-between cursor-pointer">
              <div></div><span style="font-size: large;">{{ title[0]?.title }}</span>
            </div>
          </div>
          <div class="flex-1 flex justify-start p-2">
            <div class="flex-1 px-2 cursor-pointer"></div>
            <div class="cursor-pointer" style="font-size: large;" @click="goToShopTools(4)"> 查看更多&gt; </div>
          </div>
        </div>
      </div>
      <div class="tools-page bg-white">
        <a-row justify="center" :gutter=[16,16]>
          <a-col :xs="24" :sm="22" :md="22" :lg="22" :xl="22">
            <div class="max-w-[1380px] mx-auto">
              <h1 class="text-4xl text-[#333333] text-center pt-12">电商工具</h1>
              <p class="text-xl text-[#77838f] text-center pt-5 pb-12">
                整合国内主流电商平台电商运营工具，让您运营更轻松
              </p>
              <div class="zkzx-tools">
                <a-row :gutter="[16, 16]" style="margin-left: -10rem; margin-right: 0;">
                  <a-col :span="6" v-for="(tool, index) in tools" :key="index"
                    style="padding-left:3rem; padding-right: 0px;">
                    <a-card hoverable class="zkzx-tools-item" style="height:19rem;width:22rem">
                      <div class="zkzx-tools-item-top">
                        <img :src="tool.imgSrc" :alt="tool.name" class="tool-image" />
                        <div v-text=" '&ensp;'+ tool.name + '&ensp;'">
                        </div>
                        <div v-if="tool.tag" class="i" style="margin-top: 24px;"
                          :style="{ 'border-bottom': '5px solid ' + tool.tagColor, 'border-right': '5px solid ' + tool.tagColor }">
                        </div>
                        <div v-if="tool.tag" class="tag" style="color:white;padding: 5px;"
                          :style="{ backgroundColor: tool.tagColor }">{{ tool.tag }}
                        </div>
                      </div>
                      <div class="zkzx-tools-item-middle clamp clamp-2" v-text="tool.description"></div>
                      <div class="zkzx-tools-item-bottom">
                        <a-button
                          style="margin-top:-0.5rem;color: darkorange;border-color:darkorange;border-radius: 20px;"
                          @click="goToShopTools(8)">查看详情</a-button>
                        <img :src="tool.detailImg">
                      </div>
                    </a-card>
                  </a-col>
                </a-row>
                <div class="zkzx-tools-more mt-8" style="height: 5rem;margin-left: 44%;">
                  <a-button style="border-radius: 20px;color: white;background-color:darkorange ;"
                    @click="goToShopTools(3)">查看更多</a-button>
                </div>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>

      <div class="w-full">
        <div class="max-w-[1380px] mx-auto">
          <div class="text-4xl text-[#333333] text-center pt-12">礼品代发</div>
          <div class="text-xl text-[#77838f] text-center pt-5 pb-12">礼品代发1、3元起,全国多仓，秒出单号</div>
          <div class="zkzx-gift">
            <div class="zkzx-gift-express">
              <div v-for="express in expresses" :key="express.name"
                :class="['zkzx-gift-express-item', { 'active': currentExpress === express.name }]"
                @mouseover="handleMouseOver(express)">
                <div class="title" style="margin-right: 170px;width:100px">{{ express.name }}</div>
                <div class="sub-title">
                  <p>{{ express.store_number }}大仓库</p>
                  <div class="sub-title-line"></div>
                  <p>{{ express.des }}</p>
                </div>
              </div>
              <div class="gift-earth"><img src="/index_files/home-img1.png" alt=""></div>
            </div>
            <div class="zkzx-gift-list">
              <div v-for="(gift, index) in gifts" :key="index" class="zkzx-gift-list-item">
                <div class="img">
                  <img :src="gift.imgSrc" alt="">
                </div>
                <div class="content">
                  <div class="info">
                    <span class="info-title">{{ gift.name }}</span>
                    <span class="info-price" v-if="tokenExists">¥{{ gift.price.toFixed(2) }}</span>
                    <span v-else class="info-price">请登录后查看</span>
                  </div>
                  <div class="sale">
                    <div class="group">
                      <img src="/index_files/home-img2.png">
                      <img src="/index_files/home-img3.png">
                      <img src="/index_files/home-img4.png">
                      <img src="/index_files/home-img5.png">
                    </div>
                    <div v-if="tokenExists" class="btn">购买</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full bg-white">
        <a-row class="w-full bg-white">
          <a-col :span="24">
            <div class="max-w-[1380px] mx-auto">
              <div class="text-4xl text-[#333333] text-center pt-12">人工流量</div>
              <div class="text-xl text-[#77838f] text-center pt-5 pb-12">
                支持三大主流电商平台流量优化，支持手机、pc搜索浏览，支持多维度进店方式
              </div>
              <div class="zkzx-artificial" style="text-align: left;">
                <a-row :gutter="[16, 24]">
                  <a-col v-for="(item, index) in artificialItems" :key="index" :span="6" class="zkzx-artificial-item">
                    <div class="colControl" style="margin-left: 10px">
                      <div class="zkzx-artificial-item-top">
                        <div class="title">
                          <strong>{{ item.toptitle }}</strong>
                          <p>{{ item.description }}</p>
                        </div>
                        <img :src="item.image" alt="">
                      </div>
                      <div class="zkzx-artificial-item-content">
                        <div v-for="(feature, featureIndex) in item.features" :key="featureIndex" class="item"
                          :class="{ 'tag': feature.tag }">
                          <div>
                            <div class="label">
                              <div class="point"></div>
                              <div class="title">{{ feature.title }}</div>
                            </div>
                            <div class="desc">{{ feature.desc }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a-col>
                </a-row>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
      <div class="w-full">
        <div class="api-section max-w-[1380px] mx-auto">
          <a-col :span="24">
            <div class="text-4xl text-[#333333] text-center pt-12">API接口</div>
            <div class="text-xl text-[#77838f] text-center pt-5 pb-12">
              全电商平台API，一个秘钥对接全接口，单次使用方便灵活
            </div>
            <div class="zkzx-api" :class="{ 'active': activeIndex === index }">
              <div v-for="(apiItem, index) in apiItems" :key="index" :span="6" class="zkzx-api-item"
                @mouseenter="setActive(index)" :class="{ 'active': activeIndex === index }" @mouseon="clearActive"
                :style="{ backgroundImage: activeIndex === index ? 'url(' + apiItem.activeBackgroundImage + ')' : 'url(' + apiItem.backgroundImage + ')' }">
                <div class="title" :class="{'active-title': activeIndex === index}">
                  <p>{{ apiItem.title }}</p>
                </div>
                <div class="sub-title" :class="{'active-sub-title': activeIndex === index}">
                  <p class="clamp clamp-2">
                    <span v-for="(feature, fIndex) in apiItem.features" :key="fIndex">
                      {{ feature + ' |' }}
                      <i v-if="fIndex < apiItem.features.length - 1" class="title-item-line"></i>
                    </span>
                  </p>
                </div>
                <!-- 添加更多信息或按钮 -->
                <div class="active-btn" v-show="activeIndex === index">
                  <button class="free"><router-link to="/api">免费试用</router-link></button>
                  <button class="more"><router-link to="/api">更多接口</router-link></button>
                </div>
              </div>
            </div>
          </a-col>
        </div>
        <div>
        </div>
      </div>

      <div class="w-full bg-white">
        <div class="max-w-[1380px] mx-auto">
          <div class="text-4xl text-[#333333] text-center pt-12">分站加盟 </div>
          <div class="text-xl text-[#77838f] text-center pt-5 pb-12">选择我们，发展前景更广阔改为加盟电商工具，发展更有前景
          </div>
          <div class="zkzx-substation">
            <div class="zkzx-substation-content-bg"><img src="/index_files/home-img17.png" alt=""></div>
            <div class="zkzx-substation-content">
              <div class="side">
                <div class="item">
                  <div></div>
                  <div class="item-box">
                    <div class="box1"><img src="/index_files/home-icon17.png" alt="">
                      <p>7×24H售后支持</p>
                    </div>
                    <div class="box2">
                      <p>支持售后托管，有什么运营和工具使用 售后问题，24小时服务解答</p>
                    </div>
                    <div class="box3"><img src="/index_files/home-img18.png" alt="">
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div class="item-box">
                    <div class="box1"><img src="/index_files/home-icon18.png" alt="">
                      <p>提供域名服务</p>
                    </div>
                    <div class="box2">
                      <p>无域名可提供二级域名，免去备案麻烦， 快速部署上线</p>
                    </div>
                    <div class="box3"><img src="/index_files/home-img18.png" alt="">
                    </div>
                  </div>
                  <div></div>
                </div>
                <div class="item">
                  <div class="item-box">
                    <div class="box1"><img src="/index_files/home-icon19.png" alt="">
                      <p>提供电商API接口</p>
                    </div>
                    <div class="box2">
                      <p>聚合电商平台API接口，技术对接售后， 解决技术难题</p>
                    </div>
                    <div class="box3"><img src="/index_files/home-img18.png" alt="">
                    </div>
                  </div>
                  <div></div>
                </div>
                <div class="item">
                  <div></div>
                  <div class="item-box">
                    <div class="box1"><img src="/index_files/home-icon20.png" alt="">
                      <p>聚合电商运营功能</p>
                    </div>
                    <div class="box2">
                      <p>涵盖淘宝，抖音，拼多多等电商平台 工具，让用户一站式使用</p>
                    </div>
                    <div class="box3"><img src="/index_files/home-img18.png" alt="">
                    </div>
                  </div>
                </div>
              </div>
              <div class="side">
                <div class="item">
                  <div class="item-box">
                    <div class="box1"><img src="/index_files/home-icon21.png" alt="">
                      <p>无任何门槛</p>
                    </div>
                    <div class="box2">
                      <p>只要您有资源，无任何门槛开通分站， 上线运营</p>
                    </div>
                    <div class="box3"><img src="/index_files/home-img18.png" alt="">
                    </div>
                  </div>
                  <div></div>
                </div>
                <div class="item">
                  <div></div>
                  <div class="item-box">
                    <div class="box1"><img src="/index_files/home-icon22.png" alt="">
                      <p>OEM分站加盟</p>
                    </div>
                    <div class="box2">
                      <p> 分站加盟打造自己的品牌，自定义logo、 名称、价格、联系方式、会员套餐等 </p>
                    </div>
                    <div class="box3"><img src="/index_files/home-img18.png" alt="">
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div></div>
                  <div class="item-box">
                    <div class="box1"><img src="/index_files/home-icon23.png" alt="">
                      <p>独立后台管理</p>
                    </div>
                    <div class="box2">
                      <p> 自己管理网站，客户，会员，工具功能， 定价，客服，财务，数据统计等 </p>
                    </div>
                    <div class="box3"><img src="/index_files/home-img18.png" alt="">
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div class="item-box">
                    <div class="box1"><img src="/index_files/home-icon24.png" alt="">
                      <p>后台随时提现</p>
                    </div>
                    <div class="box2">
                      <p>无需通过后台审核，随时提现到账， 安全放心</p>
                    </div>
                    <div class="box3"><img src="/index_files/home-img18.png" alt="">
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
          <div class="zkzx-substation-more"><button class="btn"><router-link to="/jiameng">查看更多</router-link></button>
          </div>
        </div>
      </div>
      <div class="w-full">
        <div class="max-w-[1380px] mx-auto">
          <div class="text-4xl text-[#333333] text-center pt-12">电商资讯</div>
          <div class="text-xl text-[#77838f] text-center pt-5 pb-12">每天分享电商干活资讯，最新电商动态一手掌握</div>
          <div>
            <div class="grid grid-cols-2 gap-x-20 gap-y-10">
              <div v-for="newsItem in form" :key="newsItem.id" class="grid grid-cols-2 gap-x-20 gap-y-10">
                <div class="flex justify-start items-start">
                  <div class="flex flex-col justify-center items-start mr-8">
                    <!-- <div>
                      <a-image :src="newsItem.image"></a-image>
                    </div> -->
                    <RouterLink :to="'/news/' + newsItem.id">
                      <span class="text-2xl text-[#fd802e]">{{ newsItem.push_time }}</span>
                    </RouterLink>
                  </div>
                  <div class="flex-1">
                    <RouterLink :to="'/news/' + newsItem.id">
                      <span class="text-base text-[#333333] cursor-pointer">{{ newsItem.title }}</span>
                      <p class="text-[#999999] line-clamp-2 leading-5 cursor-default">
                        {{ newsItem.read_count }} {{ newsItem.content }}
                      </p>
                    </RouterLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="view-more"><button class="btn"><router-link to="/news">查看更多</router-link></button></div>
        </div>
      </div>
    </a-layout-content>
    <footerComponent></footerComponent>
  </a-layout>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import {
  Layout,
  Row,
  Col,
  Card,
  Button,
  Carousel
} from 'ant-design-vue'
import HeaderComponent from '../components/center/banner.vue'
import footerComponent from '../components/footer.vue'
import { getGiftExpress, getGift, getInfo, getNoticeList, getArticleList } from '@api'
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'HomePage',
  components: {
    HeaderComponent,
    footerComponent,
    'a-layout': Layout,
    'a-layout-content': Layout.Content,
    // 'a-layout-footer': Layout.Footer,
    'a-row': Row,
    'a-col': Col,
    'a-card': Card,
    'a-carousel': Carousel,
    'a-button': Button
  },
  data () {
    return {
      currentExpress: null,
      activeIndex: 0,
      tokenExists: false,
      banner: '',
      copyright: '',
      des: '',
      footerContent: '',
      footerLogo: '',
      gzhQr: '',
      keyword: '',
      kfKey: '',
      kfQr: '',
      name: '',
      plateIcon: '',
      plateLogo: '',
      qq: '',
      record: '',
      registCode: '',
      statisticsCode: '',
      weixin: '',
      workTime: '',
      title: [],
      tools: [
        {
          name: '旺旺打标',
          imgSrc: '/index_files/home-icon4.png',
          description: '提高潜在消费者转化，提高宝贝曝光...',
          tag: '黑科技',
          tagColor: '#333',
          detailImg: '/index_files/home-icon4-1.png'
        },
        {
          name: '照妖镜验号',
          imgSrc: '/index_files/home-icon5.png',
          description: '使用照妖镜验号就行多维度优选高质账号...',
          detailImg: '/index_files/home-icon5-1.png'
        },
        {
          name: '小号透视',
          imgSrc: '/index_files/home-icon6.png',
          description: '实时同步精准查询淘气值、好评率、信誉级、等旺旺账号相关的数据，分析账...',
          detailImg: '/index_files/home-icon6-1.png'
        },
        {
          name: '标签查询',
          imgSrc: '/index_files/home-icon7.png',
          description: '根据账号的日常搜索，浏览，关注，收藏，停留，付款等多个维度计算出账...',
          detailImg: '/index_files/home-icon7-1.png'
        },
        {
          name: '礼品代发',
          imgSrc: '/index_files/home-icon8.png',
          description: '礼品代发低至1、3元起，仓库遍布全国秒出单号，自动发货自动同步，高效提...',
          tag: '1.3元起',
          tagColor: 'red',
          detailImg: '/index_files/home-icon8-1.png'
        },
        {
          name: '人工流量',
          imgSrc: '/index_files/home-icon9.png',
          description: '三大主流电商平台搜索流量、手淘流量、优质无过滤流量、猜你喜欢流量，...',
          detailImg: '/index_files/home-icon9-1.png'
        },
        {
          name: '淘宝卡首屏',
          imgSrc: '/index_files/home-icon10.png',
          description: '高达二十几种卡首屏模式，如：搜索卡首屏、猜你喜欢卡首屏、购后猜你喜...',
          detailImg: '/index_files/home-icon10-1.png'
        },
        {
          name: '抖音卡首屏',
          imgSrc: '/index_files/home-icon11.png',
          description: '搜索卡首屏、猜你喜欢卡首屏、黑科技卡首屏生成工具，可计算搜索权重，搜索...',
          detailImg: '/index_files/home-icon11-1.png'
        },
        {
          name: '拼多多卡首屏',
          imgSrc: '/index_files/home-icon12.png',
          description: '大家都说好卡首屏、搜索卡首屏生成二维码，通过扫码后可指定在首页，增...',
          detailImg: '/index_files/home-icon12-1.png'
        },
        {
          name: '标签推送',
          imgSrc: '/index_files/home-icon7.png',
          tag: '独家使用',
          tagColor: '#b876ff',
          description: '通过标签推送进行大数据分析后，通过模型算法提高宝贝在用户搜索结果页...',
          detailImg: '/index_files/home-icon7-1.png'
        },
        {
          name: '指数转换',
          imgSrc: '/index_files/home-icon14.png',
          tag: '永久免费',
          tagColor: '#333',
          description: '支持生意参谋多数据指标同时在线转换真实数据，最高支持转化100亿数据。',
          detailImg: '/index_files/home-icon14-1.png'
        },
        {
          name: '无痕秒单',
          imgSrc: '/index_files/home-icon14.png',
          tag: '黑科技',
          tagColor: '#74be63',
          description: '支持生意参谋多数据指标同时在线转换真实数据，最高支持转化100亿数据。',
          detailImg: '/index_files/home-icon14-1.png'
        }
      ],
      expresses: [],
      gifts: [
      ],
      artificialItems: [
        // 淘宝优化的数据
        {
          toptitle: '淘宝优化',
          description: '手淘、优质不过滤、猜你喜欢',
          image: '/index_files/home-img7.png',
          features: [
            {
              title: '手淘流量',
              desc: '通过手淘流量、优质流量(不过滤)、猜你喜欢(高级)、标签 / 展现流量、淘口令流量进店浏览商品宝贝',
              tag: true
            },
            {
              title: '收藏/加购',
              desc: '通过搜索收藏、搜索收藏（男女）、直接收藏等可针对性选择优化类型',
              tag: false
            },
            {
              title: '宝贝推荐',
              desc: '通过宝贝推荐、评价点赞可多维度优化店铺数据',
              tag: false
            },
            {
              title: '达人微淘',
              desc: '达人微淘，加入真实人工流量，入口多样更高权重',
              tag: false
            }
          ]
        },
        // 京东优化的数据
        {
          toptitle: '京东优化',
          description: 'APP流量、收藏/关注、宝贝加购、达人业务',
          image: '/index_files/home-img8.png',
          features: [
            {
              title: 'APP流量',
              desc: '通过APP搜索流量进店浏览商品宝贝',
              tag: true
            },
            {
              title: '收藏/关注',
              desc: '通过搜索收藏、直接收藏、店铺关注等可针对性选择优化类型',
              tag: false
            },
            {
              title: '搜索加购',
              desc: '通过搜索加购、直接加购可针对性选择优化类型',
              tag: false
            },
            {
              title: '达人业务',
              desc: '通过京东达人粉丝、京东达人阅读、京东达人进店多样更高权重',
              tag: false
            }
          ]
        },
        // 拼多多优化的数据
        {
          toptitle: '拼多多优化',
          description: 'APP流量、收藏/关注、宝贝加购、达人业务',
          image: '/index_files/home-img9.png',
          features: [
            {
              title: 'APP流量',
              desc: '通过APP搜索流量进店浏览商品宝贝',
              tag: true
            },
            {
              title: '收藏/关注',
              desc: '通过搜索收藏、直接收藏、店铺关注等可针对性选择优化类型',
              tag: false
            },
            {
              title: '搜索加购',
              desc: '通过搜索加购、直接加购可针对性选择优化类型',
              tag: false
            },
            {
              title: '达人业务',
              desc: '通过拼多多达人粉丝、拼多多达人阅读、拼多多达人进店多样更高权重',
              tag: false
            }
          ]
        },
        // 订单管理的数据
        {
          toptitle: '订单管理优化',
          description: '独立流量优化单独订单管理',
          image: '/index_files/home-img10.png',
          features: [
            {
              title: '批量发布',
              desc: '量大用户可一键批量发布，高效开展流量优化业务',
              tag: true
            },
            {
              title: '订单列表',
              desc: '淘宝、京东、拼多多流量订单列表管理',
              tag: false
            }
          ]
        }
      ],
      apiItems: [
        {
          title: '淘宝接口',
          backgroundImage: '/index_files/bg-tb.png',
          activeBackgroundImage: '/index_files/bg-tb-active.png',
          features: [
            '√淘宝商品详情API',
            '√淘宝商品排名API',
            '√旺旺打标API',
            '√照妖镜验号API',
            '√照妖镜验号API',
            '√照妖镜验号API',
            '√照妖镜验号API',
            '√标签推送【淘金币推送】API',
            '√标签推送【淘金币推送】API'
          ]
        },
        {
          title: '拼多多接口',
          backgroundImage: '/index_files/bg-pdd.png',
          activeBackgroundImage: '/index_files/bg-pdd-active.png',
          features: [
            '√拼多多商品详情API',
            '√拼多多打标API',
            '√拼多多大家都喜欢卡首屏API',
            '√V兔拼多多打标API'
          ]
        },
        {
          title: '抖音接口',
          backgroundImage: '/index_files/bg-dy.png',
          activeBackgroundImage: '/index_files/bg-dy-active.png',
          features: [
            '√抖音关键词卡首屏API',
            '√抖音猜你喜欢卡首屏API',
            ''
          ]
        },
        {
          title: '京东接口',
          backgroundImage: '/index_files/bg-jd.png',
          activeBackgroundImage: '/index_files/bg-jd-active.png',
          features: [
            '√京东商品详情API'
          ]
        },
        {
          title: '1688接口',
          backgroundImage: '/index_files/bg-1688.png',
          activeBackgroundImage: '/index_files/bg-1688-active.png',
          features: [
            '√阿里巴巴商品详情API'
          ]
        }
        // ...
        // 添加其他API接口项
      ],
      substationInfo: [
        '多种模式自由选择',
        '省去研发成本',
        '无需囤货压货',
        '交易资金有保障',
        '专业运营团队支持',
        '7*24小时客户服务'
        // 可能还有更多信息...
      ]
    }
  },
  methods: {
    selectExpress (name) {
      this.currentExpress = name;
    },
    setActive (index) {
      this.activeIndex = index;
    },
    clearActive () {
      this.activeIndex = null;
    },
    async fetchGiftData () {
      try {
        const response = await getGiftExpress();
        if (response && response.data) {
          this.expresses = Object.entries(response.data.data).map(([key, value]) => ({
            name: key,
            ...value
          }));
          if (this.expresses.length > 0) {
            this.currentExpress = this.expresses[0].name;
            this.fetchGiftProducts(this.expresses[0]);
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    handleMouseOver (express) {
      this.hoverTimeout = setTimeout(() => {
        this.currentExpress = express.name;
        this.fetchGiftProducts(express);
      }, 1000); // 1 second delay
    },
    async fetchNotice () {
      try {
        const res = await getNoticeList({ type: 2 })
        this.title = res.data.data.list
        console.log(this.title)
      } catch (error) {
        console.log(error);
      }
    },
    async fetchGiftProducts (express) {
      try {
        const giftRes = await getGift({ store_id: express.store_id });
        this.currentExpress = express.name; // 确保当前快递被选中
        if (giftRes && giftRes.data) {
          this.gifts = giftRes.data.data.slice(0, 8).map(item => ({
            name: item.name,
            price: item.apiprice,
            imgSrc: item.goods_image
          }));
        }
      } catch (error) {
        console.log(error);
      }
    },
    async fetchData () {
      try {
        const response = await getInfo()
        this.banner = response.data.data.banner
        this.copyright = response.data.data.copyright
        this.des = response.data.data.des
        this.footerContent = response.data.data.footer_content
        this.footerLogo = response.data.data.footer_logo
        this.gzhQr = response.data.data.gzh_qr
        this.keyword = response.data.data.keyword
        this.kfKey = response.data.data.kf_key
        this.kfQr = response.data.data.kf_qr
        this.name = response.data.data.name
        this.plateIcon = response.data.data.plate_icon
        this.plateLogo = response.data.data.plate_logo
        this.qq = response.data.data.qq
        this.record = response.data.data.record
        this.registCode = response.data.data.regist_code
        this.statisticsCode = response.data.data.statistics_code
        this.weixin = response.data.data.weixin
        this.workTime = response.data.data.work_time
      } catch (error) {
        console.error(error)
      }
    }
  },
  mounted () {
    this.tokenExists = !!localStorage.getItem('token');
    this.fetchGiftData();
    this.fetchData();
    this.fetchNotice();
  },
  setup () {
    const router = useRouter();
    function goToShopTools (key) {
      router.push({ name: 'shopTools', params: { key } });
      console.log(key);
    }

    const form = ref([]); // 初始化为一个空数组

    const getNewslist = async () => {
      try {
        const res = await getArticleList({ current: 1, pageSize: 4 });
        if (res.data && res.data.data) {
          form.value = res.data.data.list;
          console.log('Fetched data:', form.value); // 确保数据已成功获取
        }
      } catch (error) {
        console.error('Error fetching news list:', error);
      }
    };

    onMounted(() => {
      getNewslist(); // 页面加载时调用函数获取数据
    });
    return {
      goToShopTools,
      getNewslist,
      form
    };
  },
  watch: {
    expresses (newVal) {
      if (newVal.length > 0) {
        this.currentExpress = newVal[0].name
      }
    }
  }
})
</script>

<style scoped>
/* General Styles */
.zkzx-gift-list{
    display: flex;
    flex-wrap: wrap;
    height: 652px;
    justify-content: flex-start;
    width: calc(100% - 288px);
}
.zkzx-index-container {
    background-repeat: no-repeat;
    background-size: cover;
}

.text-center {
    text-align: center;
}

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.w-full {
    width: 100%;
}

.flex-1 {
    flex: 1 1 0%;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .text-4xl {
        font-size: 1.5rem;
        line-height: 2rem;
    }

    .text-xl {
        font-size: 1rem;
    }

    .pt-12 {
        padding-top: 2rem;
    }

    .max-w-[1380px] {
        max-width: 100%;
        padding: 0 1rem;
    }

    .zkzx-tools-item {
        width: 100%;
        height: auto;
        margin-bottom: 1rem;
    }

    .zkzx-tools-more {
        margin-left: 0;
        text-align: center;
    }

    .message-container {
        padding: 1rem;
        flex-direction: column;
        align-items: center;
    }

    .carousel-section img {
        height: auto;
    }

    .zkzx-gift-express {
        width: 100%;
        min-height: auto;
        margin-bottom: 1rem;
    }

    .zkzx-gift-express-item .title {
        font-size: 14px;
        margin-bottom: 4px;
    }

    .zkzx-substation-content .side .box2 p {
        font-size: 12px;
        width: auto;
    }
}

@media (max-width: 480px) {
    .text-4xl {
        font-size: 1.25rem;
        line-height: 1.75rem;
    }

    .pt-12 {
        padding-top: 1rem;
    }

    .zkzx-tools-item {
        padding: 0;
    }

    .zkzx-gift-list-item .content {
        font-size: 0.875rem;
    }

    .zkzx-artificial-item-content .item .desc {
        font-size: 0.75rem;
    }
}
.zkzx-tools-item-top .i {
    border-bottom: 2px solid #333;
    border-right: 2px solid #333;
    border-color: transparent red red transparent;
    border-style: solid;
    border-width: 5px;
    margin-top: 16px;
    height: 0;
    width: 0;
}
.text-\[\#333333\], .text-\[\#333\] {
    --tw-text-opacity: 1;
    color: rgb(51 51 51 / var(--tw-text-opacity));
}
.zkzx-artificial-item-top .title p {
    font-size: 14px;
    margin-top: 8px;
    opacity: .9;
    color:white;
}
.zkzx-artificial-item-top .title strong {
    font-size: 16px;
    font-weight: 700;
    color:white;
}
.text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
}
.text-center {
    text-align: center;
}
.pt-12 {
    padding-top: 3rem;
}
.max-w-\[1380px\] {
    max-width: 1380px;
}
.mx-auto {
    margin-left: auto;
    margin-right: auto;
}
.zkzx-gift-express .active, .zkzx-gift-express .active .sub-title, .zkzx-gift-express .active .title {
    color: var(--font-color-white) !important;
}
.zkzx-gift-express .active, .zkzx-gift-express .active .sub-title, .zkzx-gift-express .active .title {
    color: var(--font-color-white) !important;
}
.zkzx-gift-express-item .sub-title-line {
    background: var(--sub-font-color);
    height: 15px;
    margin: 0 10px;
    width: 1px;
}
.zkzx-gift-express {
    background: var(--bg-color);
    border-radius: 8px;
    min-height: 652px;
    position: relative;
    width: 268px;
}
.zkzx-gift-express .gift-earth {
    bottom: 0;
    height: 144px;
    position: absolute;
    right: 0;
    width: 129px;
}

.zkzx-gift-express-item .title {
    color: #262729;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
}
.zkzx-gift-express-item .title {
    color: #262729;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
}
.zkzx-index-container {
    background-repeat: no-repeat;
    background-size: 100% 600px;
}

.zkzx-index-container {
    background-repeat: no-repeat;
    background-size: 100% 600px;
}
.flex-1 {
    flex: 1 1 0%;
}
.w-full {
    width: 100%;
}
.mx-auto {
    margin-left: auto;
    margin-right: auto;
}
.zkzx-substation-content .side .box2 p {
    color: var(--sub-font-color);
    font-size: 14px;
    font-weight: 400;
    width: 237px;
}
.zkzx-substation-content .side .box3 img {
    height: 10px;
    width: auto;
}
.text-\[\#77838f\] {
    --tw-text-opacity: 1;
    color: rgb(119 131 143 / var(--tw-text-opacity));
}
.text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
}
.text-center {
    text-align: center;
}
.pt-5 {
    padding-top: 1.25rem;
}
.pb-12 {
    padding-bottom: 3rem;
}
.zkzx-substation {
    position: relative;
    width: 100%;
}
.zkzx-substation-content-bg {
    display: flex;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 11px;
    width: 100%;
}
.zkzx-gift-express{
    min-height: 652px;
    position: relative;
    width: 268px;
    background: var(--bg-color);
    border-radius: 8px;
}
.zkzx-tools-item:hover {
    border: 1px solid var(--primary-color-1);
}

.zkzx-index-container-banner {
    display: flex;
    flex-direction: column;
    height: 393px;
}
.view-more {
    display: flex;
    justify-content: center;
}
.zkzx-api .active {
    height: 278px;
    transition: width 2s;
    width: 542px;
}
.view-more .btn {
    background: linear-gradient(60deg, #e6a23d, #fd5c30);
    border-radius: 17px;
    color: var(--font-color-white);
    cursor: pointer;
    font-size: 14px;
    height: 34px;
    line-height: 34px;
    margin: 38px 0 54px;
    text-align: center;
    width: 98px;
}
.zkzx-api-item .active-btn {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 60px 20px;
    width: 100%;
}
.zkzx-index-container-banner .message-container {
    background-color: rgba(255, 255, 255, 0.5);
    height: 64px;
}
.zkzx-tools-more .btn {
    color: var(--font-color-white);
    cursor: pointer;
    font-size: 14px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    width: 98px;
    background: linear-gradient(60deg, rgb(230, 162, 61), rgb(253, 92, 48));
    border-radius: 17px;
    margin: 38px 0px 54px;
}
.zkzx-tools-item-bottom {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    padding: 0px 13px 8px 25px;
}
.zkzx-tools-item-middle {
    color: var(--sub-font-color);
    font-size: 14px;
    margin: 15px 0px 7px;
    padding: 0px 25px;
}
.zkzx-tools-item-top {
    align-items: center;
    color: rgb(38, 39, 41);
    display: flex;
    font-size: 1rem;
    font-weight: 700;
    justify-content: flex-start;
    padding: 28px 26px 15px;
}
.zkzx-tools-item {
    box-shadow: var(--box-shadow);
    height: 230px;
    margin-bottom: 16px;
    width: 300px;
    background: var(--bg-color);
    border-radius: 8px;
}
.zkzx-tools {
    align-items: center;
    margin-left: 5%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.title {
  font-size: 24px;
  margin-bottom: 20px;
}

.description {
  margin-bottom: 40px;
}

.more-btn .ant-btn {
  margin-top: 20px;
}

.text-center {
  text-align: center;
}
.background-white {
  background-color: #fff;
}
.carousel-image {
  width: 100%;
  height: 100px;
}

.membership, .credits {
  cursor: pointer;
  margin-right: 20px;
  display: flex;
  align-items: center;
}

.messages-icon, .profile-icon {
  margin-left: 16px;
  cursor: pointer;
}
.zkzx-substation-more {
    display: flex;
    justify-content: center;
}
.zkzx-substation-more .btn {
    background: linear-gradient(60deg, #e6a23d, #fd5c30);
    border-radius: 17px;
    color: var(--font-color-white);
    cursor: pointer;
    font-size: 14px;
    height: 34px;
    line-height: 34px;
    margin: 38px 0 54px;
    text-align: center;
    width: 98px;
}

.zkzx-gift {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-bottom: 66px;
}
.zkzx-gift-express{
    min-height: 652px;
    position: relative;
    width: 268px;
    background: var(--bg-color);
    border-radius: 8px;
}
.zkzx-gift-list-item .img{
    background-color: var(--img-p);
    height: 200px;
    width: 203px;
    display: flex;
}
.zkzx-gift-list-item .img img{
    height: 100%;
    width: 100%;
    display: flex
}
.zkzx-gift-list-item .content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}
.zkzx-gift-list-item .content .info {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 200px;
}
.zkzx-gift-list-item .content .sale{
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 200px;
    margin: 20px 0px;
}
.zkzx-gift-list-item .content .sale .btn{
    color: var(--font-color-white);
    cursor: pointer;
    font-size: 14px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    width: 60px;
    background: var(--linear-gradient-1);
    border-radius: 17px;
}
.zkzx-gift-list-item .content .sale .group{
    align-items: center;
    display: flex;
    justify-content: flex-start;
}
.zkzx-gift {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-bottom: 66px;
}
.zkzx-gift-express-item{
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 80px;
    justify-content: center;
    padding: 0px 24px;
}
.zkzx-gift-express-item .title {
    color: rgb(38, 39, 41);
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
}
.zkzx-gift-express .active{
    background: linear-gradient(270deg, #ffff, #fd802e);
}
.zkzx-gift-list-item:hover {
    border: 1px solid var(--primary-color-1);
}
.zkzx-gift-express .active {
    background: linear-gradient(270deg, var(--font-color-white), var(--primary-color-1));
}
.zkzx-artificial {
    align-items: flex-start;
    display: flex;
    justify-content: center;
    padding-bottom: 66px;
    width: 100%;
}
.zkzx-artificial-item {
    align-items: center;
    box-shadow: var(--box-shadow);
    display: flex;
    flex-direction: column;
    height: 520px;
    justify-content: flex-start;
    width: 328px;
    background: var(--bg-color);
    border-radius: 20px;
    transition: margin 0.3s ease 0s;
}
.zkzx-artificial-item-top {
    align-items: center;
    background-image: url(/public/index_files/home-img6.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    height: 114px;
    justify-content: space-between;
    width: 100%;
    padding: 0px 18px 0px 27px;
}
.zkzx-artificial-item-content .item:hover, .zkzx-artificial-item-content .item:last-child{
    background-image: url(/public/index_files/home-img11.png)
}
.zkzx-artificial-item-content {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 14px;
    width: 100%;
}
.zkzx-artificial-item-content .item {
    align-items: center;
    display: flex;
    height: 98px;
    justify-content: center;
    width: 100%;
    min-width: 300px;
}
.zkzx-artificial-item-content .label {
    align-items: center;
    display: -webkit-inline-box;
    flex: 1 1 150px;
    justify-content: flex-start;
}
.zkzx-artificial-item-content .desc {
    color: var(--sub-font-color);
    font-size: 14px;
    flex: 1 1 150px;
    width: 224px;
}
.zkzx-artificial-item-content .label .point {
    background-color: var(--primary-color-2);
    height: 8px;
    margin-right: 12px;
    margin-top: 7px;
    width: 8px;
    border-radius: 4px;
}
.zkzx-artificial-item-content .label .title {
    color: var(--font-color);
    font-size: 14px;
    font-weight: 700;
    flex: 1 1 150px;
    margin-bottom: 2px;
}
.zkzx-artificial-item:hover {
    margin: -15px 0px 0px;
}
.zkzx-substation {
    position: relative;
    width: 100%;
}
.zkzx-substation-content-bg {
    display: flex;
    justify-content: center;
    left: 0px;
    position: absolute;
    top: 11px;
    width: 100%;
}
.zkzx-substation-content {
    align-items: center;
    display: flex;
    height: 621px;
    justify-content: space-between;
    width: 100%;
}
.zkzx-substation-content .side {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    width: 344px;
}
.zkzx-substation-content .side .item {
    align-items: flex-start;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    z-index: 1;
    transition: transform 0.3s ease 0s;
}
.zkzx-substation-content .side .box1 {
    align-items: center;
    display: flex;
    justify-content: flex-start;
}
.zkzx-substation-content .side .box2 {
    margin: 13px 0px 12px;
}
.zkzx-gift-list-item {
    align-items: center;
    background: var(--bg-color);
    border-radius: 8px;
    box-shadow: var(--box-shadow);
    display: flex;
    flex-direction: column;
    height: 316px;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-right: 10px;
    width: calc(25% - 10px);
}
.item-box:hover{
  transform: scale(1.1);
}

.zkzx-api {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-bottom: 66px;
}
.zkzx-api-item {
    color: var(--font-color-white);
    height: 278px;
    transition-behavior: normal;
    transition-duration: 2s;
    transition-timing-function: ease;
    transition-delay: 0s;
    transition-property: width;
    max-width: 188px;
}
.zkzx-api .active {
    height: 278px;
    transition: width .5s;
    max-width: 542px;
}
.message-container {
    background-color: #ffffff80;
    height: 64px;
}

.zkzx-api-item .title {
    font-size: 14px;
    font-weight: 400;
    margin: 48px 0 14px 19px;
}
.zkzx-api-item .active-sub-title {
    display: flex;
    flex-direction: column;
    height: 65px;
    justify-content: space-between;
    margin: 33px 0 45px 52px;
}
.zkzx-api-item .active-title {
    color: var(--font-color-white);
    font-size: 16px;
    font-weight: 700;
    margin: 52px 0 0 51px;
}
.zkzx-api-item .active-sub-title .title-item {
    align-items: center;
    color: var(--font-color-white);
    display: flex;
    font-size: 14px;
    font-weight: 300;
    justify-content: flex-start;
}
.zkzx-gift-express-item .title{
    color: #262729;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
}
.zkzx-api-item .sub-title {
    font-size: 14px;
    font-weight: 300;
    margin: 0 43px 0 19px;
}
.zkzx-gift-express-item .sub-title{
    align-items: center;
    color: var(--sub-font-color);
    display: flex;
    font-size: 14px;
    justify-content: flex-start;
}
.clamp-2 {
    -webkit-line-clamp: 2;
}

.clamp {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
}
.zkzx-api-item .active-btn {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 60px  20px;
    width: 100%;
}
.zkzx-api-item .active-btn .free, .zkzx-api-item .active-btn .more {
    color: var(--primary-color-4);
    cursor: pointer;
    font-size: 14px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    width: 240px;
}
.zkzx-api-item .active-btn .more {
    background: #ffe1c6;
}
.zkzx-api-item .active-btn .free, .zkzx-api-item .active-btn .more {
    color: var(--primary-color-4);
    cursor: pointer;
    font-size: 14px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    width: 240px;
}
.text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
}
.zkzx-api-item .active-btn .free, .zkzx-api-item .active-btn .more {
    color: var(--primary-color-4);
    cursor: pointer;
    font-size: 14px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    width: 240px;
}
.zkzx-api-item .active-btn .free {
    background: var(--color-white);
}
.zkzx-tools-item-top .i {
    border-style: solid;
    height: 0;
    width: 0;
}
.zkzx-api .active {
    height: 278px;
    transition: width .5s;
    width: 542px;
}

.zkzx-api-item {
    color: var(--font-color-white);
    height: 278px;
    transition: width .5s;
    width: 188px;
}

.zkzx-gift-list-item .content .info .info-price{
    color: var(--primary-color-3);
    font-size: 14px;
    font-weight: 700;
}
body {
  min-width: 1080px;
}
</style>
