<template>
  <HeaderComponent></HeaderComponent>
  <a-layout style="padding:4rem 1rem 4rem 4rem;width: 100%;margin-top: 20px;">
    <div class="sidebar arco-layout arco-layout-has-sider flex justify-start items-start w-full"
      style="padding: 10px 0 0 10px;margin-left:-3%;">
      <a-layout-sider width="220" class="zkzx-default-layout-sider !mr-1 !bg-[#f5f5f5]">
        <a-menu mode="inline" theme="light" :open-keys="openKeys" @update:openKeys="handleOpenChange"
          @click="handleMenuClick" style="text-align: left;">
          <!-- 个人中心 -->
          <a-menu-sub-menu key="sub1" title="个人中心">
            <template #icon><icon-user></icon-user></template>
            <a-menu-item key="3">控制台</a-menu-item>
            <a-menu-item key="4">消息通知</a-menu-item>
            <a-menu-item key="5">财务明细</a-menu-item>
            <a-menu-item key="6">反馈中心</a-menu-item>
            <a-menu-item key="7">邀请好友</a-menu-item>
          </a-menu-sub-menu>

          <!-- 验号中心 -->
          <a-menu-sub-menu key="sub2" title="验号中心">
            <template #icon><icon-search></icon-search></template>
            <a-menu-item key="8">防P图验号</a-menu-item>
            <a-menu-item key="9">照妖镜验号</a-menu-item>
            <a-menu-item key="10">小号透视</a-menu-item>
            <a-menu-item key="11">批量照妖镜验号</a-menu-item>
            <a-menu-item key="12">黑号打标</a-menu-item>
          </a-menu-sub-menu>

          <!-- 卡首屏工具 -->
          <a-menu-sub-menu key="sub3" title="卡首屏工具">
            <template #icon><icon-home></icon-home></template>
            <a-menu-item key="13">淘宝卡首屏</a-menu-item>
            <a-menu-item key="14">1688卡首屏</a-menu-item>
            <a-menu-item key="15">抖音卡首屏</a-menu-item>
            <a-menu-item key="16">拼多多卡首屏</a-menu-item>
          </a-menu-sub-menu>

          <!-- 无痕秒单 -->
          <a-menu-sub-menu key="sub4" title="无痕秒单">
            <template #icon><icon-book></icon-book></template>
            <a-menu-item key="17">淘宝无痕秒单</a-menu-item>
            <a-menu-item key="18">批量淘宝无痕秒单</a-menu-item>
            <a-menu-item key="19">拼多多无痕秒单</a-menu-item>
          </a-menu-sub-menu>

          <!-- 人工流量 -->
          <a-menu-sub-menu key="sub5" title="人工流量">
            <template #icon><icon-computer></icon-computer></template>
            <a-menu-item key="20">人工流量</a-menu-item>
            <a-menu-item key="21">批量订单</a-menu-item>
            <a-menu-item key="22">订单列表</a-menu-item>
          </a-menu-sub-menu>

          <!-- 礼品代发 -->
          <a-menu-sub-menu key="sub6" title="礼品代发">
            <template #icon><icon-gift></icon-gift></template>
            <a-menu-item key="23">礼品购买</a-menu-item>
            <a-menu-item key="24">我的订单</a-menu-item>
            <a-menu-item key="25">发件信息</a-menu-item>
          </a-menu-sub-menu>

          <!-- 标签推送 -->
          <a-menu-sub-menu key="sub7" title="标签推送">
            <template #icon><icon-tags></icon-tags></template>
            <a-menu-item key="26">旺旺打标</a-menu-item>
            <a-menu-item key="27">拼多多打标</a-menu-item>
            <a-menu-item key="28">手淘直播间推送</a-menu-item>
            <a-menu-item key="29">购物车推送</a-menu-item>
            <a-menu-item key="30">淘内免费推送</a-menu-item>
            <a-menu-item key="31">手淘搜索推送</a-menu-item>
            <a-menu-item key="32">猜你喜欢推送</a-menu-item>
            <a-menu-item key="33">微详情推送</a-menu-item>
          </a-menu-sub-menu>

          <!-- 流量入口 -->
          <a-menu-sub-menu key="sub8" title="流量入口">
            <template #icon><icon-send></icon-send></template>
            <a-menu-item key="34">焕新</a-menu-item>
            <a-menu-item key="35">汇吃</a-menu-item>
            <a-menu-item key="36">淘抢购</a-menu-item>
            <a-menu-item key="37">亲宝贝</a-menu-item>
            <a-menu-item key="38">潮玩城</a-menu-item>
            <a-menu-item key="39">极有家</a-menu-item>
            <a-menu-item key="40">全球购</a-menu-item>
            <a-menu-item key="41">有好货</a-menu-item>
            <a-menu-item key="42">IFISHION</a-menu-item>
            <a-menu-item key="43">每日好店</a-menu-item>
            <a-menu-item key="44">手淘清仓</a-menu-item>
            <a-menu-item key="45">猜你喜欢</a-menu-item>
            <a-menu-item key="46">必买清单</a-menu-item>
            <a-menu-item key="47">首页钻展</a-menu-item>
            <a-menu-item key="48">品牌捡漏</a-menu-item>
            <a-menu-item key="49">中国制造</a-menu-item>
            <a-menu-item key="50">手淘网红集合</a-menu-item>
          </a-menu-sub-menu>
        </a-menu>
      </a-layout-sider>

      <a-layout-content style="width:100%;height: 100%;margin-left: 10px;;">
        <component :is="activeComponent" :id="form.id"></component>
      </a-layout-content>
    </div>
  </a-layout>
  <footerComponent></footerComponent>
</template>
<script>
import { defineComponent, ref, watch, reactive } from 'vue';
import { useRoute } from 'vue-router';
import {
  Layout,
  Menu
  // Row,
  // Col,
  // // Card,
  // Button
  // Carousel
} from 'ant-design-vue'
import footerComponent from '../components/footer.vue'

import HeaderComponent from '../components/center/banner.vue'
import UserComponent from '../components/center/center.vue'
import InfomationComponent from '../components/center/infomation.vue'
import DetailComponent from '../components/center/detail.vue'
import FeedbackComponent from '../components/center/feedback.vue'
import InviteComponent from '../components/center/invite.vue'
import VerificationComponent from '../components/mirror/verification.vue'
import TbComponent from '../components/mirror/tb.vue'
import PerspectiveComponent from '../components/mirror/perspective.vue'
import TbBatchComponent from '../components/mirror/tbBatch.vue'
import MarkComponent from '../components/mirror/mark.vue'
import FsTbComponent from '../components/tools/fstb.vue'
import ylbbComponent from '../components/tools/1688.vue'
import pddComponent from '../components/tools/pdd.vue'
import tkComponent from '../components/tools/tk.vue'
import Tb2Component from '../components/order/tb.vue'
import pdd2Component from '../components/order/pdd.vue'
import orderCompinent from '../components/artificial/order.vue'
import batchComponent from '../components/artificial/batch.vue'
import listComponent from '../components/artificial/list.vue'
import giftComPonent from '../components/gift/giftPurchase.vue'
import giftOrderComPonent from '../components/gift/giftOrder.vue'
import changeUserComPonent from '../components/gift/changeUser.vue'
import wangwangComPonent from '../components/mark/wangwang.vue'
import flowComponent from '../components/flow/flow.vue'

export default defineComponent({
  name: 'ToolsPage',
  components: {
    HeaderComponent,
    footerComponent,
    batchComponent,
    pddComponent,
    tkComponent,
    UserComponent,
    InfomationComponent,
    changeUserComPonent,
    pdd2Component,
    DetailComponent,
    wangwangComPonent,
    FeedbackComponent,
    InviteComponent,
    VerificationComponent,
    TbComponent,
    PerspectiveComponent,
    TbBatchComponent,
    MarkComponent,
    FsTbComponent,
    Tb2Component,
    listComponent,
    orderCompinent,
    giftComPonent,
    giftOrderComPonent,
    ylbbComponent,
    flowComponent,
    'a-layout': Layout,
    'a-layout-sider': Layout.Sider,
    'a-menu': Menu,
    'a-menu-item': Menu.Item,
    'a-menu-sub-menu': Menu.SubMenu
    // 'a-icon': Icon
  },
  setup () {
    const activeComponent = ref('VerificationComponent')
    const form = reactive({
      id: 1
    })

    const openKeys = ref([]);

    function handleOpenChange (keys) {
      // 只允许一个子菜单展开
      openKeys.value = keys.length ? [keys[keys.length - 1]] : [];
    }

    function handleMenuClick (item) {
      switch (item.key) {
        case '3':
          activeComponent.value = 'UserComponent'
          break
        case '4':
          activeComponent.value = 'InfomationComponent'
          break
        case '5':
          activeComponent.value = 'DetailComponent'
          break
        case '6':
          activeComponent.value = 'FeedbackComponent'
          break
        case '7':
          activeComponent.value = 'InviteComponent'
          break
        case '8':
          activeComponent.value = 'VerificationComponent'
          break
        case '9':
          activeComponent.value = 'TbComponent'
          break
        case '10':
          activeComponent.value = 'PerspectiveComponent'
          break
        case '11':
          activeComponent.value = 'TbBatchComponent'
          break
        case '12':
          activeComponent.value = 'MarkComponent'
          break
        case '13':
          activeComponent.value = 'FsTbComponent'
          break
        case '14':
          activeComponent.value = 'ylbbComponent'
          break
        case '15':
          activeComponent.value = 'tkComponent'
          break
        case '16':
          activeComponent.value = 'pddComponent'
          break
        case '17':
          console.log(form.id)
          form.id = 1
          activeComponent.value = 'Tb2Component'
          break
        case '18':
          activeComponent.value = 'Tb2Component'
          form.id = 2
          break
        case '19':
          activeComponent.value = 'pdd2Component'
          break
        case '20':
          activeComponent.value = 'orderCompinent'
          break
        case '21':
          activeComponent.value = 'batchComponent'
          break
        case '22':
          activeComponent.value = 'listComponent'
          break
        case '23':
          activeComponent.value = 'giftComPonent'
          break
        case '24':
          activeComponent.value = 'giftOrderComPonent'
          break
        case '25':
          activeComponent.value = 'changeUserComPonent'
          break
        case '26':
          form.id = 1;
          activeComponent.value = 'wangwangComPonent';
          break;
        case '27':
          form.id = 2;
          activeComponent.value = 'wangwangComPonent';
          break;
        case '28':
          form.id = 3;
          activeComponent.value = 'wangwangComPonent';
          break;
        case '29':
          form.id = 4;
          activeComponent.value = 'wangwangComPonent';
          break;
        case '30':
          form.id = 5;
          activeComponent.value = 'wangwangComPonent';
          break;
        case '31':
          form.id = 6;
          activeComponent.value = 'wangwangComPonent';
          break;
        case '32':
          form.id = 7;
          activeComponent.value = 'wangwangComPonent';
          break;
        case '33':
          form.id = 8;
          activeComponent.value = 'wangwangComPonent';
          break;
        case '34':
          form.id = 1;
          activeComponent.value = 'flowComponent';
          break;
        case '35':
          form.id = 2;
          activeComponent.value = 'flowComponent';
          break;
        case '36':
          form.id = 3;
          activeComponent.value = 'flowComponent';
          break;
        case '37':
          form.id = 4;
          activeComponent.value = 'flowComponent';
          break;
        case '38':
          form.id = 5;
          activeComponent.value = 'flowComponent';
          break;
        case '39':
          form.id = 6;
          activeComponent.value = 'flowComponent';
          break;
        case '40':
          form.id = 7;
          activeComponent.value = 'flowComponent';
          break;
        case '41':
          form.id = 8;
          activeComponent.value = 'flowComponent';
          break;
        case '42':
          form.id = 9;
          activeComponent.value = 'flowComponent';
          break;
        case '43':
          form.id = 10;
          activeComponent.value = 'flowComponent';
          break;
        case '44':
          form.id = 11;
          activeComponent.value = 'flowComponent';
          break;
        case '45':
          form.id = 12;
          activeComponent.value = 'flowComponent';
          break;
        case '46':
          form.id = 13;
          activeComponent.value = 'flowComponent';
          break;
        case '47':
          form.id = 14;
          activeComponent.value = 'flowComponent';
          break;
        case '48':
          form.id = 15;
          activeComponent.value = 'flowComponent';
          break;
        case '49':
          form.id = 16;
          activeComponent.value = 'flowComponent';
          break;
        case '50':
          form.id = 17;
          activeComponent.value = 'flowComponent';
          break;
        default:
          activeComponent.value = null
      }
    }

    const route = useRoute();
    const componentMap = {
      3: 'UserComponent',
      4: 'InfomationComponent',
      5: 'DetailComponent',
      6: 'FeedbackComponent',
      7: 'InviteComponent',
      8: 'VerificationComponent',
      9: 'TbComponent',
      10: 'PerspectiveComponent',
      11: 'TbBatchComponent',
      12: 'MarkComponent',
      13: 'FsTbComponent',
      14: 'ylbbComponent',
      15: 'tkComponent',
      16: 'pddComponent',
      17: 'Tb2Component',
      18: 'Tb2Component',
      19: 'pdd2Component',
      20: 'orderCompinent',
      21: 'batchComponent',
      22: 'listComponent',
      23: 'giftComPonent',
      24: 'giftOrderComPonent',
      25: 'changeUserComPonent',
      26: 'wangwangComPonent',
      27: 'wangwangComPonent',
      28: 'wangwangComPonent',
      29: 'wangwangComPonent',
      30: 'wangwangComPonent',
      31: 'wangwangComPonent',
      32: 'wangwangComPonent',
      33: 'wangwangComPonent',
      34: 'flowComponent',
      35: 'flowComponent',
      36: 'flowComponent',
      37: 'flowComponent',
      38: 'flowComponent',
      39: 'flowComponent',
      40: 'flowComponent',
      41: 'flowComponent',
      42: 'flowComponent',
      43: 'flowComponent',
      44: 'flowComponent',
      45: 'flowComponent',
      46: 'flowComponent',
      47: 'flowComponent',
      48: 'flowComponent',
      49: 'flowComponent',
      50: 'flowComponent'
    };

    watch(
      () => route.params.key,
      (newKey) => {
        const key = Number(newKey);
        if (key) {
          activeComponent.value = componentMap[key];
        }
      },
      { immediate: true }
    );

    return {
      activeComponent,
      handleMenuClick,
      form,
      handleOpenChange,
      openKeys
    }
  }
})
</script>

<style>
:where(.css-dev-only-do-not-override-19iuou).ant-menu-light.ant-menu-inline .ant-menu-item::after {
    position: absolute;
    inset-block: 0;
    inset-inline-end: 0;
    border-inline-end: transparent;
    transform: scaleY(0.0001);
    opacity: 0;
    transition: transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    content: "";
}
:where(.css-dev-only-do-not-override-19iuou).ant-menu-light .ant-menu-item-selected {
    background-color: transparent;
}
:where(.css-dev-only-do-not-override-19iuou).ant-menu-light .ant-menu-submenu-selected >.ant-menu-submenu-title {
    color: rgb(253,128,46) !important;
}
:where(.css-dev-only-do-not-override-19iuou).ant-menu-light .ant-menu-item-selected {
    color: rgb(253,128,46) !important;
}
html,
body {
  height: 120%;
  margin: 0;
  padding: 0;
}

a-layout {
  height: 120vh;
  width: 120vw;
}

a-layout-content {
  flex: 1;
  padding: 16px;
  overflow: auto;
}
</style>
