<template>
  <a-popover v-model:visible="visible" placement="bottom-end" trigger="hover" style="z-index: 2010; width: 251px;">
    <div class="w-10 h-10 cursor-pointer" @mouseenter="debouncedShow" @mouseleave="debouncedHide">
      <img class="w-full h-full" src="../../../../public/index_files/15grzx-icon1.png" alt="用户信息" />
    </div>
    <template #content>
      <div class="flex justify-start items-center">
        <div class="w-10 h-10 mr-2">
          <img class="w-full h-full" src="../../../../public/index_files/15grzx-icon1-1.png" alt="" />
        </div>
        <div>
          <div class="text-[#fd802e] text-base">{{ props.username }}</div>
          <div>有效期至：{{ formattedEndTime }}</div>
        </div>
      </div>
      <div class="flex justify-between items-center py-3 my-2 border-y border-[#f8f8f8]">
        <div class="flex justify-start items-center">
          <img class="w-6 h-6 mr-2" src="../../../../public/index_files/15grzx-img6.png" alt="" />
          <span>赠送天数</span>
        </div>
        <a-button type="primary" size="small" round>续费/升级</a-button>
      </div>
      <div>
        <div class="flex justify-start items-center">
          <img class="w-10 h-10" src="../../../../public/index_files/yc-icon3.png" alt="" />
          <span>个人中心</span>
        </div>
        <div class="grid grid-cols-2 gap-x-2 gap-y-2 px-8">
          <div class="cursor-pointer py-1 px-2 hover:bg-[#f8f8f8]" @click="goToShopTools(3)">控制台</div>
          <div class="cursor-pointer py-1 px-2 hover:bg-[#f8f8f8]" @click="goToShopTools(4)">消息中心</div>
          <div class="cursor-pointer py-1 px-2 hover:bg-[#f8f8f8]" @click="goToShopTools(5)">财务管理</div>
          <div class="cursor-pointer py-1 px-2 hover:bg-[#f8f8f8]" @click="goToShopTools(7)">邀请好友</div>
          <div class="cursor-pointer py-1 px-2 hover:bg-[#f8f8f8]" @click="handleClick">修改密码</div>
          <div class="cursor-pointer py-1 px-2 hover:bg-[#f8f8f8]" @click="logoutAccount">退出</div>
        </div>
      </div>
    </template>
  </a-popover>

  <a-modal v-model:visible="visible2" title="修改密码" @cancel="handleCancel" @before-ok="handleBeforeOk">
    <a-form :model="form">
      <a-form-item field="newPassword" label="新密码">
        <a-input v-model="form.newPassword" type="password" />
      </a-form-item>
      <a-form-item field="rePassword" label="重复密码">
        <a-input v-model="form.rePassword" type="password" />
      </a-form-item>
      <a-form-item field="password" label="旧密码">
        <a-input v-model="form.password" type="password" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script setup>
import { ref, defineProps, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { resetpwd, logout } from '@api';
import { useAuthStore } from '@/stores/auth';
import { Message } from '@arco-design/web-vue';

const props = defineProps({
  username: {
    type: String,
    required: true
  },
  endTime: {
    type: Number,
    required: true
  }
});

const visible2 = ref(false);
const visible = ref(false);

const form = reactive({
  password: '',
  newPassword: '',
  rePassword: ''
});

const router = useRouter();
const authStore = useAuthStore();

const formattedEndTime = new Date(props.endTime * 1000).toLocaleDateString('zh-CN', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit'
});

const debouncedShow = () => {
  clearTimeout(timeout);
  timeout = setTimeout(() => {
    visible.value = true;
  }, 200);
};

const debouncedHide = () => {
  clearTimeout(timeout);
  timeout = setTimeout(() => {
    visible.value = false;
  }, 200);
};

let timeout;

const logoutAccount = async () => {
  try {
    await logout();
    await authStore.clearToken();
    Message.success('退出成功');
    location.reload()
  } catch (error) {
    Message.warning('请登陆后操作');
  }
};

const handleClick = () => {
  visible2.value = true;
};

const handleBeforeOk = async (done) => {
  try {
    await resetpwd({ oldpassword: form.password, newpassword: form.newPassword, renewpassword: form.rePassword });
    setTimeout(() => {
      done();
      visible2.value = false; // 关闭模态框
    }, 3000);
  } catch (err) {
    console.error(err);
  }
};

const handleCancel = () => {
  visible2.value = false;
};

const goToShopTools = (key) => {
  router.push({ name: 'shopTools', params: { key } });
};
</script>

<style scoped>
/* 添加样式 */
</style>
