<template>
  <div class="flex-1">
    <div class="mb-6">
      <a-card class="rounded-lg" body-style="padding: 0">
        <div class="flex justify-start items-center h-[68px] border-b-[1px] border-[#e5e5e5] capitalize">
          <div class="flex-auto flex justify-start items-center">
            <div><!--[--><!--]--></div>
            <div class="font-bold text-[#fe802d] text-2xl mr-4" style="    padding-left: 27px;">黑号打标</div>
            <div class="flex-1"><!--[--><!--]--></div>
          </div>
          <div><!--[--><!--]--></div>
        </div>
        <div class="pt-5 pb-7 px-5">
          <div class="my-5 w-[100%]" style="line-height: 20px;">
            <a-form @submit.prevent="submitFeedback" label-width="100px" class="form-container"
              style="margin-left: -30px;">
              <a-form-item label="打标类型" class="form-item">
                <a-radio-group v-model="feedbackType" class="radio-group">
                  <a-radio :value="type.value" v-for="type in feedbackTypes" :key="type.value" class="radio-item">
                    {{ type.text }}
                  </a-radio>
                </a-radio-group>
              </a-form-item>
              <a-form-item label="旺旺号" required class="form-item">
                <a-input v-model="feedWangaWang" rows="4" placeholder="请输入旺旺号" class="input-style wide-input" style="height:40px" />
              </a-form-item>
              <a-form-item label="订单编号" required class="form-item">
                <a-input v-model="feedOrderNum" rows="4" placeholder="请输入订单编号" class="input-style wide-input" style="height:40px"/>
              </a-form-item>
              <a-form-item label="举报内容" required class="form-item">
                <a-textarea v-model="feedbackContent" rows="8" placeholder="请输入您想要反馈内容" class="wide-input"
                  style="height:150px" />
              </a-form-item>
              <a-form-item label="举报证据" class="form-item">
                  <a-upload list-type="picture-card"  class="upload-container" action="/" :limit="1" :file-list="fileList" v-model="feedbackImages"
                    :custom-request="customRequest" @change="handleImagesChange" image-preview>
                  </a-upload>
              </a-form-item>
              <a-form-item>
                <div class="upload-info">仅支持上传3张图片</div>
              </a-form-item>
              <a-form-item class="form-item">
                <a-button style="width: 200px;height: 40px;background-color: darkorange;" @click="submitFeedback"
                  type="primary">举报TA</a-button>
              </a-form-item>
            </a-form>
          </div>
        </div>
      </a-card>
    </div>
    <div class="mb-5">
      <a-card class="rounded-lg" body-style="padding: 0">
        <div class="h-[68px] border-[#e5e5e5] capitalize flex justify-start items-center px-5">
          <img src="../../../public/zyj-icon1-jilu.png" alt="" />
          <span class="text-xl text-[#333] font-bold p-2">举报记录</span>
        </div>
        <a-table :columns="columns" :data="tableData" class="p-4">
          <template #type="{ record }">
            <span v-if="record.type === `1`">跑单</span>
            <span v-else-if="record.type === `2`">骚扰</span>
            <span v-else-if="record.type === `3`">骗子</span>
            <span v-else-if="record.type === `4`">打假</span>
            <span v-else-if="record.type === `5`">差评</span>
            <span v-else-if="record.type === `6`">淘客</span>
            <span v-else-if="record.type === `7`">P图</span>
            <span v-else-if="record.type === `8`">降权</span>
          </template>

          <template #images="{ record }">
            <a-image :src="record.image" width="80px" height="80px"></a-image>
          </template>

          <template #status="{ record }">
            <span v-if="record.status === 1">待审核</span>
            <span v-else-if="record.status === 2">审核通过</span>
            <span v-else-if="record.status === 3">审核失败</span>
          </template>

        </a-table>
      </a-card>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { Message } from '@arco-design/web-vue';
import { blackAccount, getBlackList, uploadFiles } from '@api';

export default {
  setup () {
    const feedbackType = ref('');
    const feedbackContent = ref('');
    const feedWangaWang = ref('');
    const feedOrderNum = ref('')
    const feedbackImages = ref([]);
    const customRequest = async (options) => {
      const controller = new AbortController();
      const { onError, onSuccess, fileItem, name = 'file' } = options;
      const formData2 = new FormData();
      formData2.append(name, fileItem.file);

      try {
        const res = await uploadFiles(formData2, { controller });
        const imageUrl = res.data.data.fullurl;

        // 确保 images 是数组
        feedbackImages.value = Array.isArray(imageUrl) ? imageUrl.map(url => ({ url })) : [{ url: imageUrl }];

        onSuccess(res);
      } catch (error) {
        onError(error);
      }
    };
    const feedbackTypes = ref([
      { value: '1', text: '跑单（兔子）' },
      { value: '2', text: '骚扰（蜜獾）' },
      { value: '3', text: '骗子（狐狸）' },
      { value: '4', text: '打假（鳄鱼）' },
      { value: '5', text: '差评（野狗）' },
      { value: '6', text: '淘客（老鼠）' },
      { value: '7', text: 'P图' },
      { value: '8', text: '降权' }
    ]);
    const records = ref([]);
    const tableData = ref([]);
    const columns = ref([
      {
        title: '举报时间',
        dataIndex: 'create_time',
        align: 'center'
      },
      {
        title: '订单编号',
        dataIndex: 'order_number',
        align: 'center'
      },
      {
        title: '旺旺帐号',
        dataIndex: 'wangwang',
        align: 'center'
      },
      {
        title: '举报类型',
        dataIndex: 'type',
        slotName: 'type',
        align: 'center'
      },
      {
        title: '审核结果',
        dataIndex: 'status',
        slotName: 'status',
        align: 'center'
      },

      {
        title: '举报截图',
        dataIndex: 'image',
        slotName: 'images',
        align: 'center'
      },

      {
        title: '平台回复',
        dataIndex: 'reply',
        align: 'center'
      }
    ]);

    const submitFeedback = async () => {
      const blackRes = await blackAccount({
        wangwang: feedWangaWang.value,
        type: feedbackType.value,
        content: feedbackContent.value,
        Image: feedbackImages.value,
        order_number: feedOrderNum.value
      });
      if (blackRes.data.code !== 20000) {
        Message.warning(blackRes.data.msg)
      } else {
        Message.success(blackRes.data.msg)
      }

      getTableList();
    };

    const getTableList = async () => {
      const tableRes = await getBlackList({ type: feedbackType.value });
      tableData.value = tableRes.data.data.list;
    };
    getTableList();
    return {
      feedbackType,
      feedbackContent,
      feedbackImages,
      feedbackTypes,
      records,
      tableData,
      feedOrderNum,
      feedWangaWang,
      columns,
      submitFeedback,
      getTableList,
      customRequest
    };
  }
}
</script>
<style scoped>
.radio-group {
  display: flex;
  flex-wrap: nowrap; /* 不换行 */
}

.radio-item {
  white-space: nowrap; /* 文字不换行 */
  margin-right: 20px; /* 控制间距 */
}
.a-form-item {
  margin-left: 0 !important;
}

.text-[#fe802d] {
  color: rgb(254, 128, 45);
}

.font-bold {
  font-weight: 700;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.mr-4 {
  margin-right: 1rem;
}

.justify-start {
  justify-content: flex-start;
}

.items-center {
  align-items: center;
}

.flex-auto {
  flex: 1 1 auto;
}

.flex {
  display: flex;
}

.capitalize {
  text-transform: capitalize;
}

.border-[#e5e5e5] {
  border-color: rgb(229, 229, 229);
}

.border-b-[1px] {
  border-bottom-width: 1px;
}

.h-[68px] {
  height: 68px;
}

.form-container {
  max-width: 800px;
  /* 设定最大宽度 */
  margin: 0 auto;
  /* 居中对齐 */
}

.form-item {
  margin-bottom: 20px;
  /* 统一表单项的间距 */
}

.upload-container {
  margin-left: 10px;
}

.upload-info {
  text-align: left;
  margin-left: 20px;
}
</style>
<style scoped>
.a-form-item {
  margin-left: 0 !important;
}

.text-\[\#fe802d\] {
  --tw-text-opacity: 1;
  color: rgb(254 128 45 / var(--tw-text-opacity));
}

.font-bold {
  font-weight: 700;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.mr-4 {
  margin-right: 1rem;
}

.justify-start {
  justify-content: flex-start;
}
.wide-input {
  width: 100%; /* 设置宽度为100% */
  max-width: 800px; /* 可选：设置最大宽度 */
  margin: 0 auto; /* 居中对齐 */
  background-color: white;
  border: 1px solid #c8c8c8
}
.items-center {
  align-items: center;
}

.flex-auto {
  flex: 1 1 auto;
}

.flex {
  display: flex;
}

.capitalize {
  text-transform: capitalize;
}

.border-\[\#e5e5e5\] {
  --tw-border-opacity: 1;
  border-color: rgb(229 229 229 / var(--tw-border-opacity));
}

.border-b-\[1px\] {
  border-bottom-width: 1px;
}

.justify-start {
  justify-content: flex-start;
}

.items-center {
  align-items: center;
}

.h-\[68px\] {
  height: 68px;
}

.flex {
  display: flex;
}
</style>
