<template>
  <a-button @click="handleClick" type="text" status="warning">查看二维码</a-button>
  <a-modal v-model:visible="visible"  width="auto" :footer="false">
    <img v-if="form.qrCode" :src="form.qrCode" alt="二维码" style="width: 500px; height: 500px" />
    <p v-else>正在生成二维码...</p>
  </a-modal>
</template>

<script>
import { ref, reactive, watch } from 'vue';
import QRCode from 'qrcode';

export default {
  props: {
    url: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const form = reactive({
      qrCode: ''
    });
    const visible = ref(false);

    // 监视 url 变化并生成二维码
    watch(() => props.url, (newUrl) => {
      if (newUrl) {
        generateQRCode(newUrl);
      }
    });

    const handleClick = async () => {
      visible.value = true;
      await generateQRCode(props.url);
    };

    const generateQRCode = async (url) => {
      try {
        form.qrCode = await QRCode.toDataURL(url);
      } catch (error) {
        console.error('二维码生成失败:', error);
        form.qrCode = ''; // 生成失败时清空二维码
      }
    };

    const handleCancel = () => {
      visible.value = false;
      form.qrCode = ''; // 清空二维码
    };

    return {
      visible,
      form,
      handleCancel,
      handleClick
    };
  }
}
</script>

<style scoped>
/* 添加任何必要的样式 */
</style>
