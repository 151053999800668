<template>
  <div class="flex-1">
    <div class="mb-6 h-full">
      <div class="bg-white rounded-lg px-5 pb-5 flex flex-col h-full">
        <div class="flex justify-start items-center h-[68px] border-b-[1px] border-[#e5e5e5] capitalize"
          style="padding: 10px;">
          <div class="flex-auto flex justify-start items-center">
            <div></div>
            <div class="font-bold text-[#fe802d] text-2xl mr-4" style="color: darkorange;">我的订单</div>
            <div class="flex-1"></div>
          </div>
          <div></div>
        </div>
        <div class="" style="float: left;">
          <!-- 单选按钮组 -->
          <a-space direction="vertical" size="large" style="float: inline-start;padding-top: 20px">
            <a-radio-group type="button" v-model="radioValue">
              <a-radio value="0">全部</a-radio>
              <a-radio value="1">进行中</a-radio>
              <a-radio value="2">已完成</a-radio>
              <a-radio value="3">取消中</a-radio>
              <a-radio value="4">已退款</a-radio>
            </a-radio-group>
          </a-space>
          <!-- 表单搜索区域 -->
          <div class="p-3 rounded" style="margin-top: 43px !important;" >
    <a-form layout="inline" class="arco-form" style="margin-top: 43px !important;">
      <!-- 订单号 -->
      <a-form-item label="订单号" class="arco-form-item">
        <a-input
          v-model="orderNumber"
          placeholder="请输入订单号"
          class="arco-input-size-large"
          style="width: 150px;"
        />
      </a-form-item>

      <!-- 关键字 -->
      <a-form-item label="关键字" class="arco-form-item">
        <a-input
          v-model="keyword"
          placeholder="请输入关键字"
          class="arco-input-size-large"
          style="width: 150px;"
        />
      </a-form-item>

      <!-- 商品标题 -->
      <a-form-item label="商品标题" class="arco-form-item">
        <a-input
          v-model="productTitle"
          placeholder="请输入商品标题"
          class="arco-input-size-large"
          style="width: 150px;"
        />
      </a-form-item>

      <!-- 操作按钮 -->
      <a-form-item class="arco-form-item">
        <a-button
          type="primary"
          status="warning"
          @click="handlePageChange"
          class="arco-btn-primary"
        >
          查询
        </a-button>
        <a-button
          type="outline"
          status="warning"
          class="ml-2.5 arco-btn-outline"
          @click="resetForm"
        >
          重置
        </a-button>
      </a-form-item>
    </a-form>
  </div>
          <!-- 表格区域 -->
          <div class="flex flex-1 w-full" style="overflow: auto;padding-top: 60px">
            <a-table :columns="columns" :data="tableData" :loading="loading" row-key="id" bordered hoverable :current-page="currentPage" :total="totalItems"
              class="arco-table-size-large arco-table-border arco-table-hover arco-table-empty arco-table-layout-fixed w-full h-full">
              <template #do>
                <a-space>
                <a-button>
                  查看
                </a-button>
                <a-button>
                  查看
                </a-button>
              </a-space>
              </template>
            </a-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';
import { getFlowList } from '@api'

const radioValue = ref(0);
const orderNumber = ref('');
const keyword = ref('');
const productTitle = ref('');
const loading = ref(false);
const currentPage = ref(1);
const totalItems = ref(50);

const columns = [
  { title: '序号', dataIndex: 'id', key: 'id', align: 'center' },
  { title: 'ID', dataIndex: 'outid', key: 'outid', align: 'center' },
  { title: '商品 / 链接', dataIndex: 'request', key: 'request', align: 'center' },
  { title: '优化类型', dataIndex: 'type_name', key: 'type_name', align: 'center' },
  { title: '进度', dataIndex: 'alltasknum', key: 'alltasknum', align: 'center' },
  { title: '状态', dataIndex: 'success_number', key: 'success_number', align: 'center' },
  { title: '操作', dataIndex: 'operation', key: 'operation', slotName: 'do', align: 'center' }
];

const tableData = ref([]);

const handlePageChange = async () => {
  try {
    const tabData = await getFlowList({ status: radioValue.value });
    tableData.value = tabData.data.data.list;
  } catch (error) {
    console.log(error)
  }
};

watch(radioValue, (newValue) => {
  console.log('Radio value changed to:', newValue);
  handlePageChange();
});

onMounted(() => {
  handlePageChange()
})

</script>

<style>
.arco-radio-button.arco-radio-checked {
    color: rgb(255, 151, 22) !important;
    background-color: rgb(var(--orange-1));
    border-color: rgb(255, 151, 22) !important; /* 确保边框颜色也变化 */
}
.arco-radio-button, .arco-radio-group-button {
    border-radius: var(--border-radius-small);
    line-height: 26px;
}
a-input{
    width: 25%;
}

.arco-radio-group-button {
    background-color: var(--color-fill-2);
    display: inline-flex;
    padding: 1.5px;
}
.arco-radio-button>input[type=radio], .arco-radio>input[type=radio] {
    height: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 0;
}.arco-radio-button-content {
    display: block;
    padding: 0 12px;
    position: relative;
}
.label{
    width: 70px;
}
</style>
