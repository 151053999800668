<template>
  <div class="flex-1">
    <div class="mb-6">
      <div class="bg-white rounded-lg px-5 pb-5">
        <div class="flex justify-start items-center h-[68px] border-b-[1px] border-[#e5e5e5] capitalize"
          style="padding: 10px;">
          <div class="flex-auto flex justify-start items-center">
            <div></div>
            <div class="font-bold text-[#fe802d] text-2xl mr-4" style="color: darkorange;">旺旺打标</div>
            <div class="flex-1">
              <div class="tool-header-content">
                <p>优惠套餐：购买套餐可低至 0.5 积分/次</p>
              </div>
            </div>
          </div>
          <div>
            <div class="tool-header-right text-base text-[#333]">
              <div class="flex justify-start items-center mr-3">
                <img class="w-6 h-6 mr-2" src="/zhaoyaojing_files/biaoti-icon1.png" alt="" />
                <span>单次：0积分</span>
              </div>
              <div class="flex justify-start items-center">
                <img class="w-6 h-6 mr-2" src="/zhaoyaojing_files/biaoti-icon2.png" alt="" />
                <span>会员当日次数：0/0次</span>
              </div>
            </div>
          </div>
        </div>
        <div v-html="generatedTooltips"></div>
        <div class="pt-5 pb-7 flex justify-between items-start">
          <div class="left-side flex-1">
            <el-form class="mt-5">
              <el-form-item class="is-required asterisk-left flex items-center w-[60%] mx-auto"
                style="margin-left: 0px;">
                <el-form-item class="is-required asterisk-left flex items-center w-[60%] mx-auto" required label="场景"
                  label-width="150" style="width: 2400px;margin-left:30px">

                  <template v-for="(scene, index) in scenes" :key="index">
                    <el-button :type="scene.isActive ? 'primary' : ''" @click="selectScene(index)"
                      style="margin-bottom: 5px;border: 1px solid #FD802E">
                      {{ scene.name }}
                    </el-button>

                  </template>
                </el-form-item>
              </el-form-item>

              <template v-if="scenes.length > 0 && currentSceneIndex !== null">
                <template v-if="selectedType.type === 1 || selectedType.type === 2">
                  <el-form-item required class="is-required asterisk-left flex items-center w-[60%] mx-auto"
                    style="margin-left: 30px;" label="打标方式">
                    <a-radio-group style="margin-left: 55px;" v-model="selectedType.radioType">
                      <a-radio value="1">单个打标</a-radio>
                      <a-radio value="2">批量打标</a-radio>
                    </a-radio-group>
                  </el-form-item>
                </template>

                <template v-for="(inputField, inputIndex) in scenes[currentSceneIndex].json"
                  :key="`field-${inputIndex}`">
                  <el-form-item :label="inputField.name" :required="inputField.required"
                    class="is-required asterisk-left flex items-center w-[60%] mx-auto" style="margin-left: 30px;"
                    label-width="150px">

                    <!-- 仅对 currentSceneIndex 为 0 和 1 的第一个输入框进行判断 -->
                    <template v-if="inputIndex === 0 && (currentSceneIndex === 0)">
                      <!-- 根据 radioType 显示文本域或输入框 -->
                      <template v-if="selectedType.radioType === '1'">
                        <!-- 单个打标：显示输入框 -->
                        <el-input v-model="inputField.value" :placeholder="`请输入${inputField.name}`"
                          style="width: 700px;height: 40px;"></el-input>
                      </template>
                      <template v-else-if="selectedType.radioType === '2'">
                        <!-- 批量打标：显示文本域 -->
                        <el-input type="textarea" v-model="inputField.value" :placeholder="`请输入${inputField.name}`"
                          style="width: 700px;height: 80px;"></el-input>
                      </template>
                    </template>

                    <!-- 仅对 currentSceneIndex 为 2 的第一个输入框进行判断 -->
                    <template v-else-if="inputIndex === 1 && currentSceneIndex === 1">
                      <!-- 根据 radioType 显示文本域或输入框 -->
                      <template v-if="selectedType.radioType === '1'">
                        <!-- 单个打标：显示输入框 -->
                        <el-input v-model="inputField.value" :placeholder="`请输入${inputField.name}`"
                          style="width: 700px;height: 40px;"></el-input>
                      </template>
                      <template v-else-if="selectedType.radioType === '2'">
                        <!-- 批量打标：显示文本域 -->
                        <el-input type="textarea" v-model="inputField.value" :placeholder="`请输入${inputField.name}`"
                          style="width: 700px;height: 80px;"></el-input>
                      </template>
                    </template>

                    <!-- 对于其他输入框，保持原样 -->
                    <template v-else>
                      <template v-if="inputField.type === 'select'">
                        <a-select v-model="inputField.value" placeholder="请选择">
                          <a-option v-for="option in inputField.options" :key="option.value" :label="option.label"
                            :value="option.value"></a-option>
                        </a-select>
                      </template>
                      <template v-else>
                        <el-input v-model="inputField.value" :placeholder="`请输入${inputField.name}`"
                          style="width: 700px;height: 40px;"></el-input>
                      </template>
                    </template>

                  </el-form-item>
                </template>
              </template>

              <el-form-item class="asterisk-left flex items-center w-[60%] mx-auto">
                <p class="text-sm text-[#e63400]">{{ errorMessage }}</p>
              </el-form-item>
              <el-form-item class="asterisk-left flex w-[60%] mx-auto" style="margin-right: 790px;color: red;">
                <div v-html="generatedTooltips2"></div>
              </el-form-item>
              <el-form-item class="asterisk-left flex w-[60%] mx-auto" style="margin-right: 790px;">

                <el-button type="primary" size="large" style="width: 200px;" @click="generateQRCode"><span v-if="currentSceneIndex === 0 || currentSceneIndex === 1">开始打标</span><span v-else>开始推送</span></el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>

    <div class="mb-6" v-if="selectedType.type === 1">
      <div class="bg-white rounded-lg px-5 pb-5">
        <div class="mt-5 bg-white rounded-lg px-5 pb-5">
          <div class="flex justify-start items-center h-[68px] border-[#e5e5e5] capitalize border-none">
            <div class="flex-auto flex justify-start items-center">
              <div>
                <div class="flex justify-start items-center"><img src="../../assets/img/zyj-icon1-jilu.png" alt=""><span
                    class="text-xl text-[#333] font-bold p-2">旺旺打标记录</span></div>
              </div>
              <div class="font-bold text-[#fe802d] text-2xl mr-4"></div>
              <div class="flex-1"></div>
            </div>
            <div></div>
          </div>
          <a-table :columns="wwColumns" :data="wwData" row-key="id" pagination="false">
            <template #status="{ record }">
              <span>
                {{ record.status === 1 ? '待推送' : record.status === 2 ? '已推送' : record.status === 3 ? '推送完成' : '未知状态' }}
              </span>
            </template>
            <template #QRcode="{ record }">
              <showQrCode :url="record.image"></showQrCode>
            </template>
          </a-table>
          <el-pagination :total="total" v-model:current-page="currentPage" :page-size="pageSize"
            @size-change="handlePageSizeChange" @current-change="handlePageChange"></el-pagination>
        </div>

      </div>
    </div>

    <div class="mb-6" v-else-if="selectedType.type === 2">
      <div class="bg-white rounded-lg px-5 pb-5">
        <div class="mt-5 bg-white rounded-lg px-5 pb-5">
          <div class="flex justify-start items-center h-[68px] border-[#e5e5e5] capitalize border-none">
            <div class="flex-auto flex justify-start items-center">
              <div>
                <div class="flex justify-start items-center"><img src="../../assets/img/zyj-icon1-jilu.png" alt=""><span
                    class="text-xl text-[#333] font-bold p-2">拼多多打标记录</span></div>
              </div>
              <div class="font-bold text-[#fe802d] text-2xl mr-4"></div>
              <div class="flex-1"></div>
            </div>
            <div></div>
          </div>
          <a-table :columns="pddColumns" :data="pddData" row-key="id" pagination="false">
            <template #QRcode="{ record }">
              <showQrCode :url="record.image"></showQrCode>
            </template>
            <template #status="{ record }">
              <span>
                {{ record.status === 1 ? '待推送' : record.status === 2 ? '已推送' : record.status === 3 ? '推送完成' : '未知状态' }}
              </span>
            </template>
          </a-table>
          <el-pagination :total="total" v-model:current-page="currentPage" :page-size="pageSize"
            @size-change="handlePageSizeChange" @current-change="handlePageChange"></el-pagination>
        </div>

      </div>
    </div>

    <div class="mb-6" v-else>
      <div class="bg-white rounded-lg px-5 pb-5">
        <div class="mt-5 bg-white rounded-lg px-5 pb-5">
          <div class="flex justify-start items-center h-[68px] border-[#e5e5e5] capitalize border-none">
            <div class="flex-auto flex justify-start items-center">
              <div>
                <div class="flex justify-start items-center"><img src="../../assets/img/zyj-icon1-jilu.png" alt=""><span
                    class="text-xl text-[#333] font-bold p-2">推送记录</span></div>
              </div>
              <div class="font-bold text-[#fe802d] text-2xl mr-4"></div>
              <div class="flex-1"></div>
            </div>
            <div></div>
          </div>
          <a-table :columns="pushColumns" :data="pushData" row-key="id" pagination="false">
            <template #QRcode="{ record }">
              <showQrCode :url="record.image"></showQrCode>
            </template>
            <template #status="{ record }">
              <span>
                {{ record.status === 1 ? '待推送' : record.status === 2 ? '已推送' : record.status === 3 ? '推送完成' : '未知状态' }}
              </span>
            </template>
          </a-table>
          <el-pagination :total="total" v-model:current-page="currentPage" :page-size="pageSize"
            @size-change="handlePageSizeChange" @current-change="handlePageChange"></el-pagination>
        </div>

      </div>
    </div>

    <div class="mb-6" style="text-align: left;">
      <div class="bg-white rounded-lg px-5 pb-5">
        <el-tabs v-model="activeTab" class="mt-4 tip-text">
          <el-tab-pane label="功能介绍">
            <p>1、只需输入要打标的宝贝链接，以及要推送宝贝的目标旺旺号，执行打标后，打标成功的特征：通过打标词搜索商品，能将打标的商品排名从后面排名提高到前20名。期间不需要旺旺号介入任何流程。</p>
          </el-tab-pane>
          <el-tab-pane label="使用场景">
            <p>1、商品核心词权重低搜索不到，但是想通过核心词搜索下单成交。</p>
            <p>2、目标淘宝旺旺被打标成功增加首页猜你喜欢标签注入、加大淘宝首页出现概率。</p>
            <p>3、精准推送给意向客户，之前有咨询没付款的，使用工具后，将宝贝推送到他们的搜索页。</p>
            <p>4、对于复购率很高的消耗品，列出老客户或者精准客户旺旺清单，然后批量推送，快速引爆流量。</p>
          </el-tab-pane>
          <el-tab-pane label="注意事项">
            <p>1、宝贝需要有一定的销量，一般10个以上，销量过低打标成功率低。</p>
            <p>2、如果通过打标词在前10位商品未找到，可以在前20位商品寻找，如果仍然未找到请手淘看一下推荐的搜索词是否包含打标词。</p>
            <p>3、有时被打标的宝贝位置可能在20页开外,可以往下多翻几页，或者改长点的词，该商品在该打标词权重太低。</p>
            <p>4、并非所有类目的宝贝可成功进行打标，有店铺评分过低、成*用品、或者降权的情况下可能无法成功打标。</p>
            <p>5、打标成功的有效时长根据淘宝更新时间，打标不是都会有足迹的，打标是将搜索词和商品推荐给目标用户。</p>
            <p>6、打标前可以用自己的号测试看下效果，再进行批量打标，如果效果不满意可以试试关键词卡首屏功能。</p>
            <p>7、同一个账号每日被打标次数不超过3次打标为佳，黑号等被淘宝关进小黑屋的账号打标无效。</p>
          </el-tab-pane>
          <el-tab-pane label="常见问题">
            <p> 1、核心词下的商品权重低，希望粉丝通过核心词搜索下单，进而提高商品在核心词的权重。
              商家使用旺旺打标，将商品的排名在指定搜索词提高到前10，粉丝再搜索后下单购买（打标过商品会在指定的旺旺号下搜索排名在前10），生意参谋数据显示是核心词搜索下单的。能快速的提高商品在核心词的权重值。 </p>
            <p> 2、希望粉丝的旺旺号有自己商品的标签，有标签后下单购买权重提升更高。如何将粉丝旺旺号的标签打上指定的商品标签呢？ </p>
            <p>
              商家使用旺旺打标，将商品标签第一次打标到用户旺旺号上，用户通过打标词进行浏览商品（为了能快速打上商品的标签，同时做货比三家，同店浏览，咨询客服）完成一次操作，选择店铺里同类商品这样操作进行3到4次，该粉丝有90%会出现该商品的标签
              。 </p>
            <p>3、商家希望粉丝能通过手淘首页的猜你喜欢找到商品下单。</p>
            <p> 第一步：结合打标攻略2将商品的标签出现在淘宝标签里，搜索找到目标商品，进行浏览，收藏关注，和客服咨询商品的常规信息。 </p>
            <p> 第二步：寻找同类的其他商品大概浏览三到四个，随便收藏几个竞品，后期可以取消收藏。 </p>
            <p> 第三步：重复上述第一、二步，一天操作两到三次。重复3天，重复浏览的商品大概率会在猜你喜欢中出现。 </p>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, watch, reactive, computed } from 'vue'
import { getPushType, pushTask, getAllPushList, getWwScreenList, getPddScreenList } from '@api'
import { Message } from '@arco-design/web-vue';
import showQrCode from '../order/component/showQrCode.vue';
import iconPath from './img/4gmlp-icon8.png';

export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },
  components: {
    showQrCode
  },
  setup (props) {
    const scenes = ref([]);
    const pddColumns = ref([
      {
        title: 'uid',
        dataIndex: 'uid',
        align: 'center'
      },
      {
        title: '宝贝ID',
        dataIndex: 'goods_id',
        align: 'center'
      },
      {
        title: '消费积分',
        dataIndex: 'cost',
        align: 'center'
      },
      {
        title: '状态',
        dataIndex: 'status',
        slotName: 'status',
        align: 'center'
      },
      {
        title: '状态',
        dataIndex: 'create_time',
        align: 'center'
      }
    ])
    const pddData = ref([])
    const wwColumns = ref([
      {
        title: '推送搜索旺旺账号',
        dataIndex: 'wangwang',
        align: 'center'
      },
      {
        title: '宝贝ID',
        dataIndex: 'goods_id',
        align: 'center'
      },
      {
        title: '创建时间',
        dataIndex: 'create_time',
        align: 'center'
      },
      {
        title: '状态',
        dataIndex: 'status',
        slotName: 'status',
        align: 'center'
      },
      {
        title: '消费',
        dataIndex: 'cost',
        align: 'center'
      }
    ])
    const wwData = ref([])
    const pushColumns = ref([
      {
        title: '推送搜索旺旺账号',
        dataIndex: 'wangwang',
        align: 'center'
      },
      {
        title: '宝贝ID',
        dataIndex: 'url',
        align: 'center'
      },
      {
        title: '提交时间',
        dataIndex: 'create_time',
        align: 'center'
      },
      {
        title: '完成时间',
        dataIndex: 'finish_number',
        align: 'center'
      },
      {
        title: '状态',
        dataIndex: 'status',
        slotName: 'status',
        align: 'center'
      },
      {
        title: '消费',
        dataIndex: 'cost',
        align: 'center'
      }
    ])

    const generatedTooltips = computed(() => {
      return scenes.value.map(scene => {
        if (scene.isActive && scene.remark.length) {
          return `
            <div class="tool-tip">
              <div class="tool-tip-icon mr-2"><img src="${iconPath}"></div>
              <div class="tool-tip-content">
                <p>${scene.remark} （<span class="tip-text">${scene.remark1}</span>）：</p>
                <p class="pt-1 scene2">${scene.remark2}</p>
              </div>
            </div>
          `;
        }
        return '';
      }).join('');
    });

    const generatedTooltips2 = computed(() => {
      return scenes.value.map(scene => {
        if (scene.isActive && scene.btn_remark.length) {
          return `
                <p>${scene.btn_remark}</p>
          `;
        }
        return '';
      }).join('');
    });

    const pushData = ref([])
    const currentSceneIndex = ref(null);
    const columns = ref([]);
    const inviteDetails = ref([]);

    const errorMessage = ref('');
    const qrCodeData = ref(null);

    const transformScenes = (scenes) => {
      scenes.forEach(scene => {
        scene.json.forEach(inputField => {
          // 确保 value 是一个有效的对象
          if (typeof inputField.value === 'object' && inputField.value !== null && !Array.isArray(inputField.value)) {
            inputField.type = 'select';
            const transformedOptions = [];
            // 确保 inputField.value 是一个对象，并且其中的值和标签都存在
            for (const [value, label] of Object.entries(inputField.value)) {
              if (value !== undefined && label !== undefined) {
                transformedOptions.push({ value: value, label: label });
              }
            }
            inputField.options = transformedOptions;
          } else {
            // 否则认定为 'text' 类型
            inputField.type = 'text';
            inputField.options = []; // 清空 options，确保非 select 类型没有 options
          }
        });
      });
    };

    const getTYpe = async () => {
      try {
        const typeRes = await getPushType();
        scenes.value = typeRes.data.data.map(scene => ({ ...scene, isActive: false }));
        console.log(scenes.value)
        if (scenes.value.length > 0) {
          selectScene(0);
        }
      } catch (error) {
        console.error(error);
      }
    };

    getTYpe();
    const selectedType = reactive(
      {
        type: '',
        radioType: '1'
      }
    )

    const selectScene = (index) => {
      scenes.value.forEach((scene, idx) => {
        scene.isActive = idx === index;
      });
      currentSceneIndex.value = index;
      selectedType.type = scenes.value[index].type;
      console.log(selectedType.type)
    };

    const generateQRCode = async () => {
      if (currentSceneIndex.value === null) {
        errorMessage.value = '请选择一个场景';
        return;
      }
      const currentScene = scenes.value[currentSceneIndex.value];
      const params = { type: currentScene.type };
      let allFieldsFilled = true;

      for (const inputField of currentScene.json) {
        if (inputField.required && !inputField.value) {
          errorMessage.value = `请输入${inputField.name}`;
          allFieldsFilled = false;
          break;
        }
        params[inputField.field] = inputField.value;
      }

      if (!allFieldsFilled) return;

      errorMessage.value = '';

      try {
        const tbRes = await pushTask(params);
        qrCodeData.value = tbRes.data;
        inviteDetails.value = tbRes.data.details;
        Message.success('请求成功')
      } catch (error) {
        errorMessage.value = error.message || '生成二维码失败';
      }
    };

    const getPullList = async () => {
      try {
        if (currentSceneIndex.value === null) {
          Message.error('未选中任何场景');
          return;
        }
        const currentScene = scenes.value[currentSceneIndex.value];
        const pullList = await getAllPushList({ type: currentScene.type, page: 1, size: 10 });
        pushData.value = pullList.data.data.list;
      } catch (error) {
        Message.error(error.message || '获取列表失败');
      }
    };

    const getwwList = async () => {
      try {
        const pullList = await getWwScreenList({ page: 1, size: 10 })
        wwData.value = pullList.data.data.list
      } catch (error) {
        Message.error(error)
      }
    }

    const getPddList = async () => {
      try {
        const pullList = await getPddScreenList({ page: 1, size: 10 })
        pddData.value = pullList.data.data.list
      } catch (error) {
        Message.error(error)
      }
    }
    watch(currentSceneIndex, (newValue) => {
      if (newValue !== null) {
        getPullList()
        getwwList();
        getPddList();
      }
    });

    watch(scenes, (newScenes) => {
      transformScenes(newScenes);
    }, { immediate: true });

    watch(() => props.id, (newId) => {
      const index = scenes.value.findIndex(scene => scene.type === newId);
      if (index !== -1) {
        selectScene(index);
      }
    });

    return {
      scenes,
      errorMessage,
      qrCodeData,
      generatedTooltips,
      columns,
      inviteDetails,
      currentSceneIndex,
      pushColumns,
      pushData,
      wwColumns,
      wwData,
      pddColumns,
      pddData,
      selectScene,
      getPddList,
      getwwList,
      getPullList,
      generateQRCode,
      selectedType,
      generatedTooltips2
    };
  }
}
</script>

<style>
.scene2 {
  left: 50;
  position: absolute;
}
.content button, .tool-header-right {
    align-items: center;
    display: flex;
}

.content button {
    background-color: var(--bg-color);
    border: 1px solid var(--primary-color-1);
    border-radius: 4px;
    color: var(--sub-font-color);
    cursor: pointer;
    font-size: 14px;
    height: 38px;
    justify-content: center;
    margin: 0px 15px 12px 0px;
    padding: 0px 15px;
    width: auto;
}

.content .isActive {
    background-color: var(--primary-color-1);
    color: var(--font-color-white);
}

.tool-tip {
    align-items: flex-start;
    background-color: var(--primary-color-11);
    border: 1px solid var(--primary-color-8);
    border-radius: 4px;
    display: flex;
    height: auto;
    justify-content: flex-start;
    padding: 10px 18px;
    width: calc(100% - 70px);
    min-height: 80px
}

.tool-tip-icon {
    align-self: start;
}

.tool-tip-content {
    color: rgb(51, 51, 51);
    font-size: 14px;
    font-weight: 400;
}

.tool-tip .tip-text {
    color: rgb(253, 128, 46);
}

.tab-pane-content {
    color: rgb(51, 51, 51);
    font-size: 16px;
    padding: 10px 0px 20px;
}

.tab-pane-content p {
    line-height: 30px;
}

.el-form-item__label {
    align-items: flex-start;
    box-sizing: border-box;
    color: var(--el-text-color-regular);
    display: inline-flex;
    font-size: var(--el-form-label-font-size);
    height: 32px;
    justify-content: flex-start !important;
    line-height: 32px;
    padding: 0 13px 0 0px;
    font-size: 1rem !important;
    /* margin-left: 0px; */
}
.left-side .content button {
    align-items: center;
    background-color: var(--bg-color);
    border: 1px solid var(--primary-color-1);
    border-radius: 4px;
    color: var(--sub-font-color);
    cursor: pointer;
    display: flex;
    font-size: 14px;
    height: 38px;
    justify-content: center;
    margin: 0 15px 12px 0;
    padding: 0 15px;
    width: auto;
}
.content .isActive {
    background-color: var(--primary-color-1);
    color: var(--font-color-white);
}
.tool-header-content {
  background-image: url(/public/zhaoyaojing_files/coupon-bg.png);
  color: var(--bg-color);
  font-size: 14px;
  font-weight: 400;
  height: 20px;
  line-height: 20px;
  text-align: center;
  width: 260px;
}
</style>
