import { createRouter, createWebHashHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import ShopTools from '../views/ShopTools.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/shopTools/:key',
    name: 'shopTools',
    component: ShopTools
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('../views/news.vue')
  },
  {
    path: '/jiameng',
    name: 'jiameng',
    component: () => import('../views/jiameng.vue')
  },
  {
    path: '/api',
    name: 'api',
    component: () => import('../views/api.vue')
  },
  {
    path: '/vip',
    name: 'vip',
    component: () => import('../views/vip.vue')
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;
