<template>
  <a-button @click="handleClick">查看详情</a-button>
  <a-modal v-model:visible="visible" @cancel="handleCancel" :on-before-ok="handleBeforeOk" :simple=true>
    <div :style="{ display: 'flex' }">
      <a-card :style="{ width: '520px' }" :title="Message.title" :bordered="false">
        <template #extra>
          <a-link>{{ Message.pushTime }}</a-link>
        </template>
        {{ Message.content }}
      </a-card>
    </div>
  </a-modal>
</template>

<script>
import { ref, reactive } from 'vue';

export default {
  props: ['content', 'pushTime', 'title'],
  setup (props) {
    const visible = ref(false);
    const Message = reactive({
      content: props.content,
      pushTime: props.pushTime,
      title: props.title
    })

    const handleClick = () => {
      visible.value = true;
    };
    const handleBeforeOk = async () => {
      await new Promise(resolve => setTimeout(resolve, 20));
      return true;
    };
    const handleCancel = () => {
      visible.value = false;
    }

    return {
      visible,
      Message,
      handleClick,
      handleBeforeOk,
      handleCancel
    }
  }
}
</script>
