<template>
  <div >
    <div class="bg-white rounded-lg px-5 pb-5 pt-5" >
      <div class="bg-[#fd802e] h-4 w-full rounded-lg"></div>
      <div class="flex justify-between p-4 mx-3 rounded-b-lg" style="box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px">
        <div class="flex-1 border-r border-[#e5e5e5] pb-3">
          <div class="contentInfo">
          <div class="text-[#fd802e] text-xl">
            <img src="http://www.lyre220.cn:8016/tools/center/invite" alt="" />
            <span>邀请说明：</span>
          </div>
          <p class="leading-7">
            1、复制下方链接邀请您的朋友注册，注册后成为你的徒弟。
          </p>
          <p class="leading-7">2、您的徒弟邀请注册的用户成为您的徒孙。</p>
          <p class="leading-7">
            3、您的徒弟、徒孙升级VIP或充值积分，您均可获得奖励或免费兑换VIP。
          </p>
          <p class="leading-7">4、现金奖励可以申请提现、升级会员、充值积分。</p>
          <div class="flex items-center h-[40px] mt-4 mr-4">
            <div class="w-[60px] min-w-[60px] mr-4">
              <strong>邀请链接</strong>
            </div>
            <div class="flex justify-start items-center h-[40px] rounded-lg bg-[#fff6e6]">
              <div class="min-w-[270px] px-4 flex-1 line-clamp-1">
                {{ paymentPerson.invateCode }}
              </div>
              <button @click="copyInviteLink"
                class="w-[120px] h-[40px] text-center bg-[#fd802e] rounded-r-lg text-white">
                复制
              </button>
            </div>
          </div>
        </div>
        </div>
        <div class="flex-1 pl-5">
          <div class="grid grid-cols-5 gap-4">
            <!-- 统计数据展示区域 -->
            <div v-for="(stat, index) in stats" :key="index"
              class="flex flex-col justify-center h-[140px] items-center rounded-lg" :class="stat.bgClass" style="position: relative;">
              <div class="w-14 h-14">
                <img class="w-full h-full" :src="stat.icon" alt="" />
              </div>
              <div>
                <span class="text-2xl" :class="stat.valueClass">{{
                  stat.value
                  }}</span>{{ stat.suffix }}
                <span v-if="stat.unit">{{ stat.unit }}</span>
              </div>
              <strong>{{ stat.label }}</strong>
            </div>
          </div>
          <!-- 提现和充值使用按钮 -->
          <div class="w-full flex justify-between mt-4">
            <button class="flex-1 h-10 leading-10 text-white bg-[#fd802e] rounded-lg mr-2 " style="background-color: #fd802e">
              <tixian @tixian-complete="getUserData"/>
            </button>
            <button class="flex-1 h-10 leading-10 bg-[#fff6e6] text-[#fd802e] border border-[#fd802e] rounded-lg ml-2">
              <alipayRecharge :score="paymentPerson.score"/>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-5 bg-white rounded-lg px-5 pb-5">
      <div class="flex-auto flex justify-start items-center">
        <div>
          <div class="flex justify-start items-center"><span
              class="text-xl text-[#333] font-bold p-2">邀请明细</span></div>
        </div>
      </div>
      <a-table :columns="columns" :data="inviteDetails" row-key="id" pagination="false" titles="邀请好友">
      </a-table>
    </div>

    <div class="mt-5 bg-white rounded-lg px-5 pb-5">
      <div class="flex-auto flex justify-start items-center">
        <div>
          <div class="flex justify-start items-center"><span
              class="text-xl text-[#333] font-bold p-2">提现明细</span></div>
        </div>
      </div>
      <a-table :columns="pushColumns" :data="pushData" row-key="id" pagination="false" >
        <template #registerTime="{ record }">
          {{ record.registerTime }}
        </template>
        <template #inviteProject="{ record }">
          {{ record.inviteProject }}
        </template>
        <template #projectSpend="{ record }">
          {{ record.projectSpend }}
        </template>
        <template #invitedPerson="{ record }">
          {{ record.invitedPerson }}
        </template>
        <template #rewardPoints="{ record }">
          {{ record.rewardPoints }}
        </template>
      </a-table>
    </div>

    <!-- <div class="mt-5 bg-white rounded-lg px-5 pb-5">
      <div class="flex-auto flex justify-start items-center">
        <div>
          <div class="flex justify-start items-center"><span
              class="text-xl text-[#333] font-bold p-2">邀请TOP榜</span></div>
        </div>
      </div>
      <a-table :columns="topColumns" :data="inviteTopDetails" row-key="id" pagination="false" titles="邀请好友">
        <template #registerTime="{ record }">
          {{ record.registerTime }}
        </template>
        <template #inviteProject="{ record }">
          {{ record.inviteProject }}
        </template>
        <template #projectSpend="{ record }">
          {{ record.projectSpend }}
        </template>
        <template #invitedPerson="{ record }">
          {{ record.invitedPerson }}
        </template>
        <template #rewardPoints="{ record }">
          {{ record.rewardPoints }}
        </template>
      </a-table>
    </div> -->
  </div>
</template>

<script>
import { ref, reactive } from 'vue';
import alipayRecharge from './component/alipayCharge.vue';
import tixian from './component/tixian.vue'
import { getUserInfo, getInviteList, getPushLog } from '@api'
import { Message } from '@arco-design/web-vue';

export default {
  components: {
    alipayRecharge,
    tixian
  },
  setup () {
    const inviteLink = 'http://www.lyre220.cn:8016?aff=WERIQJXA4GLYK';
    const stats = ref([
      { icon: '/invite_files/16yqhy-icon2.png', value: '0', suffix: '人', label: '邀请好友', bgClass: 'bg-[#fefaf4]', valueClass: 'text-[#fd802e]' },
      { icon: '/invite_files/16yqhy-icon3.png', value: '5%', suffix: '%', label: '提成比例', bgClass: 'bg-[#fff6f6]', valueClass: 'text-[#fe3f66]' },
      { icon: '/invite_files/16yqhy-icon4.png', value: '5%', suffix: '元', label: '累计推广佣金', bgClass: 'bg-[#fff6f6]', valueClass: 'text-[#fe3f66]' },
      { icon: '/invite_files/16yqhy-icon5.png', value: '5%', suffix: '元', label: '已提现佣金', bgClass: 'bg-[#fff6f6]', valueClass: 'text-[#fe3f66]' },
      { icon: '/invite_files/16yqhy-icon6.png', value: '5%', suffix: '元', label: '佣金余额', bgClass: 'bg-[#fff6f6]', valueClass: 'text-[#fe3f66]' }
      // ... 更多统计数据项
    ]);

    const inviteDetails = ref([])
    const pushData = ref([])

    const columns = [
      { title: '注册时间', dataIndex: 'create_time' },
      { title: '邀请项目', dataIndex: 'order_type' },
      { title: '项目消费', dataIndex: 'money' },
      { title: '被邀请人', dataIndex: 'invite_user' },
      { title: '奖励积分', dataIndex: 'return_money' },
      { title: '状态', dataIndex: 'status' },
      { title: '备注', dataIndex: 'remarks' }
    ];

    const pushColumns = [
      { title: '提现时间', dataIndex: 'create_time' },
      { title: '提现账号', dataIndex: 'username' },
      { title: '提现积分', dataIndex: 'get_money' },
      { title: '提现状态', dataIndex: 'status' }
    ];

    const inviteList = async () => {
      try {
        const res = await getInviteList()
        if (res.data.code !== 20000) {
          Message.warning(res.data.msg)
        } else {
          inviteDetails.value = res.data.data.list
        }
      } catch (error) {
        Message.error(error)
      }
    }

    const pushLog = async () => {
      try {
        const res = await getPushLog()
        if (res.data.code !== 20000) {
          Message.warning(res.data.msg)
        } else {
          pushData.value = res.data.data.list
        }
      } catch (error) {
        Message.error(error)
      }
    }
    pushLog();
    inviteList();

    const topColumns = ref([])
    const inviteTopDetails = ref([])

    const currentPage = ref(1);
    const pageSize = ref(10);
    const total = ref(50); // 总条数

    const copyInviteLink = () => {
      navigator.clipboard.writeText(inviteLink)
        .then(() => {
          Message.success('链接已复制到剪贴板');
        })
        .catch(() => {
          Message.error('复制失败，请手动复制链接');
        });
    };

    const handlePageChange = (newPage) => {
      currentPage.value = newPage;
    };

    const handlePageSizeChange = (newSize) => {
      pageSize.value = newSize;
    };

    const paymentPerson = reactive({
      invateCode: '',
      inviteNumber: '',
      score: ''
    });

    const getUserData = async () => {
      try {
        const userInfo = await getUserInfo();
        paymentPerson.invateCode = userInfo.data.invate_code;
        paymentPerson.inviteNumber = userInfo.data.invite_number;
        paymentPerson.score = userInfo.data.score
        console.log(paymentPerson.score)

        stats.value[0].value = paymentPerson.inviteNumber.toString();
        stats.value[1].value = userInfo.data.commission_per.toString();
        stats.value[2].value = userInfo.data.commission_all.toString();
        stats.value[3].value = userInfo.data.commission_push.toString();
        stats.value[4].value = userInfo.data.commission.toString();
        console.log(paymentPerson.inviteNumber)
      } catch (error) {
        console.error('获取用户信息失败:', error);
      }
    };

    getUserData();

    return {
      inviteLink,
      stats,
      columns,
      currentPage,
      pageSize,
      total,
      topColumns,
      paymentPerson,
      inviteTopDetails,
      getUserData,
      alipayRecharge,
      pushColumns,
      pushData,
      copyInviteLink,
      handlePageChange,
      handlePageSizeChange,
      pushLog,
      inviteList,
      inviteDetails
    };
  }
};
</script>

<style>
.bg-\[\#fd802e\] {
    --tw-bg-opacity: 1;
    background-color: rgb(253 128 46 / var(--tw-bg-opacity));
}
.contentInfo{
  text-align: left;
}
.bg-\[\#fefaf4\] {
    --tw-bg-opacity: 1;
    background-color: rgb(254 250 244 / var(--tw-bg-opacity));
}
.bg-\[\#fff6f6\] {
    --tw-bg-opacity: 1;
    background-color: rgb(255 246 246 / var(--tw-bg-opacity));
}
.bg-\[\#f8f6fe\] {
    --tw-bg-opacity: 1;
    background-color: rgb(248 246 254 / var(--tw-bg-opacity));
}
.bg-\[\#f6fafe\] {
    --tw-bg-opacity: 1;
    background-color: rgb(246 250 254 / var(--tw-bg-opacity));
}
.bg-\[\#f5fef8\] {
    --tw-bg-opacity: 1;
    background-color: rgb(245 254 248 / var(--tw-bg-opacity));
}
.rounded-lg {
    border-radius: .5rem;
}
.justify-center {
    justify-content: center;
}
.items-center {
    align-items: center;
}
.flex-col {
    flex-direction: column;
}
.h-\[140px\] {
    height: 140px;
}
.flex {
    display: flex;
}
</style>
