<template>
  <div class="flex-1">
    <div class="mb-6">
      <div class="bg-white rounded-lg px-5 pb-5">
        <!-- Header -->
        <div class="flex justify-start items-center h-[68px] border-b-[1px] border-[#e5e5e5] capitalize">
          <div class="font-bold text-[#fe802d] text-2xl mr-4">礼品购买</div>
        </div>
        <div class="pt-5 pb-7">
          <a-radio-group v-model="orderMethod" class="tool-form-item">
            <div class="label">下单方式:</div>
            <a-radio :value="1">手动下单</a-radio>
            <a-radio :value="2">自动下单</a-radio>
          </a-radio-group>

          <div class="tool-form-item">
            <div class="label">电商平台:</div>
            <div class="content gap-10">
              <div v-for="(platform, index) in platforms" :key="index"
                :class="['line-button flex flex-row items-center justify-center w-fit', { 'active-button': selectedPlatform === platform.name }]"
                @click="selectedPlatform = platform.name">
                <img class="mr-3" :src="platform.icon" :alt="platform.name"> {{ platform.name }}
              </div>
            </div>
          </div>

          <div class="tool-form-item" style="align-items: start;">
            <div class="label">发货仓库:</div>
            <a-select v-model="selectedWarehouse" placeholder="请选择发货仓库" style="width: 320px;">
              <a-option v-for="warehouse in warehouses" :key="warehouse.store_id" :value="warehouse.store_id">
                {{ warehouse.name + ' ' + '您的运费' + warehouse.apiprice }}
              </a-option>
            </a-select>
            <div v-if="showWarehouseWarning"
              class="flex flex-row items-center w-[45px] py-2.5 px-2.5 rounded border border-[#fe802d] border-solid bg-[#fdf6e9] text-xs mt-2.5">
              <span>{默认提示信息}</span>
            </div>
          </div>

          <div class="tool-form-item" style="align-items: start;" v-if="selectedWarehouse">
            <div class="label">选择礼品:</div>
            <a-table :columns="columns" :data="tableData" style="width: 100%;">
              <template #goods_image="{ record }">
                <img :src="record.goods_image" alt="" style="width: 4rem; height: 4rem;" />
              </template>
              <template #buyCount="{ record }">
                <a-button @click="selectProducts(record.gift_id)">操作</a-button>
              </template>
              <template #number="{ record }">
                <!-- Bind the input to record.quantity and call updateTotal when it changes -->
                <a-input-number v-model="record.quantity" @change="updateTotal(record)" :min="1" />
              </template>
              <template #totalprice="{ record }">
                {{ calculateTotalPrice(record) }} 元
              </template>
            </a-table>
          </div>

          <div class="tool-form-item" style="align-items: flex-start;">
            <div class="label">收货地址:</div>
            <div class="flex flex-col w-3/5">
              <a-textarea v-model="address" placeholder="一行一个收货地址" style="height: 118px; resize: none;"></a-textarea>
              <div class="text-xs mt-2.5 p-2.5 rounded border border-[#fe802d] bg-[#fdf6e9]">
                <div class="flex items-start">
                  <a-icon type="exclamation-circle-fill" class="mr-2" style="color: #fe802d;"></a-icon>
                  <div class="flex flex-col leading-5">
                    <p>格式：姓名，手机，地址（例如：张三，13888888888，浙江省 杭州市 下城区 文新路888号）</p>
                    <p class="text-[#fe802d]">注意：礼品是真实发货，请核对好地址，出单号无法取消退款</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="tool-form-item">
            <div class="flex" style="margin-left: 120px;">
              <a-space>
                <a-button type="primary" class="px-8 py-2 rounded-lg text-sm"
                  style="background-color: #99C1C8;border-radius:5px;width: 100px;height:40px">识别</a-button>
                <a-button type="warning" class="px-8 py-2 rounded-lg text-sm ml-2"
                  style="background-color: darkorange;color: white;width: 150px;height:40px;border-radius:5px;"
                  @click="sendGiftData">确认提交</a-button>
              </a-space>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import { getGiftCate, sendGift } from '@api';

export default {
  setup () {
    const orderMethod = ref(1);
    const selectedPlatform = ref('淘宝');
    const selectedWarehouse = ref('');
    const address = ref('');
    const selectedGiftId = ref(null);

    const platforms = ref([
      { name: '淘宝', icon: '/giftpurchse_files/4gmlp-icon1.png' },
      { name: '京东', icon: '/giftpurchse_files/4gmlp-icon4.png' },
      { name: '拼多多', icon: '/giftpurchse_files/4gmlp-icon3.png' },
      { name: '抖音', icon: '/giftpurchse_files/4gmlp-icon6.png' },
      { name: '快手', icon: '/giftpurchse_files/4gmlp-icon7.png' }
    ]);

    const warehouses = ref([]);
    const columns = ref([
      { title: 'ID', dataIndex: 'gift_id' },
      { title: '商品名称', dataIndex: 'name' },
      { title: '商品重量', dataIndex: 'weight' },
      { title: '商品价格', dataIndex: 'apiprice' },
      { title: '商品图片', dataIndex: 'goods_image', slotName: 'goods_image' },
      { title: '操作', dataIndex: 'buyCount', slotName: 'buyCount' }
    ]);
    const tableData = ref([]);

    const getCate = async () => {
      try {
        const cateRes = await getGiftCate();
        warehouses.value = cateRes.data.data;
        updateTableData();
      } catch (error) {
        console.error(error);
      }
    };

    const updateTableData = () => {
      if (selectedWarehouse.value) {
        const selected = warehouses.value.find(warehouse => warehouse.store_id === selectedWarehouse.value);
        tableData.value = selected ? selected.gift : [];
      } else {
        tableData.value = [];
      }
    };

    const calculateTotalPrice = (record) => {
      const price = parseFloat(record?.apiprice || 0);
      const expressFee = parseFloat(record?.expree_fre || 0);
      const quantity = record?.quantity || 1;
      return ((price + expressFee) * quantity).toFixed(2);
    };

    const updateTotal = (record) => {
      record.totalPrice = calculateTotalPrice(record);
    };

    const selectProducts = (giftId) => {
      selectedGiftId.value = giftId;
      const selectedProduct = tableData.value.find(item => item.gift_id === giftId);
      tableData.value = selectedProduct ? [selectedProduct] : [];
      columns.value = [
        {
          title: '商品',
          dataIndex: 'name',
          align: 'center'
        },
        {
          title: '商品图片',
          dataIndex: 'goods_image',
          slotName: 'goods_image',
          width: '200'
        },
        {
          title: '数量',
          dataIndex: 'number',
          slotName: 'number',
          width: '200',
          align: 'center'
        },
        {
          title: '运费',
          dataIndex: 'expree_fre',
          slotName: 'expree_fre',
          width: '200',
          align: 'center'
        },
        {
          title: '价格',
          dataIndex: 'apiprice',
          align: 'center'
        },
        {
          title: '商品总价',
          align: 'center',
          slotName: 'totalprice'
        }
      ];
    };

    const sendGiftData = async () => {
      try {
        const addressArray = address.value.split('\n').map(line => line.trim()).filter(line => line);
        const sendRes = await sendGift({
          store_id: selectedWarehouse.value,
          gift_id: selectedGiftId.value,
          address: addressArray
        });
        console.log(sendRes.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    watch(selectedWarehouse, updateTableData);
    getCate();

    return {
      orderMethod,
      selectedPlatform,
      selectedWarehouse,
      address,
      selectedGiftId,
      platforms,
      warehouses,
      columns,
      tableData,
      calculateTotalPrice, // Ensure this is returned
      updateTotal,
      sendGiftData,
      selectProducts
    };
  }
}
</script>

<style scoped>
.active-button {
  color: #ebb402;
  border: 1px solid#ebb402;
}

.line-button {
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  height: 50px;
  line-height: 50px;
  min-width: 100px;
  padding: 0 20px;
  text-align: center;
  transition: border-color .3s ease-in-out;
}

.tool-form-item:not(:last-child) {
  margin-bottom: 20px;
}

.tool-form-item {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}

.tool-form {
  display: flex;
  flex-direction: column;
}

.tool-form-item .gap-10 {
  gap: 10px;
}

.tool-form-item .content {
  align-items: center;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.mr-3 {
  margin-right: .75px;
}

img {
  border-style: none;
}

img,
video {
  height: auto;
  max-width: 100%;
}

.text-\[\#fe802d\] {
  --tw-text-opacity: 1;
  color: rgb(254 128 45 / var(--tw-text-opacity));
}

.font-bold {
  font-weight: 700;
}

.mr-4 {
  margin-right: 1px;
}
</style>
