<template>
  <div class="flex-1">
    <div class="mb-6">
      <div class="bg-white rounded-lg px-5 pb-5">
        <div class="flex justify-start items-center h-[68px] border-b-[1px] border-[#e5e5e5] capitalize"
          style="padding: 10px;">
          <div class="flex-auto flex justify-start items-center">
            <div></div>
            <div class="font-bold text-[#fe802d] text-2xl mr-4" style="color: darkorange;">流量入口</div>
            <div class="flex-1">
              <div class="tool-header-content">
                <p>优惠套餐：购买套餐可低至 0.5 积分/次</p>
              </div>
            </div>
          </div>
          <div>
            <div class="tool-header-right text-base text-[#333]">
              <div class="flex justify-start items-center mr-3">
                <img class="w-6 h-6 mr-2" src="/zhaoyaojing_files/biaoti-icon1.png" alt="" />
                <span>单次：0积分</span>
              </div>
              <div class="flex justify-start items-center">
                <img class="w-6 h-6 mr-2" src="/zhaoyaojing_files/biaoti-icon2.png" alt="" />
                <span>会员当日次数：0/0次</span>
              </div>
            </div>
          </div>
        </div>
        <div class="pt-5 pb-7 flex justify-between items-start">
          <div class="left-side flex-1">
            <el-form label-position="right" class="mt-5">
              <el-form-item class="asterisk-left border-[#efecec]" label="场景" style="padding-left: 27px;width:1200px;">
                <template v-for="(scene, index) in scenes" :key="index">
                  <el-button :type="scene.isActive ? 'primary' : ''" @click="selectScene(index)" style="margin:5px 5px 5px 5px;border: 1px solid #DE6E22;padding: 15px 15px 15px 15px ;" >
                    {{ scene.name }}
                  </el-button>
                </template>
              </el-form-item>

              <template v-if="scenes.length > 0 && currentSceneIndex !== null">
                <template v-for="(inputField, inputIndex) in scenes[currentSceneIndex].json"
                  :key="`field-${inputIndex}`">
                  <el-form-item :label="inputField.name" :required="inputField.required"
                    class="is-required asterisk-left flex items-center w-[60%] mx-auto" style="margin-left:10px" >
                    <template v-if="inputField.value && typeof inputField.value === 'object'">
                      <el-select v-model="inputField.value" placeholder="请选择">
                        <el-option v-for="(option, key) in inputField.value" :key="key" :label="option"
                          :value="key"></el-option>
                      </el-select>
                    </template>
                    <template v-else>
                      <el-input v-model="inputField.value" :placeholder="`请输入${inputField.name}`" style="height:40px"></el-input>
                    </template>
                  </el-form-item>
                </template>
              </template>

              <el-form-item class="asterisk-left flex items-center w-[60%] mx-auto">
                <p class="text-sm text-[#e63400]">{{ errorMessage }}</p>
              </el-form-item>
              <el-form-item class="asterisk-left">
                <div class="w-full flex justify-center items-center">
                  <el-button type="primary" class="w-[200px]" @click="generateQRCode">生成二维码</el-button>
                </div>
              </el-form-item>
            </el-form>
          </div>
          <div class="right-side flex justify-center items-center flex-col" style="margin-top: 20px;">
            <div class="qrcode w-[260px] h-[260px]" style="width: 260px;height: 260px;">
              <div v-if="qrCodeData && qrCodeData.img ">
                <img :src="qrCodeData.img" style="width: 240px;height: 240px;" />
                <span style="color: #fe802d;padding-top: 5px;">打开淘宝App 扫一扫</span>
              </div>
              <div v-else>二维码将显示在这里</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mb-6">
      <div class="bg-white rounded-lg px-5 pb-5">
        <div class="flex justify-start items-center h-[68px] border-[#e5e5e5] capitalize border-none">
          <div class="flex-auto flex justify-start items-center">
            <div>
              <div class="flex justify-start items-center"><img src="../../assets/img/zyj-icon1-jilu.png" alt=""><span
                  class="text-xl text-[#333] font-bold p-2">生成记录</span></div>
            </div>
            <div class="font-bold text-[#fe802d] text-2xl mr-4"></div>
            <div class="flex-1"></div>
          </div>
          <div></div>
        </div>
        <a-table :columns="columns" :data="tableData" row-key="id" pagination="false" stripe>
          <template #QRcode="{ record }">
            <showQrCode :url="record.image"></showQrCode>
          </template>
        </a-table>
        <el-pagination :total="total" v-model:current-page="currentPage" :page-size="pageSize"
          @size-change="handlePageSizeChange" @current-change="handlePageChange"></el-pagination>
      </div>
    </div>

    <div class="mb-6">
      <div class="bg-white rounded-lg px-5 pb-5" style="text-align: left;">
        <el-tabs v-model="activeTab" class="mt-4 tip-text">
          <el-tab-pane label="功能介绍">
            <p>1.用户扫描二维码后，会在生意参谋中，显示该访客是通过淘内免费来源访问你的商品的，商品也会在手淘首页商品列表靠前的位置</p>
            <p>2.通过这样卡位的方式将商品卡在手淘首页的商品列表中，提高商品的搜索路径的权重值</p>
          </el-tab-pane>
          <el-tab-pane label="使用场景">
            <p>1.先输入要商品链接或ID，点开始生成即可生成首屏二维码</p>
            <p>2.打开手机淘宝扫描二维码跳转方式把宝贝卡在首页</p>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, nextTick, reactive } from 'vue';
import { getFlowEntranceCate, sendFlowEntrance, getFlowEntranceList } from '@api';
import { Message } from '@arco-design/web-vue';
import showQrCode from '../order/component/showQrCode.vue';
import QRCode from 'qrcode';

export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },
  components: {
    showQrCode
  },
  setup (props) {
    watch(() => props.id, (newId) => {
      const index = scenes.value.findIndex(scene => scene.type === newId);
      if (index !== -1) {
        selectScene(index);
      }
    });
    const scenes = ref([]);
    const currentSceneIndex = ref(null);
    const columns = ref([
      { title: '生成时间', dataIndex: 'create_time', align: 'center' },
      { title: '类型', dataIndex: 'type', align: 'center' },
      { title: '消耗积分', dataIndex: 'cost', align: 'center' },
      { title: '操作', dataIndex: 'image', slotName: 'QRcode', align: 'center' }
    ]);
    const tableData = ref([]);
    const errorMessage = ref('');
    const qrCodeData = reactive({
      img: ''
    });

    const getTYpe = async () => {
      try {
        const typeRes = await getFlowEntranceCate();
        scenes.value = typeRes.data.data;
        if (scenes.value.length > 0) {
          selectScene(0); // 默认选择第一个场景
        }
      } catch (error) {
        console.error(error);
      }
    };

    getTYpe();

    const selectScene = async (index) => {
      scenes.value.forEach((scene, idx) => {
        scene.isActive = idx === index;
      });
      currentSceneIndex.value = index;
      await getFlowList();
    };

    const generateQRCode = async () => {
      if (currentSceneIndex.value === null) {
        errorMessage.value = '请选择一个场景';
        return;
      }
      const currentScene = scenes.value[currentSceneIndex.value];
      const params = { type: currentScene.type };

      for (const inputField of currentScene.json) {
        if (inputField.required && !inputField.value) {
          errorMessage.value = `请输入${inputField.name}`;
          break;
        }
        params[inputField.field] = inputField.value;
      }
      try {
        // 检查 API 返回的数据
        console.log('发送请求参数:', params);
        const tbRes = await sendFlowEntrance(params);
        console.log('API 响应:', tbRes);

        const canvas = document.createElement('canvas');
        await QRCode.toCanvas(canvas, tbRes.data.data, { errorCorrectionLevel: 'H' });

        qrCodeData.img = canvas.toDataURL();
        console.log('生成的二维码:', qrCodeData.img);

        Message.success(tbRes.data.msg);
        await getFlowList(tbRes.data);
      } catch (error) {
        errorMessage.value = error.message || '生成二维码失败';
      }
    };

    const getFlowList = async (qrData) => {
      if (currentSceneIndex.value === null) return;
      try {
        const flowType = scenes.value[currentSceneIndex.value].type;
        const flowRes = await getFlowEntranceList({ type: flowType, data: qrData });
        tableData.value = flowRes.data.data.list;
        await nextTick(); // 等待 DOM 更新
      } catch (error) {
        console.log(error);
      }
    };

    return {
      scenes,
      errorMessage,
      qrCodeData,
      columns,
      tableData,
      currentSceneIndex,
      selectScene,
      generateQRCode,
      getFlowList
    };
  }
}
</script>

<style scoped>
.tool-header-content {
  background-image: url(/public/zhaoyaojing_files/coupon-bg.png);
  color: var(--bg-color);
  font-size: 14px;
  font-weight: 400;
  height: 20px;
  line-height: 20px;
  text-align: center;
  width: 260px;
}
</style>
