<template>
  <div class="flex-1">
    <div class="mb-6">
      <div class="bg-white rounded-lg p-5">
        <div class="bg-white rounded-lg p-5">
          <a-row type="flex" align="middle" justify="start" style="border-bottom: 1px solid #C3C3C3;">
            <a-col class="flex justify-start items-center" :span="24">
              <strong class="text-3xl text-[#333333]">{{ userInfo.mobile }}</strong>
              <ul class="contact-information">
                <li v-for="(item, index) in contactInfo" :key="index">
                  <img :src="`/acount_files/${item.imgSrc}`" :alt="item.alt" style="padding-left: 5px;">
                </li>
              </ul>
              <span style="padding-left: 10px;">
                <a-button shape="round" size="medium" @click="goToShopTools(5)" type="outline" status="warning">
                  <img src="../../../public/acount_files/15grzx-icon5.png" alt="消息通知" /> 消息通知
                </a-button>
              </span>
              <span style="padding-left: 10px;">
                <resetPwd />
              </span>
            </a-col>
            <div class="text-base text-[#999999] pt-4 pb-6 border-b border-[#e5e5e5]">
              上次登录时间：{{ userInfo.logintime }} 登录IP：{{ userInfo.loginip }}( {{ userInfo.ipLocation }} )
            </div>
          </a-row>
          <a-row type="flex" justify="space-between" align="middle" class="my-5" style="min-width: 800px;">
            <a-col :flex="1" class="border-r border-[#e5e5e5]">
              <a-row type="flex" justify="space-between" align="middle" class="pt-2 pb-5"
                style="border-bottom: 1px solid #C7C7C7">
                <div class="info-container">
                  <img class="icon" src="../../../public/acount_files/15grzx-icon7.png" alt="">
                  <span style="font-size: large;margin-top: -25px;margin-left: 33px">账户积分</span>
                  <strong class="text-3xl text-[#333333]">{{ userInfo.score }}</strong>
                </div>
                <recharge :avatar="userInfo.avatar" :score="userInfo.score"></recharge>
              </a-row>
              <a-row type="flex" justify="space-between" align="middle" class="pb-2 pt-5">
                <div class="info-container">
                  <img class="icon" src="../../../public/acount_files/15grzx-icon8.png" alt="">
                  <span style="font-size: large;margin-top: -25px;margin-left: 33px;">API密钥</span>
                  <strong class="text-3xl text-[#333333]">{{ maskedClientSecret }}</strong>
                </div>
                <apikey></apikey>
              </a-row>
            </a-col>
            <a-col :flex="1" class="flex justify-start items-center">
              <img class="customer-image" src="/index_files/89f559b1-b467-45e0-a44e-a08dae8d2a1a.JPG">
              <div>
                <span class="text-base text-[#666666]" style="margin-left: -60px;">专属微信客服</span>
                <div class="text-xl text-[#333333] mt-2">微信号：{{ wechatCustomerService.id }}</div>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="mb-6">
      <div class="bg-white rounded-lg p-5">
        <div class="member-content">
          <div class="flex-1" style="text-align:left; ">
            <div class="text-3xl text-[#333] font-bold" style="padding-bottom: 26px;">工具会员(赠送天数)</div>
            <p class="text-base text-[#333]" style="padding-bottom: 26px;">到期时间：{{ userInfo.member_end_time }}</p>
            <a-button class="!bg-[#ffc45e] !text-white"
              style="background-color: #ffc45e; color:white;width:70px;border-radius: 10px;">去使用</a-button>
          </div>
          <div class="flex-1" style="text-align:left; ">
            <div class="text-3xl text-[#333] font-bold" style="padding-bottom: 26px;">插件会员</div>
            <p class="text-base text-[#333]" style="padding-bottom: 26px;">暂未开通插件会员</p>
            <a-button class=" \!bg-[#70adea] !text-white" type="button"
              style="background-color: #70adea; color:white;width:70px;border-radius: 10px;">订购</a-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div>

    <div>
      <div class="mb-6">
        <div class="bg-white rounded-lg p-5">
          <div class="flex justify-start items-center h-[68px] border-[#e5e5e5] capitalize border-none">
            <div class="flex-auto flex justify-start items-center">
              <div>
                <div class="flex justify-start items-center"><img src="../../assets/img/zyj-icon1-jilu.png" alt=""><span
                    class="text-xl text-[#333] font-bold p-2">财务报表</span></div>
              </div>
              <div class="flex-1"></div>
            </div>
          </div>
          <a-tabs>
            <a-tab-pane title="消费明细" key="1">
              <a-table :columns="columns" :data="payData"></a-table>
            </a-tab-pane>
            <a-tab-pane title="充值明细" key="2">
              <a-table :columns="czColumns" :data="czData"></a-table>
            </a-tab-pane>
            <a-tab-pane title="会员明细" key="3">
              <a-table :columns="vipColumns" :data="vipData"></a-table>
            </a-tab-pane>
            <a-tab-pane title="邀请明细" key="4">
              <a-table :columns="inviteColumns" :data="inviteData"></a-table>
            </a-tab-pane>
          </a-tabs>
        </div>
      </div>
    </div>

    <div class="mb-6">
      <div class="bg-white rounded-lg p-5">
        <div class="flex justify-start items-center h-[68px] border-b border-[#e5e5e5] capitalize">
          <div class="flex-auto flex justify-start items-center">
            <div class="flex justify-start items-center">
              <div class="bg-[#ff7742] w-1 h-[26px] rounded-sm mr-2"></div>
              <span class="text-xl text-[#333] font-bold">工具会员工具</span>
            </div>
          </div>
        </div>
        <a-tabs class="demo-tabs">
          <a-tab-pane title="照妖镜" key="1">
            <ul class="flex justify-start flex-wrap">
              <li class="tool-item" v-for="(tool, index) in toolsPane1" :key="index" style="width: calc(16.66% - 30px); margin-left: 15px; margin-bottom: 20px;">
                <div class="tool-image">
                  <img :src="tool.imgSrc" :alt="tool.name">
                </div>
                <div class="ml-3 flex-1">
                  <p class="text-[#333333] text-sm">{{ tool.name }}</p>
                  <strong class="text-[#333333] text-xl font-bold">{{ tool.usage }}/{{ tool.limit }}</strong>
                </div>
              </li>
            </ul>
          </a-tab-pane>
          <a-tab-pane title="黑科技工具" key="2">
            <ul class="flex justify-start flex-wrap">
              <li class="tool-item" v-for="(tool, index) in toolsPane2" :key="index" style="width: calc(16.66% - 30px); margin-left: 15px; margin-bottom: 20px;">
                <div class="tool-image" style="width:90px">
                  <img :src="tool.imgSrc" :alt="tool.name">
                </div>
                <div class="ml-3 flex-1" style="width:90px">
                  <p class="text-[#333333] text-sm">{{ tool.name }}</p>
                  <strong class="text-[#333333] text-xl font-bold">{{ tool.usage }}/{{ tool.limit }}</strong>
                </div>
              </li>
            </ul>
          </a-tab-pane>
          <a-tab-pane title="运营工具" key="3">
            <ul class="flex flex-wrap justify-start">
              <li class="tool-item flex items-center" v-for="(tool, index) in toolsPane3" :key="index"
                style="width: calc(16.66% - 30px); margin-left: 15px; margin-bottom: 20px;">
                <div class="tool-image">
                  <img :src="tool.imgSrc" :alt="tool.name" class="w-16 h-16"> <!-- 调整图片大小 -->
                </div>
                <div class="ml-3 flex-1">
                  <p class="text-[#333333] text-sm">{{ tool.name }}</p>
                  <strong class="text-[#333333] text-xl font-bold">{{ tool.usage }}/{{ tool.limit }}</strong>
                </div>
              </li>
            </ul>
          </a-tab-pane>
          <a-tab-pane title="查询工具" key="4">
            <ul class="flex justify-start flex-wrap">
              <li class="tool-item" v-for="(tool, index) in toolsPane4" :key="index" style="width: calc(16.66% - 30px); margin-left: 15px; margin-bottom: 20px;">
                <div class="tool-image">
                  <img :src="tool.imgSrc" :alt="tool.name">
                </div>
                <div class="ml-3 flex-1">
                  <p class="text-[#333333] text-sm">{{ tool.name }}</p>
                  <strong class="text-[#333333] text-xl font-bold">{{ tool.usage }}/{{ tool.limit }}</strong>
                </div>
              </li>
            </ul>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, reactive, onMounted } from 'vue'
import { getUserInfo, getConsumeList, getPayList, getMemberList, getInviteList } from '@api'
import apikey from './component/apikey.vue'
import recharge from './component/recharge.vue'
import resetPwd from './component/changePassword.vue'
import { useRouter } from 'vue-router';

export default {
  components: {
    apikey,
    recharge,
    resetPwd
  },
  computed: {
    maskedClientSecret () {
      const secret = this.userInfo.client_secret;
      if (secret && secret.length > 4) {
        return `${secret.charAt(0)}*****${secret.charAt(secret.length - 1)}`;
      }
      return secret; // Return as is if it's too short
    }
  },
  setup () {
    const userInfo = reactive({
    });

    const goToShopTools = (key) => {
      router.push({ name: 'shopTools', params: { key } });
    };

    const router = useRouter();

    const apiInfo = reactive({ key: 'AE6********65' });
    const wechatCustomerService = reactive({ id: 'vv123321' });
    const contactInfo = ref([
      { imgSrc: '15grzx-icon1.png', alt: '姓名' },
      { imgSrc: '15grzx-icon2-1.png', alt: '手机' },
      { imgSrc: '15grzx-icon3.png', alt: '邮件' },
      { imgSrc: '15grzx-icon4.png', alt: '微信' }
    ]);

    const loading = ref(true);
    const toolsPane1 = ref([
      { name: '防P图验号', usage: 0, limit: 0, imgSrc: './acount_files/15grzx-icon12.png' },
      { name: '照妖镜验号', usage: 0, limit: 1, imgSrc: './acount_files/15grzx-icon13.png' }
    ]);

    const toolsPane2 = ref([
      { name: '标签推送', usage: 0, limit: 0, imgSrc: './acount_files/15grzx-icon12.png' },
      { name: '抖音卡首屏', usage: 0, limit: 1, imgSrc: './acount_files/15grzx-icon13.png' },
      { name: '流量入口', usage: 0, limit: 1, imgSrc: './acount_files/15grzx-icon14.png' },
      { name: '拼多多打标', usage: 0, limit: 1, imgSrc: './acount_files/15grzx-icon15.png' },
      { name: '拼多多卡首屏', usage: 0, limit: 1, imgSrc: './acount_files/15grzx-icon16.png' },
      { name: '拼多多无痕秒单', usage: 0, limit: 1, imgSrc: './acount_files/15grzx-icon16.png' },
      { name: '淘宝1688卡首屏', usage: 0, limit: 1, imgSrc: './acount_files/15grzx-icon15.png' },
      { name: '淘宝首屏', usage: 0, limit: 1, imgSrc: './acount_files/15grzx-icon14.png' },
      { name: '淘宝无痕秒单', usage: 0, limit: 1, imgSrc: './acount_files/15grzx-icon14.png' },
      { name: '旺旺打标', usage: 0, limit: 0, imgSrc: './acount_files/15grzx-icon12.png' }
    ]);

    const toolsPane3 = ref([
      { name: '1688商品详情下载', usage: 0, limit: 1, imgSrc: './acount_files/15grzx-icon12.png' },
      { name: '阿里商品详情下载', usage: 0, limit: 1, imgSrc: './acount_files/15grzx-icon13.png' },
      { name: '车图下载', usage: 0, limit: 1, imgSrc: './acount_files/15grzx-icon14.png' },
      { name: 'DSR智能计算器', usage: 0, limit: 1, imgSrc: './acount_files/15grzx-icon15.png' },
      { name: '抖音商品详情下载', usage: 0, limit: 1, imgSrc: './acount_files/15grzx-icon16.png' },
      { name: '京东商品详情下载', usage: 0, limit: 1, imgSrc: './acount_files/15grzx-icon16.png' },
      { name: '拼多多商品详情下载', usage: 0, limit: 1, imgSrc: './acount_files/15grzx-icon15.png' },
      { name: '潜力词', usage: 0, limit: 1, imgSrc: './acount_files/15grzx-icon14.png' },
      { name: '商品排名', usage: 0, limit: 1, imgSrc: './acount_files/15grzx-icon13.png' },
      { name: '淘宝商品详情下载', usage: 0, limit: 1, imgSrc: './acount_files/15grzx-icon12.png' },
      { name: '淘口令解析', usage: 0, limit: 1, imgSrc: './acount_files/15grzx-icon12.png' },
      { name: '淘口令生成', usage: 0, limit: 1, imgSrc: './acount_files/15grzx-icon13.png' },
      { name: 'TOP 20w', usage: 0, limit: 1, imgSrc: './acount_files/15grzx-icon14.png' },
      { name: '下拉推荐词', usage: 0, limit: 1, imgSrc: './acount_files/15grzx-icon15.png' },
      { name: '指数转换', usage: 0, limit: 1, imgSrc: './acount_files/15grzx-icon16.png' }
    ]);
    const toolsPane4 = ref([
      { name: '飙升词分析', usage: 0, limit: 0, imgSrc: './acount_files/15grzx-icon12.png' },
      { name: '标题分析', usage: 0, limit: 0, imgSrc: './acount_files/15grzx-icon13.png' },
      { name: '关键词分析', usage: 0, limit: 0, imgSrc: './acount_files/15grzx-icon14.png' },
      { name: '蓝海词挖批', usage: 0, limit: 0, imgSrc: './acount_files/15grzx-icon15.png' },
      { name: '手淘每日热榜', usage: 0, limit: 0, imgSrc: './acount_files/15grzx-icon16.png' },
      { name: '淘词分析', usage: 0, limit: 0, imgSrc: './acount_files/15grzx-icon16.png' }
    ]);

    const payData = ref([]);
    const czData = ref([]);
    const vipData = ref([]);
    const inviteData = ref([])

    const columns = ref([
      { dataIndex: 'create_time', title: '消费时间' },
      { dataIndex: 'order_number', title: '订单编号' },
      { dataIndex: 'project', title: '消费项目' },
      { dataIndex: 'cost', title: '消费积分' },
      { dataIndex: 'after', title: '积分余额' },
      { dataIndex: 'remark', title: '备注' }
    ]);

    const czColumns = ref([
      { dataIndex: 'create_time', title: '充值时间' },
      { dataIndex: 'account', title: '充值账号' },
      { dataIndex: 'pay_money', title: '充值金额' },
      { dataIndex: 'pay_integral', title: '兑换积分' },
      { dataIndex: 'remark', title: '备注' }
    ]);

    const vipColumns = ref([
      { dataIndex: 'create_time', title: '充值时间' },
      { dataIndex: 'order_number', title: '订单编号' },
      { dataIndex: 'mode', title: '充值方式' },
      { dataIndex: 'type', title: '会员类型' },
      { dataIndex: 'day', title: '时长' },
      { dataIndex: 'integral', title: '消费积分' },
      { dataIndex: 'remark', title: '备注' }
    ]);

    const inviteColumns = ref([
      { dataIndex: 'create_time', title: '创建时间' },
      { dataIndex: 'order_type', title: '订单类型' },
      { dataIndex: 'money', title: '交易金额' },
      { dataIndex: 'return_money', title: '返佣金额' },
      { dataIndex: 'status', title: '结算状态' },
      { dataIndex: 'remark', title: '备注' }
    ]);

    const fetchData = async () => {
      try {
        const userRes = await getUserInfo();
        Object.assign(userInfo, userRes.data);
      } catch (error) {
        console.log(error);
      }
    };

    const getTableList = async () => {
      try {
        const payRes = await getConsumeList();
        const czRes = await getPayList();
        const vipRes = await getMemberList();
        const inviteRes = await getInviteList();
        payData.value = payRes.data.data.list;
        czData.value = czRes.data.data.list
        vipData.value = vipRes.data.data.list
        inviteData.value = inviteRes.data.data.list
      } catch (error) {
        console.log(error);
      }
    };

    onMounted(() => {
      fetchData();
      getTableList();
    });

    return {
      userInfo,
      toolsPane2,
      apiInfo,
      wechatCustomerService,
      toolsPane4,
      inviteColumns,
      inviteData,
      vipColumns,
      czColumns,
      czData,
      contactInfo,
      toolsPane3,
      vipData,
      loading,
      columns,
      payData,
      toolsPane1,
      goToShopTools
    };
  }
}
</script>

<style scoped>
.info-container {
    display: flex;
    flex-direction: column;
}

.info-container img {
    align-self: flex-start;
}

.info-container span {
    align-self: flex-start;
}

.info-container strong {
    margin-top: 5px;
}
.flex-1 {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.bg-white {
  background-color: white;
}

.text-[#666666] {
  color: #666666;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.contact-information {
  display: flex;
  justify-content: flex-start;
  padding-left: 20px;
}

.member-content {
  display: flex;
  justify-content: space-between;
}

.icon {
  width: 22px;
  height: 22px;
  margin-right: 0.5rem;
}

.customer-image {
  width: 148px;
  height: 148px;
  margin: 0 1rem;
}

.tool-item {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 0.5rem;
}

.tool-image {
  width: 60px;
  height: 60px;
}

img {
  max-width: 100%;
  height: auto;
}

.member-content>div:first-child {
    background-image: url('../../../public/acount_files/15grzx-icon9.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-radius: 12px;
    margin: 15px 8px 15px 0;
    padding: 50px;
}

.member-content>div:last-child {
    background-image: url(../../../public/acount_files/15grzx-icon10.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-radius: 12px;
    margin: 15px 0 15px 8px;
    padding: 50px;
}
</style>
