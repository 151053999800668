<template>
  <div class="flex-1">
    <div class="mb-6">
      <div class="bg-white rounded-lg px-5 pb-5">
        <div class="flex justify-start items-center h-[68px] border-[#e5e5e5] border-b-[1px] capitalize">
          <div class="flex-auto flex justify-start items-center">
            <div class="font-bold text-[#fe802d] text-2xl mr-4" style="color: darkorange;">反馈中心</div>
          </div>
        </div>
        <div class="pt-5 pb-7">
          <div style="text-align:left;padding-top:10px">
            <p class="text-[#333333] text-bold">您对本平台有什么意见或建议*</p>
            <p class="text-[#999999] pt-2" style="color: #c3c3c3">
              您可以反馈我们产品的使用问题，或者提供新的优化建议，如果是不在我们计划内的建议，被采纳后可以终身免费使用我们的工具!更重要的优化方案可直接红包答谢!
            </p>
          </div>
          <div class="my-5" style="margin-left: -19rem; padding-top:10px">
            <a-form>
              <a-form-item label="意见类型" required style="padding-top:10px">
                <a-radio-group v-model="feedbacktype" type="button" class="transparent-background">
                  <a-radio v-for="type in feedbackTypes" :key="type.value" :value="type.value">
                    {{ type.text }}
                  </a-radio>
                </a-radio-group>
              </a-form-item>
              <a-form-item label="反馈内容" required style="padding-top:10px">
                <a-textarea
                  style="width:80%;height: 150px;background-color: white;border-radius: 10px;border-color: lightgrey;"
                  v-model="feedback.content" rows="4" placeholder="请输入您想要反馈内容" />
              </a-form-item>
              <a-form-item label="反馈图片" style="padding-top:10px">
                <a-tooltip content="最多选择三张图片" position="left" style="left: 14rem; top: 290px;">
                  <a-upload list-type="picture-card" action="/" :limit="1" :file-list="fileList" v-model="feedback.images"
                    :custom-request="customRequest" @change="handleImagesChange" image-preview>
                  </a-upload>
                </a-tooltip>
              </a-form-item>
              <a-form-item style="padding-top:10px">
                <a-button type="primary" html-type="submit"
                  style="width: 200px;height: 40px;background-color: darkorange;border-radius: 5px;"
                  @click="submitFeedback">
                  提交反馈
                </a-button>
              </a-form-item>
            </a-form>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-5">
      <div class="bg-white rounded-lg px-5 pb-5">
        <div class="flex justify-start items-center h-[68px] border-b-[1px] border-[#e5e5e5] capitalize"
          style="padding: 10px;">
          <div class="flex justify-start items-center">
            <img src="../../../public/zyj-icon1-jilu.png" alt="" />
            <span class="text-xl text-[#333] font-bold p-2">反馈记录</span>
          </div>
        </div>
        <a-table :columns="columns" :data="tableData">
          <template #type="{ record }">
            <span>
              {{ feedbackTypes.find(type => type.value === record.type)?.text || record.type }}
            </span>
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { addFeedBack, getFeedList, uploadFiles } from '@/api/api';
import { Message } from '@arco-design/web-vue';

export default {
  setup () {
    const feedback = ref({
      type: '',
      content: '',
      images: [] // 确保初始化为数组
    });

    const feedbacktype = ref(1);

    const columns = ref([
      { title: 'id', dataIndex: 'id' },
      { title: '反馈时间', dataIndex: 'create_time' },
      { title: '反馈类型', dataIndex: 'type', slotName: 'type' },
      { title: '反馈内容', dataIndex: 'content' },
      { title: '回复', dataIndex: 'reply_content' },
      { title: '回复时间', dataIndex: 'reply_time' }
    ]);
    const tableData = ref([]);

    const feedbackTypes = ref([
      { value: '1', text: '优化建议' },
      { value: '2', text: '需求反馈' },
      { value: '3', text: '数据异常' },
      { value: '4', text: '体验不佳' }
    ]);

    const getTableList = async () => {
      try {
        const tableRes = await getFeedList({ current: '1', pageSize: '10' });
        tableData.value = tableRes.data.data.list;
      } catch (error) {
        console.log(error);
      }
    };

    const customRequest = async (options) => {
      const controller = new AbortController();
      const { onError, onSuccess, fileItem, name = 'file' } = options;
      const formData2 = new FormData();
      formData2.append(name, fileItem.file);

      try {
        const res = await uploadFiles(formData2, { controller });
        const imageUrl = res.data.data.fullurl;

        // 确保 images 是数组
        feedback.value.images = Array.isArray(imageUrl) ? imageUrl.map(url => ({ url })) : [{ url: imageUrl }];

        onSuccess(res);
      } catch (error) {
        onError(error);
      }
    };

    getTableList();

    const submitFeedback = async (event) => {
      event.preventDefault();
      try {
        console.log('Images before submit:', feedback.value.images);
        const res = await addFeedBack({
          type: feedbacktype.value,
          content: feedback.value.content,
          images: feedback.value.images.map(image => image.url) // 确保此时是数组
        });
        if (res.data.code === 20000) {
          Message.success('操作成功');
        } else {
          Message.warning(res.data.msg);
        }
        getTableList();
      } catch (error) {
        console.error('提交反馈失败:', error);
      }
    };

    const handleImagesChange = (info) => {
      if (info && info.fileList) {
        feedback.value.images = info.fileList.map(file => {
          if (file.response && file.response.url) {
            return {
              uid: file.uid,
              url: file.response.url
            };
          }
          return null;
        }).filter(item => item !== null);
      }
    };

    return {
      feedback,
      feedbacktype,
      feedbackTypes,
      tableData,
      columns,
      submitFeedback,
      getTableList,
      handleImagesChange,
      customRequest
    };
  }
};
</script>

<style scoped>
.arco-textarea-wrapper {
  width: 63%;
}
.arco-radio-button.arco-radio-checked {
  color: rgb(255, 151, 22) !important;
  background-color: rgb(var(--orange-1));
  border-color: rgb(255, 151, 22) !important;
}
.transparent-background {
  background-color: transparent !important;
}
.arco-textarea-wrapper {
  display: inline-flex;
  box-sizing: border-box;
  padding-right: 12px;
  padding-left: 12px;
  color: var(--color-text-1);
  font-size: 14px;
  border: 1px solid #c3c3c3 !important;
  border-radius: var(--border-radius-small);
  cursor: text;
  transition: color 0.1s cubic-bezier(0, 0, 1, 1), border-color 0.1s cubic-bezier(0, 0, 1, 1), background-color 0.1s cubic-bezier(0, 0, 1, 1);
  position: relative;
  display: inline-block;
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  overflow: hidden;
}
.arco-textarea {
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  color: inherit;
  background: none;
  border: 1px solid #c3c3c3 !important;
  border-radius: 0;
  outline: none;
  cursor: inherit;
  display: block;
  box-sizing: border-box;
  height: 100%;
  min-height: 32px;
  padding: 4px 12px;
  font-size: 14px;
  line-height: 1.5715;
  vertical-align: top;
  resize: vertical;
}
</style>
