<template>
  <a-button @click="handleClick" type="outline" status="warning">注册/登录</a-button>
  <a-modal v-model:visible="visible" :closable="true" class="rig" :footer="null" hide-title="true" :width="isRegister ? '1000px' : '950px'">
    <span @click="visible = false" class="close-icon">
      <img src="../../../assets/img/dl-icon1.png" />
    </span>
    <div class="relative img-content" style="float: left; width: 45%; display: inline"
      :style="{ height: isRegister === true ? '600px' : '470px' }">
      <img :src="banner.kf_qr" :style="{
        height: isRegister === true ? '207px' : '163px',
        width: isRegister === true ? '206px' : '174px',
        margin: isRegister === true ? '274px 0px 0px 116px' : '205px 0 0 122px', }" />
    </div>
    <div style="float: right; width: 50%; margin-top: -20px">
      <a-card style="border: transparent;">
        <div v-if="isRegister" class="register-item w-[360px] m-auto" style="margin-left: 26px;">
          <div class="flex justify-between items-center mt-[60px]" style="padding-bottom: 20px;">
            <strong class="text-xl font-bold text-[#333333] cursor-default">HELLO!欢迎注册</strong>
            <span class="text-sm text-[#fd802e] self-end cursor-pointer" @click="isRegister = false">登录&gt;</span>
          </div>
          <a-form :model="form" class="rigMod" style="height:500px; flex: 1 1 0; margin-top: -20px;">
            <a-form-item field="name" style="width: 477px; margin-left: -108px; padding-top: 10px;">
              <a-input style="width: 100%;" v-model="form.name" placeholder="请输入手机号">
                <template #prefix>
                  <IconMobile style="height: 20px; width: 20px" />
                </template>
              </a-input>
            </a-form-item>
            <a-form-item field="password" style="width: 477px; margin-left: -108px;">
              <a-input style="width: 100%;" v-model="form.password" type="password" placeholder="请输入密码">
                <template #prefix>
                  <icon-lock style="height: 20px; width: 20px" />
                </template>
              </a-input>
            </a-form-item>
            <a-form-item field="rePassword" style="width: 477px; margin-left: -108px;">
              <a-input style="width: 100%;" v-model="form.rePassword" type="password" placeholder="请再次输入密码">
                <template #prefix>
                  <icon-lock style="height: 20px; width: 20px" />
                </template>
              </a-input>
            </a-form-item>
            <a-form-item field="code" style="width: 477px; margin-left: -108px;">
              <a-input style="width: 100%;" v-model="form.code" placeholder="请输入图形验证码">
                <template #prefix>
                  <IconSafe style="height: 20px; width: 20px" />
                </template>
                <template #suffix>
                  <img :src="captchaInfo.image" alt="验证码" @click="getCaptcha"
                    style="cursor: pointer; height: 50px; width: 100px" />
                </template>
              </a-input>
            </a-form-item>
            <a-form-item field="smscode" style="width: 477px; margin-left: -108px;">
              <a-input style="width: 100%;" v-model="form.smscode" placeholder="请输入短信验证码">
                <template #prefix>
                  <IconUserGroup style="height: 20px; width: 20px" />
                </template>
                <template #suffix>
                  <span @click="sendSMS"
                    style="cursor: pointer; border-radius: 5px; border: 1px solid rgb(var(--warning-6)); width: 100px; text-align: center; color: rgb(var(--warning-6)); display: inline-block; margin-left: 8px;"
                    :disabled="isCounting">
                    {{ isCounting ? countdown + '秒' : '发送验证码' }}
                  </span>
                </template>
              </a-input>
            </a-form-item>
            <a-form-item field="invateCode" style="width: 477px; margin-left: -108px;">
              <a-input style="width: 100%;" v-model="form.invateCode" placeholder="请输入邀请码">
                <template #prefix>
                  <icon-skin />
                </template>
              </a-input>
            </a-form-item>
            <a-form-item style="margin-left: -40px;">
              <div style="margin-top: 10px;">
                <a-button @click="handleRegister" class="h-[48px] w-[300px] bg-[#fc683d] text-white text-base rounded"
                  style="width: 300px; height: 48px; background-color: #fc683d; border-radius: 3px; font-size: 16px;"
                  type="primary">注册</a-button>
              </div>
            </a-form-item>
          </a-form>
        </div>
        <div v-else>
          <a-form :model="form" style="margin-top: 100px; margin-left: -17px;">
           <a-tabs style="margin-top: -30px " class="login_tab" size="large">
            <a-tab-pane key="1" title="微信登陆">
              <div class="right-side flex justify-center items-center flex-col" style="margin-top: 20px;">
            <div class="qrcode w-[260px] h-[260px]" style="width: 220px;height: 220px;">
              <div v-if="qrCodeData && qrCodeData.img ">
                <img :src="qrCodeData.img" style="width: 200px;height: 200px;" />
                <span style="color: #fe802d;padding-top: 5px;">打开微信 扫一扫登录</span>
              </div>
              <div v-else>二维码将显示在这里</div>
            </div>
          </div>
            </a-tab-pane>

            <a-tab-pane key="2" title="账号登录">
            <a-form-item field="name">
              <a-input style="width: 90%; margin-left: -20px;" v-model="form.name" placeholder="请输入用户名">
                <template #prefix>
                  <icon-user />
                </template>
              </a-input>
            </a-form-item>
            <a-form-item field="password">
              <a-input style="width: 90%; margin-left: -20px;" v-model="form.password" type="password" placeholder="请输入密码">
                <template #prefix>
                  <icon-lock />
                </template>
              </a-input>
            </a-form-item>
            <a-form-item field="remember">
              <a-checkbox v-model="remember">记住密码</a-checkbox>
              <span style="padding-left: 115px; color: rgb(var(--warning-6))">忘记密码?</span>
            </a-form-item>
            <a-form-item>
              <a @click="isRegister = true" style="color: rgb(var(--warning-6)); margin-left: 25%; cursor: pointer;">还没有账号？点我注册</a>
            </a-form-item>
            <a-form-item class="buttonControl">
              <div style="margin-top: 5px; width: 100%;">
                <a-button @click="handleLogin" style="width: 300px; height: 48px; background-color: #fc683d; border-radius: 3px; font-size: 16px;"
                  type="primary">立即登录</a-button>
              </div>
            </a-form-item>
          </a-tab-pane>
        </a-tabs>
          </a-form>
        </div>
        <!-- <div v-if="loading" class="progress-bar-container">
          <div class="progress-bar" :style="{ width: progress + '%' }"></div>
        </div> -->
      </a-card>
    </div>
  </a-modal>
</template>

<script setup>
import { reactive, ref, onMounted } from 'vue';
import { register, login, captcha, send, getInfo } from '@api';
import { useAuthStore } from '@/stores/auth';
import { Message, Notification } from '@arco-design/web-vue';

const visible = ref(false);
const isRegister = ref(false);
const form = reactive({
  name: '',
  password: '',
  rePassword: '',
  code: '',
  smscode: '',
  invateCode: '',
  id: ''
});
const authStore = useAuthStore();
const loading = ref(false);
const progress = ref(0);
const remember = ref(false);
const captchaInfo = ref({ image: '' });

const isCounting = ref(false);
const countdown = ref(60);

const banner = reactive({
  kf_qr: ''
})

const getImg = async () => {
  const bannerData = await getInfo();
  banner.kf_qr = bannerData.data.data.kf_qr;
}
getImg();

const handleClick = () => {
  visible.value = true;
  isRegister.value = false; // 默认显示登录表单
};

const sendSMS = async () => {
  if (isCounting.value) return; // 如果正在倒计时，直接返回

  try {
    const res = await send({ mobile: form.name });
    if (res.data.code === 0) {
      Message.error(res.data.msg);
    } else {
      Message.success('发送成功');
      startCountdown();
    }
  } catch (error) {
    Message.error('发送失败: ' + error.message);
  }
};

const startCountdown = () => {
  isCounting.value = true;
  countdown.value = 60;

  const interval = setInterval(() => {
    countdown.value--;
    if (countdown.value <= 0) {
      clearInterval(interval);
      isCounting.value = false; // 结束倒计时
    }
  }, 1000);
};

const getCaptcha = async () => {
  try {
    const captchaRes = await captcha();
    if (captchaRes.data.code === 0) {
      Message.error(captchaRes.data.msg);
    }
    captchaInfo.value = captchaRes.data.data;
    form.id = captchaRes.data.data.id;
  } catch (error) {
    Message.error(error);
  }
};

const handleRegister = async () => {
  try {
    const res = await register({
      id: form.id,
      username: form.name,
      password: form.password,
      re_password: form.rePassword,
      code: form.smscode,
      captcha_code: form.code,
      invate_code: form.invateCode
    });
    if (res.data.code === 0) {
      Message.error(res.data.msg);
    } else {
      Message.success('注册成功');
      isRegister.value = true
    }
  } catch (error) {
    Message.error(error);
  }
};

const handleLogin = async () => {
  loading.value = true;
  progress.value = 0;

  try {
    const loginRes = await login({
      account: form.name,
      password: form.password
    });
    if (loginRes.data.code === 0) {
      Message.error(loginRes.data.msg);
    } else {
      Notification.success({
        title: '登录成功',
        content: `欢迎回来,${form.name}`,
        duration: 3000
      });
      const { token } = loginRes.data.data.userinfo;
      await authStore.setToken(token);
      for (let i = 0; i <= 100; i++) {
        setTimeout(() => {
          progress.value = i;
        }, i * 20); // 调整速度
      }
      setTimeout(async () => {
        visible.value = true
        loading.value = false;
        progress.value = 0; // 重置进度条
        await location.reload();
      }, 2000); // 进度条持续时间
    }
  } catch (error) {
    loading.value = false;
    Message.error(error);
  }
};

onMounted(() => {
  const token = localStorage.getItem('token');
  if (!token) {
    visible.value = true;
  }
  getCaptcha();
});

</script>

<style>
.login_tab .arco-tabs-nav {
    align-items: center;
    display: flex;
    overflow: hidden;
    position: relative;
    font-size: 29px;
}
.login_tab .arco-tabs-nav-tab {
    display: flex;
    flex: 1;
    overflow: hidden;
    margin: 0 0 0 136px;
    /* font-size: 31px; */
}
.img-content {
  background-image: url("../../../assets/img/dl-img.png");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
}

.close-icon {
  position: absolute;
  right: 0px;
  cursor: pointer;
  z-index: 99;
  height: 54px;
  width: 54px;
}

.rig .arco-modal-body {
  position: relative;
  padding: 0 !important;
  overflow: auto;
  font-size: 14px;
}

.arco-form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.rig .arco-input-wrapper:focus-within,
.rig .arco-input-wrapper.arco-input:focus {
  z-index: 1;
  background-color: var(--color-bg-2);
  border-color: transparent !important;
  border-style: none !important;
  border-bottom: 1px solid #dad7d7 !important;
  box-shadow: none !important;
}

.arco-input-wrapper:hover {
  background-color: var(--color-fill-3);
  border-color: transparent;
  border-bottom: 1px solid #dad7d7 !important;
}

.rig .arco-input-wrapper {
  display: inline-flex;
  box-sizing: border-box;
  width: 75%;
  color: var(--color-text-1);
  font-size: 14px;
  background-color: white !important;
  border: 1px solid transparent;
  border-bottom: 1px solid #dad7d7;
  border-radius: var(--border-radius-small);
  padding-top: 10px;
  cursor: text;
  transition: color 0.1s cubic-bezier(0, 0, 1, 1), border-color 0.1s cubic-bezier(0, 0, 1, 1), background-color 0.1s cubic-bezier(0, 0, 1, 1);
}

.rig .arco-form-item-content-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  background-color: white;
  border-bottom: 1px solid #f4f4f4a6;
}

.rig.arco-tabs-nav-scroll,
.rig.arco-tabs-nav-list {
  flex-grow: 0 !important;
  justify-content: center;
}

.w-[428px] {
  width: 428px;
}

.relative {
  position: relative;
}

.pt-[60px] {
  padding-top: 60px;
}

.w-[360px] {
  width: 360px;
}

.m-auto {
  margin: auto;
}

.justify-center {
  justify-content: center;
}

.flex {
  display: flex;
}

menu,
ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.progress-bar-container {
  width: 100%;
  height: 10px;
  background: #e0e0e0;
  margin-top: 20px;
}

.progress-bar {
  height: 100%;
  background: #4caf50;
  transition: width 0.2s ease;
}

.arco-modal-close-btn {
  margin-left: 946px;
  margin-bottom: 10px;
  color: var(--color-text-1);
  font-size: 12px;
  cursor: pointer;
}
</style>
