<style scoped>
.arco-btn-text:hover,
.arco-btn-text[type='button']:hover,
.arco-btn-text[type='submit']:hover {
  background-color: #fd802e !important;
  border-color: transparent;
  color: rgb(var(--primary-6));
}
</style>
<template>
  <a-button @click="handleClick" type="text"
    style="width:100%;color:white;">提现</a-button>
  <a-modal v-model:visible="visible" @cancel="handleCancel" :on-before-ok="handleBeforeOk" :simple=true width="auto">
    <a-row style="padding-bottom:3rem">
      <a-col>
        <span style="padding-right:2rem">支付宝账号:</span>
        <a-tag color="orangered">{{ form.ali_name }}</a-tag>
        <a-tag color="orangered">{{ form.ali_account }}</a-tag>
        <a-button type="primary" style="margin-left:20px" status="warning" @click="showBangDing">重新绑定</a-button>
      </a-col>
    </a-row>
    <a-row style="padding-bottom:3rem">
      <a-col>
        <span style="padding-right:2rem">佣金余额</span>
        <a-tag color="orangered">{{ form.commission }}</a-tag>
      </a-col>
    </a-row>
    <a-row>
      <a-col>
        <span style="padding-right:2rem">提现金额:</span>
        <a-input :style="{ width: '320px' }" allow-clear v-model="form.money" />
      </a-col>
    </a-row>

    <a-modal v-model:visible="bangDingVisible" @ok="handleBangDingOk" @cancel="handleBangDingCancel">
      <a-form-item field="name" label="姓名">
        <a-input v-model="form.ali_name" />
      </a-form-item>
      <a-form-item field="name" label="账号">
        <a-input v-model="form.ali_account" />
      </a-form-item>
    </a-modal>
  </a-modal>
</template>

<script setup>
import { ref, reactive, defineEmits } from 'vue';
import { getUserInfo, pushMoney, updateAli } from '@api';
import { Message } from '@arco-design/web-vue';

// 导入 defineEmits
const emit = defineEmits();

const visible = ref(false);
const bangDingVisible = ref(false);
const form = reactive({
  ali_name: '',
  ali_account: '',
  commission: '',
  money: ''
});

const getInfo = async () => {
  try {
    const res = await getUserInfo();
    form.ali_name = res.data.ali_name;
    form.ali_account = res.data.ali_account;
    form.commission = res.data.commission;
  } catch (error) {
    console.log(error);
  }
};

const handleClick = () => {
  visible.value = true;
  getInfo();
};

const showBangDing = () => {
  bangDingVisible.value = true;
};

const handleBangDingCancel = () => {
  bangDingVisible.value = false;
};

const handleBangDingOk = async () => {
  try {
    const res = await updateAli({
      ali_name: form.ali_name,
      ali_account: form.ali_account
    });
    if (res.data.code !== 20000) {
      Message.warning(res.data.msg);
    } else {
      Message.success(res.data.msg);
      await new Promise(resolve => setTimeout(resolve, 3000));
      bangDingVisible.value = false; // 关闭绑定模态框
    }
  } catch (error) {
    console.log(error);
  }
};

const handleBeforeOk = async () => {
  try {
    const res = await pushMoney({ money: form.money });
    if (res.data.code !== 20000) {
      Message.warning(res.data.msg);
    } else {
      Message.success(res.data.msg);
      emit('tixian-complete');
      await new Promise(resolve => setTimeout(resolve, 3000));
      return true;
    }
  } catch (error) {
    console.log(error);
  }
};

const handleCancel = () => {
  visible.value = false;
}
</script>

<style>
</style>
