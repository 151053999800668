<template>
  <div class="flex-1">
    <div class="mb-6">
      <a-card>
        <div class="flex justify-start items-center h-[68px] border-[#e5e5e5] border-b-[1px] capitalize">
          <div class="flex-auto flex justify-start items-center">
            <div class="font-bold text-[#fe802d] text-2xl mr-4" style="color: darkorange;">消息通知</div>
          </div>
        </div>
        <div>
          <div class="leading-10 flex justify-end items-center my-3">
            <a-button type="outline" @click="markAsRead" status="warning">标记为已读</a-button>
          </div>
          <a-table
            :data="tableData"
            :columns="columns"
            :row-selection="rowSelection"
            v-model:selectedKeys="selectedRowKeys"
          >
            <template #button="{ record }">
              <infomation :content="record.content" :pushTime="record.push_time" :title="record.title" />
            </template>
          </a-table>
          <a-pagination
            :current="currentPage"
            :page-size="pageSize"
            :total="total"
            @change="handlePageChange"
            @pageSizeChange="handlePageSizeChange"
            class="justify-center mt-10 mb-5"
          />
        </div>
      </a-card>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { ACard, ATable, AButton, APagination } from '@arco-design/web-vue';
import { getNoticeList } from '@api';
import infomation from './component/infomationAlert.vue';

export default {
  components: {
    ACard,
    ATable,
    AButton,
    APagination,
    infomation
  },
  setup () {
    const selectedRowKeys = ref([]); // 管理选中的行
    const rowSelection = {
      type: 'checkbox',
      selectedRowKeys: selectedRowKeys // 绑定到选中的行
    };
    console.log(rowSelection.selectedRowKeys)
    const currentPage = ref(1);
    const pageSize = ref(10);
    const total = ref(50);

    const columns = [
      { title: 'id', dataIndex: 'id', key: 'id' },
      { title: '标题', dataIndex: 'title', key: 'title' },
      { title: '内容', dataIndex: 'content', key: 'content' },
      { title: '推送时间', dataIndex: 'push_time', key: 'push_time' },
      { title: '操作', dataIndex: 'button', slotName: 'button' }
    ];

    const tableData = ref([]);

    const markAsRead = () => {
      console.log('已选择的行:', selectedRowKeys.value);
    };

    const handlePageChange = (newPage) => {
      currentPage.value = newPage;
    };

    const handlePageSizeChange = (newSize) => {
      pageSize.value = newSize;
    };

    const getNotice = async () => {
      try {
        const noticeRes = await getNoticeList({ type: 3 });
        tableData.value = noticeRes.data.data.list;
        console.log('表格数据:', tableData.value);
      } catch (error) {
        console.error(error);
      }
    };

    getNotice();

    return {
      currentPage,
      selectedRowKeys,
      rowSelection,
      pageSize,
      total,
      columns,
      tableData,
      markAsRead,
      handlePageChange,
      handlePageSizeChange
    };
  }
};
</script>

<style scoped>
/* 自定义样式 */
</style>
