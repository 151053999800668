<template>
  <div class="flex-1">
    <div class="mb-5 bg-white rounded-lg px-5 pb-5">
      <div class="flex justify-start items-center h-[68px] border-b-[1px] border-[#e5e5e5] capitalize"
        style="padding: 10px;">
        <div class="flex-auto flex justify-start items-center">
          <div class="font-bold text-[#fe802d] text-2xl mr-4" style="color: #fe802d;">拼多多无痕做单</div>
          <div class="tool-header-content">
            <p>优惠套餐：购买套餐可低至 0.5 积分/次</p>
          </div>
        </div>
        <div class="tool-header-right text-base text-[#333]">
          <div class="flex justify-start items-center mr-3">
            <img class="w-6 h-6 mr-2" src="/zhaoyaojing_files/biaoti-icon1.png" alt="" />
            <span>单次：0积分</span>
          </div>
          <div class="flex justify-start items-center">
            <img class="w-6 h-6 mr-2" src="/zhaoyaojing_files/biaoti-icon2.png" alt="" />
            <span>会员当日次数：0/0次</span>
          </div>
        </div>
      </div>

      <div class="flex mt-5" style="padding: 50px 30px 30px;">
        <el-form class="left-side el-form--label-left" style="padding-left: 27px;width:1600px;">
          <div :key="index">
            <el-form-item label="商品链接" class="asterisk-left flex justify-start items-center" style="width: 90%;">
              <el-input v-model="links.url" placeholder="请输入商品链接" class="flex-1" style="padding-left: 10px;height: 40px;"></el-input>
            </el-form-item>
            <el-form-item label="SKU_ID" class="asterisk-left flex justify-start items-center" style="width: 90%;">
              <el-input v-model="links.sku" placeholder="请输入SKU" class="flex-1" style="padding-left: 20px;height: 40px;"></el-input>
            </el-form-item>
            <el-form-item label="数量" class="asterisk-left flex justify-start items-center counts">
              <el-input-number v-model="links.number" :min="1" :max="100" class="ml-2" style="height: 40px;"></el-input-number>
              <div class="text-red w-[310px]">（获取SKU，额外收取2积分）</div>
            </el-form-item>
            <el-form-item class="asterisk-left" style="padding-left: 80px; padding-top: 40px;">
              <el-button type="primary" class="w-[200px]" @click="generateQRCode"
                style="width: 200px; height: 40px;">生成二维码</el-button>
            </el-form-item>
          </div>
        </el-form>

        <div class="right-side flex justify-center items-center flex-col" style="margin-left: 180px;">
            <div class="qrcode w-[260px] h-[260px]" style="width: 260px;height: 260px;margin-top: 10px;">
              <div v-if="qrCodeData && qrCodeData.imgSrc">
                <img :src="qrCodeData.imgSrc" style="width: 260px;height: 260px;" />
              </div>
              <div v-else>二维码将显示在这里</div>
            </div>
          </div>
      </div>
    </div>

    <div class="mb-6">
      <div class="bg-white rounded-lg px-5 pb-5">
        <div class="flex justify-start items-center h-[68px] border-[#e5e5e5] capitalize border-none">
          <div class="flex-auto flex justify-start items-center">
            <div>
              <div class="flex justify-start items-center"><img src="../../assets/img/zyj-icon1-jilu.png" alt=""><span
                  class="text-xl text-[#333] font-bold p-2">生成记录</span></div>
            </div>
            <div class="flex-1"></div>
          </div>
        </div>
        <a-table :columns="columns" :data="inviteDetails" row-key="id" pagination="false">
          <template #qrCode="{ record }">
            <qrcodeOpen :url="record.content" />
          </template>
        </a-table>
        <el-pagination :total="total" v-model:current-page="currentPage" :page-size="pageSize"
          @size-change="handlePageSizeChange" @current-change="handlePageChange"></el-pagination>
      </div>

      <div class="bg-white rounded-lg px-5 pb-5">
          <el-tabs v-model="activeTab" class="mt-4" style="text-align:left">
          <el-tab-pane label="功能介绍" class="tip-text">
            <p>无痕秒单就是显示成交关键词，但是成交来源里面什么都不显示，没有是任何痕迹，快速带动关键词搜索权重，获取更多展示</p>
            <p>对于店铺新品破零或者只是增加商品的基础销量权重梦无痕下单直接越过浏览过程，直接下单</p>
            <p>生意参谋后台数据只显示成交关键词，成交来源里面什么都不显示，没有任何痕迹，这样可以快速带动关键词搜索权重，提高店铺流量</p>
          </el-tab-pane>
          <el-tab-pane label="使用场景" class="tip-text">
            <p>1.对于店铺新品快速提升基础销量权重，无痕BD就是直接越过留来你过程，直接下单，没有任何痕迹对新品更加安全</p>
            <p>2.对于近期店铺需要谨慎BD使用无痕下单安全性更高，每天一两百单内非常安全（让用户直接下单不要浏览自己的宝贝，这样会非常安全）不计入访客，转化率会越高，甚至超过100%无搜索权重，只累计基础销量权重</p>
            <p>3.无痕下单的作用是为了增加权重获取更多流量，是为了做店铺运营，将流量转化为订单</p>
            <p>4.具体的无痕BD就是直接越过浏览过程,直接下单，没有任何痕迹，即使通过生意参谋流量路径，访客人数等数据都无法显示，这样可以快速带动关键词搜索权重，提高店铺流量</p>
            <p>5.新品商家，帮助店铺商品快速破零，获得更多的流量和曝光</p>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script setup>
import QRCode from 'qrcode';
import { Message } from '@arco-design/web-vue';
import { ref, onMounted, reactive } from 'vue';
import { pddWhbd, getNoTraceLog } from '@api';

import qrcodeOpen from './component/showQrCode.vue'

const links = reactive({ sku: '', url: '', number: 1 });
const columns = [
  {
    title: '商品ID',
    dataIndex: 'url',
    align: 'center'
  },
  {
    title: 'SKU',
    dataIndex: 'sku_id',
    key: 'sku_id',
    align: 'center'
  },
  {
    title: '生成时间',
    dataIndex: 'create_time',
    key: 'create_time',
    sortable: { sortDirections: ['descend'], align: 'center' },
    align: 'center'
  },
  {
    title: '操作',
    dataIndex: 'content',
    key: 'content',
    align: 'center',
    slotName: 'qrCode'
  }
];

const total = ref(0);
const currentPage = ref(1);
const pageSize = ref(10);
const inviteDetails = ref([]);
const QrCode = reactive({
  imgsrc: ''
})

const generateQRCode = async () => {
  try {
    const res = await pddWhbd({
      url: links.url,
      sku_id: links.sku,
      count: links.number
    });
    if (res.data.code !== 20000) {
      Message.warning(res.data.msg)
    } else {
      Message.success(res.data.msg)
    }
    const qrCodeUrl = await QRCode.toDataURL(res.data.data); // 生成二维码
    QrCode.imgsrc = qrCodeUrl;
    await getList();
  } catch (error) {

  }
};

const getList = async () => {
  try {
    const getLog = await getNoTraceLog({ size: pageSize.value, page: currentPage.value, platform_id: 2 });
    inviteDetails.value = getLog.data.data.list;
  } catch (error) {
    console.error('获取列表时出错:', error);
  }
};

onMounted(() => {
  getList();
});
</script>

<style>
.counts .el-form-item__content {
  align-items: center;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  font-size: var(--font-size);
  line-height: 32px;
  min-width: 0;
  padding-left: 28px !important;
  position: relative;
}

.right-side .qrcode {
  margin-top: -20px;
  align-items: center;
  background: #f5f5f5;
  border-radius: 10px;
  color: var(--sub-font-color);
  display: flex;
  font-size: 14px;
  height: 310px;
  justify-content: center;
  width: 380px;
}
</style>
