<template>
  <div class="flex-1">
    <div class="mb-6">
      <div class="bg-white rounded-lg px-5 pb-5">
        <!-- Title Bar -->
        <div class="flex justify-start items-center h-[68px] border-[#e5e5e5] border-b-[1px] capitalize">
          <div class="flex-auto flex justify-start items-center">
            <div class="font-bold text-[#fe802d] text-2xl mr-4" style="color: darkorange;">财务明细</div>
          </div>
        </div>
        <!-- Tabs -->
        <a-tabs v-model:value="activeTab" style="padding-top: 40px;">
          <a-tab-pane key="pane-1" title="消费明细">
            <!-- Content for pane-1 -->
            <div class="p-3 rounded mt-2.5" style="display: inline-flex;height: 20px;float: left">
              <a-form layout="inline" size="medium">
                <a-form-item label="消费时间">
                  <a-range-picker placeholder="开始日期" start-placeholder="开始日期" end-placeholder="结束日期"
                    style="margin-right: 24px; margin-left: 12px" v-model="form.getTime" />
                </a-form-item>
                <a-form-item label="消费项目">
                  <a-select placeholder="请选择消费项目"
                    style="width: 200px; margin-right: 24px; margin-left: 12px"></a-select>
                </a-form-item>
                <a-form-item>
                  <div>
                    <a-button type="primary" status="warning" @click="queryList(1)">查询</a-button>
                    <a-button type="outline" status="warning" style="margin-left: 10px">重置</a-button>
                    <a-button type="outline" status="warning" style="margin-left: 10px">导出</a-button>
                    <span class="text-[red]" style="margin-left: 10px">（注：本工具箱仅保留最近一个月数据，请及时下载！）</span>
                  </div>
                </a-form-item>
              </a-form>
            </div>
            <div class="p-3 rounded mt-2.5" style="padding-top: 150px;">
              <a-table :data="consumeData" :columns="consumeColumns"></a-table>
            </div>
          </a-tab-pane>
          <a-tab-pane key="pane-2" title="充值明细">
            <!-- Content for pane-2 -->
            <div class="p-3 rounded mt-2.5" style="display: inline-flex;height: 20px;float: left">
              <a-form layout="inline" size="medium">
                <a-form-item label="充值时间">
                  <a-range-picker placeholder="开始日期" start-placeholder="开始日期" end-placeholder="结束日期"
                    style="margin-right: 24px; margin-left: 12px" v-model="form.getTime" />
                </a-form-item>
                <a-form-item label="充值积分">
                  <a-input v-model="form.score" placeholder="请输入充值积分"></a-input>
                </a-form-item>
                <a-form-item>
                  <a-button type="primary" status="warning" @click="queryList(2)">查询</a-button>
                  <a-button type="outline" status="warning" style="margin-left: 10px">重置</a-button>
                  <a-button type="outline" status="warning" style="margin-left: 10px">导出</a-button>
                  <span class="text-[red]" style="margin-left: 10px">（注：本工具箱仅保留最近一个月数据，请及时下载！）</span>
                </a-form-item>
              </a-form>
            </div>
            <div class="p-3 rounded mt-2.5" style="padding-top: 150px;">
              <a-table :columns="payColumns" :data="payData"></a-table>
            </div>
          </a-tab-pane>
          <a-tab-pane key="pane-3" title="会员明细">
            <!-- Content for pane-3 -->
            <div class="p-3 rounded mt-2.5" style="display: inline-flex;height: 20px;float: left">
              <a-form layout="inline" size="medium">
                <a-form-item label="消费时间">
                  <a-range-picker placeholder="开始日期" start-placeholder="开始日期" end-placeholder="结束日期"
                    style="margin-right: 24px; margin-left: 12px" v-model="form.getTime" />
                </a-form-item>
                <a-form-item label="消费项目">
                  <a-select placeholder="请选择会员类型" style="width: 200px; margin-right: 24px; margin-left: 12px"
                    v-model="form.vipType">
                    <a-option value="1">月卡</a-option>
                    <a-option value="2">季卡</a-option>
                    <a-option value="3">半年卡</a-option>
                    <a-option value="4">年卡</a-option>
                  </a-select>
                </a-form-item>
                <a-form-item>
                  <a-button type="primary" @click="queryList(3)" status="warning">查询</a-button>
                  <a-button type="outline" status="warning" style="margin-left: 10px">重置</a-button>
                  <a-button type="outline" status="warning" style="margin-left: 10px">导出</a-button>
                  <span class="text-[red]" style="margin-left: 10px">（注：本工具箱仅保留最近一个月数据，请及时下载！）</span>
                </a-form-item>
              </a-form>
            </div>
            <div class="p-3 rounded mt-2.5" style="padding-top: 150px;">
              <a-table :columns="memberColumns" :data="memberData"></a-table>
            </div>
          </a-tab-pane>
          <a-tab-pane key="pane-4" title="邀请明细">
            <!-- Content for pane-4 -->
            <div class="p-3 rounded mt-2.5" style="display: inline-flex;height: 20px; float: left">
              <a-form layout="inline" size="medium">
                <a-form-item label="消费时间">
                  <a-range-picker placeholder="开始日期" start-placeholder="开始日期" end-placeholder="结束日期"
                    style="margin-right: 24px; margin-left: 12px" v-model="form.getTime" />
                </a-form-item>
                <a-form-item>
                  <a-button type="primary" status="warning" @click="queryList(4)">查询</a-button>
                  <a-button type="outline" status="warning" style="margin-left: 10px">重置</a-button>
                  <a-button type="outline" status="warning" style="margin-left: 10px">导出</a-button>
                  <span class="text-[red]" style="margin-left: 10px">（注：本工具箱仅保留最近一个月数据，请及时下载！）</span>
                </a-form-item>
              </a-form>
            </div>
            <div class="p-3 rounded mt-2.5" style="padding-top: 150px;">
              <a-table :data="inviteData" :columns="inviteColumns">
                <template #status="{ record }">
                  {{ record.status === 0 ? '暂未结算' : '已结算' }}
                </template>
              </a-table>
            </div>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue';
import { getConsumeList, getPayList, getMemberList, getInviteList } from '@api';

export default {
  setup () {
    const activeTab = ref('pane-1');
    const form = reactive({
      getTime: '',
      score: '',
      vipType: ''
    });

    const consumeColumns = ref([
      { title: '创建时间', dataIndex: 'create_time' },
      { title: '订单号', dataIndex: 'order_number' },
      { title: '交易金额', dataIndex: 'cost' },
      { title: '消费后余额', dataIndex: 'after' },
      { title: '项目名称', dataIndex: 'project' },
      { title: '备注', dataIndex: 'remark' }
    ]);
    const consumeData = ref([]);

    const payColumns = ref([
      { title: '创建时间', dataIndex: 'create_time' },
      { title: '账户', dataIndex: 'account' },
      { title: '充值金额', dataIndex: 'pay_money' },
      { title: '充值后余额', dataIndex: 'pay_integral' },
      { title: '备注', dataIndex: 'remark' }
    ]);
    const payData = ref([]);

    const memberColumns = ref([
      { title: '创建时间', dataIndex: 'create_time' },
      { title: '订单号', dataIndex: 'order_number' },
      { title: '充值方式', dataIndex: 'mode' },
      { title: '会员类型', dataIndex: 'type' },
      { title: '时长', dataIndex: 'day' },
      { title: '消费积分', dataIndex: 'integral' },
      { title: '备注', dataIndex: 'remarks' }
    ]);
    const memberData = ref([]);

    const inviteColumns = ref([
      { title: '创建时间', dataIndex: 'create_time' },
      { title: '订单类型', dataIndex: 'order_type' },
      { title: '交易金额', dataIndex: 'money' },
      { title: '结算状态', dataIndex: 'status', slotName: 'status' },
      { title: '返佣金额', dataIndex: 'return_money' },
      { title: '备注', dataIndex: 'remark' }
    ]);
    const inviteData = ref([]);

    const queryList = async (param) => {
      const [startTimeData, endTimeData] = form.getTime || [];
      if (param === 1) {
        try {
          const response = await getConsumeList({ start_time: startTimeData, end_time: endTimeData, type: 1 });
          consumeData.value = response.data.data.list;
        } catch (error) {
          console.error('Failed to fetch consume list:', error);
        }
      }
      if (param === 2) {
        try {
          const response = await getPayList({ start_time: startTimeData, end_time: endTimeData, score: form.score });
          payData.value = response.data.data.list;
        } catch (error) {
          console.error('Failed to fetch pay list:', error);
        }
      }
      if (param === 3) {
        try {
          const response = await getMemberList({ start_time: startTimeData, end_time: endTimeData, type: form.vipType });
          memberData.value = response.data.data.list;
        } catch (error) {
          console.error('Failed to fetch member list:', error);
        }
      }
      if (param === 4) {
        try {
          const response = await getInviteList({ start_time: startTimeData, end_time: endTimeData });
          inviteData.value = response.data.data.list;
        } catch (error) {
          console.error('Failed to fetch invite list:', error);
        }
      }
    };
    onMounted(() => {
      queryList(1); // 初始化时加载第一个 Tab 的数据
      queryList(2);
      queryList(3);
      queryList(4);
    });

    return {
      activeTab,
      form,
      consumeColumns,
      consumeData,
      payColumns,
      payData,
      memberColumns,
      memberData,
      inviteColumns,
      inviteData,
      queryList
    };
  }
};
</script>

<style scoped>
.arco-tabs-tab-active,
.arco-tabs-tab-active:hover {
  color: rgb(255 127 22) !important;
  font-weight: 500;
}

.arco-tabs-nav-ink {
  position: absolute;
  top: initial;
  right: initial;
  bottom: 0;
  height: 2px;
  background-color: rgb(255 127 22) !important;
  transition: left 0.2s cubic-bezier(0.34, 0.69, 0.1, 1), width 0.2s cubic-bezier(0.34, 0.69, 0.1, 1);
}
</style>
