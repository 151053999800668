<template>
  <div class="flex-1">
    <!-- 卡片部分 -->
    <a-card shadow="never" class="mb-6" style="border-radius: 10px;">
      <div class="flex justify-start items-center h-[68px] border-b-[1px] border-[#e5e5e5] capitalize" style="padding: 10px;">
        <div class="flex-auto flex items-center">
          <h2 class="font-bold text-[#fe802d] text-2xl mr-4" style="color: darkorange;">防P图验号</h2>
          <div class="tool-header-content">
            <p>优惠套餐：购买套餐可低至 {{ discountRate }} 积分/次</p>
          </div>
        </div>
        <div class="tool-header-right text-base text-[#333]">
          <div class="flex items-center mr-3">
            <img class="w-6 h-6 mr-2" src="/zhaoyaojing_files/biaoti-icon1.png" alt="" /><span>单次：{{ singleCost
              }}积分</span>
          </div>
          <div class="flex items-center">
            <img class="w-6 h-6 mr-2" src="/zhaoyaojing_files/biaoti-icon2.png" alt="" /><span>会员当日次数：{{
              memberDailyUsage }}/{{
              memberDailyLimit
            }}次</span>
          </div>
        </div>
      </div>

      <!-- 表单部分 -->
      <a-form label-position="right" inline class="mt-4">
        <div style="text-align: left;" class="w-full flex justify-center">
          <div
            class="m-auto mt-[40px] px-3 py-4 flex justify-center items-center bg-[#fd802e] rounded-lg bg-opacity-30">
            <img class="w-6 h-6 mr-3"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAANTSURBVHgB7ZlLSBVRGMf/35nxWua1rOgp0qYXpEVq9NgUhQWJi0QJV4VBIG0KehPYpl0EES2KIooKksqSytzYKpQeiwxJiHws7EUpPus+ztd31TbhdOfOnRkt7g+Ge2bOd86c/5zvO68LpEiR4p+G4BGcXzwPgcCO0ZtQqIHeNH6BB3gigAuLV4HMJknOHXvAPdDRUnrd+Aouo+AyHPsoZF7A78bHIFoEwzjLHnww1yvkgm0zB8zsjj6Vld2vMkafZekhzNIDvUEMLqGWJ/1wEdcE5JXxiihjNxO2Z+mB9YMqE3q8eiX9kqkH0a+CzcR4agDXW+/RB7hA0gKW7+JNinBCKtosLpJhs1hENNVrxqX2+9SAJHAsoLycjbdRnJcaquGckJS/OBLB6c466oMDHAlYVsJzjQAapHQB3OG5DqO8/SH1IEESHoVW7uKFZjrqXGx8jI0qDbW5OzkbCZKQgIISzpAgvSm+vgnus3HGdDyScSwhr0hIwHAAx6T2LfAKxoYVZahJpIhtteI6a8X6mSSD8BZNCnlttdRmx9h2D0jH7of3jY+htMYRu8a2eiC/mGeEg/gIfwTE+BRWyH9fS1/jGdrqgVAQpfCv8TEWpEWw1Y6hPReS5QF8RuLA1jttCSCN1fAZZqyxYxdXwJI9PE1+FsJvCAtyynl6PLO4AtQQ0omQDr9hpJvh+O+NKyAr7N2286+Qvfe6viPzm5SAySYlYLKxjPSaOxyYo3FOklVy+T+MjtGrgdu9CgdrKig0kYFpVXK88cnsd90gW1ykejbHjptwYCIDSxeSqbwSUwQ5itlnlWcdA4S407hfsMaIVZ6lAAmOK5giyMr0llWeZQx8k8CZExndiVXKWmgWJocfcl2dL22xMnC0zuEmmJyZe1dO1krhAnKy91gVde+EAxzNA7QFESJ1VJKfkSyMHgoYh+AQxxMZFXa+k/47zKMn6g5haEOpalrd0Q6HJDUTm0XdNxTjOJzxkxSdoqLOB0gCV9b6kZbcEqnojAR8nh17GRRaZZ45aa7rrkeSuLZZkQYZ4Re5VcS8V24Licj8I1+GQ2rROno5rWvDNaqojcIFPNltDTcvzlFsrDQNzondM6suc/bAS1r63dV/Z1KkSPEf8AvvRODwdF5ptAAAAABJRU5ErkJggg=="
              alt="">
            <div class="text-[#6b7280]">
              <h3 class="text-xl">验号说明</h3>
              <p class="leading-5">1、生成验号二维码地址发送给用户</p>
              <p class="leading-5"> 2、用户使用微信或者其他浏览器打开，按照页面流程操作 </p>
              <p class="leading-5"> 3、用户验号完成之后就可以用户列表查询到验号的用户数据 </p>
              <p class="leading-5" style="color: red;"> 4、所有的验号数据系统只保留48小时，超过48小时系统会自动删除 </p>
            </div>
          </div>
        </div>
        <a-form-item label="二维码生成" style="padding-top: 30px;">
          <a-select v-model="selectedTime" placeholder="请选择时间"
            style="width: 50rem;background-color: white;border-color: grey;">
            <a-option v-for="item in timeOptions" :key="item.value" :label="item.label" :value="item.value"></a-option>
          </a-select>
          <a-button type="primary" style="margin-left: 10px;height: 40px;width: 150px" @click="queryCheckQrInfo"
            status="warning">点击生成链接</a-button>
        </a-form-item>
        <div class="imgContent" style="justify-content: center">
          <a-image v-if="qrData.imgSrc" :src="qrData.imgSrc" :width="220" :height="220"></a-image>
        </div>
      </a-form>
    </a-card>
    <a-card style="border-radius: 10px;">
      <div class="flex-auto flex justify-start items-center">
            <div>
              <div class="flex justify-start items-center"><img src="../../assets/img/zyj-icon1-jilu.png" alt=""><span
                  class="text-xl text-[#333] font-bold p-2">验号用户列表</span></div>
            </div>
            <div class="flex-1"></div>
          </div>
      <div class="mt-5 bg-white rounded-lg px-5 pb-5" style="margin-top: 50px;">
        <a-table :columns="columns" :data="inviteDetails" row-key="id" pagination="true" striped
           @select="getAllSelection">
        </a-table>
        <a-pagination :total="total" v-model:current-page="currentPage" :page-size="pageSize"
          @size-change="handlePageSizeChange" @current-change="handlePageChange"></a-pagination>
      </div>
    </a-card>
    <a-card style="margin-top: 15px;font-size: 16px;border-radius: 10px;">
      <!-- Tab选项卡部分 -->
      <a-tabs key="1" class="mt-4" style="text-align: left;">
        <a-tab-pane title="功能用途">
          不了解粉丝的信誉的高低，无法辨别具体信息，同时防止粉丝使用P图软件截图给商家。
        </a-tab-pane>
        <a-tab-pane key="2" title="功能用途">
          <p>1、防止粉丝为了伪造个人信息使用P图工具骗取商家的信任。</p>
          <p>2、利用最新的技术，让投机取巧的粉丝无所遁形。</p>
          <p>3、截图中实时更新的时间要与手机右上角显示的时间一致，IP地址的颜色需为黑色。</p>
        </a-tab-pane>
        <a-tab-pane key="3" title="使用场景">
          <p>为了保证粉丝提供的截图的真实性，防止职业打假师、职业差评师等相关职位的粉丝的出现。整个验号、截图、检查的流程所消耗的时间较长，此功能适合中小型商家。</p>
        </a-tab-pane>
      </a-tabs>
    </a-card>
  </div>
</template>

<script>
import {
  reactive,
  ref
} from 'vue';
import {
  getCheckQr,
  getCheckQrList
} from '@api'
import { Message } from '@arco-design/web-vue';

export default {
  setup () {
    const singleCost = ref(2);
    const memberDailyLimit = ref(10);
    const selectedTime = ref('');
    const inviteDetails = ref([]);
    const rowSelection = ref({ type: 'radio' });
    const selectedRowKeys = ref([]);
    const getAllSelection = (selectedKeys, selectedRows) => {
      selectedRowKeys.value = selectedKeys;
      console.log(selectedRowKeys.value)
    }
    const qrData = reactive({
      imgSrc: ''
    })

    const timeOptions = ref([
      {
        label: '15分钟',
        value: 15
      }, {
        label: '30分钟',
        value: 30
      }
    ])
    const queryCheckQrInfo = async () => {
      try {
        const checkQrInfo = await getCheckQr({ id: selectedRowKeys.value });
        if (checkQrInfo.code !== 20000) {
          Message.warning(checkQrInfo.msg)
        } else {
          Message.success(checkQrInfo.msg)
        }

        qrData.imgSrc = checkQrInfo.data.data
        getQrTable();
      } catch (error) {

      }
    }
    const columns = ref([
      { title: '旺旺号', dataIndex: 'account', align: 'center' },
      { title: '真实姓名', dataIndex: 'realname', align: 'center' },
      { title: '年龄', dataIndex: 'age', align: 'center' },
      { title: '性别', dataIndex: 'sex', align: 'center' },
      { title: '注册时间', dataIndex: 'regdate', align: 'center' },
      { title: '实名状态', dataIndex: 'user_type', align: 'center' },
      { title: '淘气值', dataIndex: 'score', align: 'center' },
      { title: '信誉分', dataIndex: 'xinyu', align: 'center' },
      { title: '更新时间', dataIndex: 'update_time', align: 'center' },
      { title: '验号状态', dataIndex: 'status', align: 'center' },
      { title: '操作', dataIndex: 'st111atus', align: 'center' }
    ]);
    const currentPage = ref(1);
    const pageSize = ref(10);
    const total = ref(50);

    const getQrTable = async () => {
      try {
        const qrRes = await getCheckQrList({ size: pageSize.value, page: currentPage.value });
        if (qrRes.data && qrRes.data.data.list) {
          inviteDetails.value = qrRes.data.data.list;
          total.value = qrRes.data.total;
          console.log(inviteDetails.value)
        }
      } catch (error) {
        console.error(error);
      }
    };
    getQrTable();

    return {
      singleCost,
      memberDailyLimit,
      selectedTime,
      inviteDetails,
      columns,
      currentPage,
      pageSize,
      total,
      qrData,
      rowSelection,
      selectedRowKeys,
      timeOptions,
      queryCheckQrInfo,
      getAllSelection,
      getQrTable
    };
  }
}
</script>

<style>
.arco-tabs-tab-title {
    font-size: 16px !important;
}
.arco-tabs-tab-active, .arco-tabs-tab-active:hover {
    color: #fd8e32 !important;
    font-weight: 500;
}
.arco-tabs-nav-ink {
    background-color: #fd8e32 !important;
    bottom: 0;
    height: 2px;
    position: absolute;
    right: auto;
    top: auto;
    transition: left .2s cubic-bezier(.34, .69, .1, 1), width .2s cubic-bezier(.34, .69, .1, 1);
}
.arco-form-item-label-col>.arco-form-item-label {
    color: var(--color-text-2);
    font-size: 1rem !important;
    max-width: 100%;
    white-space: normal;
}
</style>
<style scoped>
/* 如果需要自定义样式，请在这里添加 */
</style>
