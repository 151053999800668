<template>
  <a-button @click="handleClick" type="text" status="warning"
    style=" border-radius: 5px; font-size: 16px;">充值积分</a-button>
    <a-modal title="充值" v-model:visible="visible" width="auto" hideCancel hideTitle hideOk :footer="false" class="charge">
    <div class="recharge-content p-5 rounded-xl">
      <div class="flex justify-start items-center">
        <div class="w-[80px] h-[80px] mr-4">
          <img class="w-full h-full" src="../../../assets/img/hejidanfangchengben.png" alt="用户头像"
            style="width:80px;height:80px" />
        </div>
        <div class="text-sm text-[#000000]">
          <strong>账户余额</strong>
          <div>
            <span class="text-[#fd802e]">{{ form.score }}</span>
            <span class="text-xs text-[#999999]">积分</span>
          </div>
        </div>
      </div>

      <div class="waves">
        <h3 class="text-[#000000] py-3">选择套餐</h3>
        <div class="flex flex-wrap -mx-1">
          <div v-for="(packageItem, index) in packages" :key="index"
            class="integral-item w-[150px] h-[118px] p-3 flex flex-col justify-center items-center mx-1 mb-3 border border-[#e5e5e5] rounded-2xl bg-white"
            :class="{ active: selectedPackage && selectedPackage.id === packageItem.id }"
            @click="selectPackage(packageItem)">
            <div class="pt-1 pb-2">
              <span class="text-4xl text-[#fe4e00] font-bold" style="color: #fe4e00;font-weight: bold;">{{
    packageItem.price }}</span>
              <span>元</span>
            </div>
            <p class="text-sm text-[#666666]">
              <span class="text-[#e63400]">{{ packageItem.points }}</span>积分
            </p>
            <p class="text-xs text-[#999999]">[充值赠送{{ packageItem.bonus }}积分]</p>
          </div>
          <div
            class="integral-item w-[150px] h-[118px] p-3 flex flex-col justify-center items-center mx-1 mb-3 border border-[#e5e5e5] rounded-2xl bg-white"
            :class="{ active: selectedPackage && selectedPackage.id === null }" @click="selectPackage({ id: null })"
            style="color: #fe4e00;font-weight: bold;">
            <div class="pt-1 pb-2">
              <span class="text-3xl text-[#fe4e00] font-bold">其他</span>
              <span>金额</span>
            </div>
            <a-input class="outline-none leading-7 px-3 w-[90%] rounded-xl border border-[#e5e5e5] mx-[-10px] my-1"
              type="text" placeholder="请输入金额" v-model="form.other" @input="selectPackage({ id: null })"
              style="border: 1px solid #e5e5e5" />
          </div>
        </div>
      </div>

      <div class="flex justify-start items-center py-2 text-[#000000]">
        <span class="leading-6">确认金额：</span>
        <div>
          <span class="text-base text-[#fd802e]">{{ selectedPackage ? selectedPackage.price : 0 }}</span>
          <strong class="text-base">元</strong>
          <span class="text-xs text-[#ffb239]">[充值赠送{{ selectedPackage ? selectedPackage.bonus : 0 }}积分]</span>
        </div>
      </div>

      <div class="flex justify-between text-[#333333]" v-if="form2.type === 1">
        <div class="flex-1 flex justify-start">
          <div>支付宝支付：</div>
          <div class="flex-1">
            <div class="w-[160px] h-[160px] mb-4">
              <img class="w-full h-full" :src="form2.aliLogo" alt="" />
            </div>
            <div class="leading-6">
              <span>收款人：</span><span>{{ form2.ali_name }}</span>
            </div>
            <div class="leading-6">
              <span>收款账户：</span><span>{{ form2.ali_account }}</span>
            </div>
          </div>
        </div>
        <div class="flex-1">
          <a-form class="w-[80%]">
            <a-form-item label="转账人账号" required style="width: 550px;">
              <a-input placeholder="请输入转账人账号" v-model="form.ali_account" style="width: 400px;" />
            </a-form-item>
            <a-form-item label="转账人" required>
              <a-input placeholder="请输入转账人" v-model="form.ali_name" style="width: 400px;" />
            </a-form-item>
            <a-form-item label="转账截图" required>
              <a-upload list-type="picture-card" action="/" :limit="1" :file-list="fileList" v-model="form2.pay_image"
                :custom-request="customRequest" @change="uploadChange" image-preview>
              </a-upload>
            </a-form-item>
            <a-form-item>
              <a-button type="primary" class="w-[200px]" status="warning" @click="pushMoney">确认</a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>

      <div v-else>
        <div class="text-[#000000]">
          <div class="flex justify-start items-center">
            <span>支付宝支付：</span>
          </div>
        </div>
        <div class="flex justify-start items-center p-4 mt-4 bg-[#f7f7f7] relative" style="background-color:#f7f7f7">
          <div class="w-[160px] h-[160px] bg-white" style="background-color:white;width:160px;height:160px;">
            <img v-if="form.ali_img" :src="form.ali_img" alt="支付宝支付" style="width:160px;height:160px;" />
            <span v-else></span>
          </div>
          <div class="p-4 cursor-default" style="">
            <div class="flex justify-start items-center pb-4">
              <span>请使用支付宝扫码支付</span>
            </div>
            <p class="text-[#ee4425] p-1" style="color: #ee4425">
              注：请勿添加备注，否则将有可能不到账，请不要保存二维码，每次充值二维码不一样。
            </p>
            <p class="text-[#ee4425] p-1" style="color: #ee4425">
              10分钟内到账，请点击【支付宝充值未到账？点击监测找回账单】
            </p>
          </div>
          <div class="absolute top-1/2 right-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <a-button type="primary" @click="getPaymentCode">获取付款码</a-button>
          </div>
        </div>
        <div class="mt-3">
          <a-button type="text" status="warning" @click="goToShopTools(5)">已支付，前往查看</a-button>
        </div>
      </div>

      <div
        class="flex flex-row items-center w-full py-2.5 px-2.5 rounded border border-[#fe802d] border-solid bg-[#fdf6e9] text-xs mt-5 mb-5"
        style="background-color:#fdf6e9;width:100%;border:1px solid #fe802d">
        <div class="text-[#333333] leading-6" style="color: #333333">
          <div class="text-[#fd802e] text-base" style="color: #fd802e">温馨提示</div>
          <p>1、会员每日查询次数会在0点自动更新</p>
          <p>2、积分是无限时间的消耗完会员次数才会消耗积分</p>
          <p>3、会员支持多个电脑、手机同时登录</p>
          <p>4、套餐均为虚拟产品，一经售出，无法退款</p>
        </div>
      </div>
    </div>
  </a-modal>
  <!-- <div class="hid" v-show="false">
    <rigster :visible="showRegister.value"></rigster>
  </div> -->
</template>

<script>
import { ref, reactive } from 'vue';
import { getPayInfo, getPayQr, getUserInfo, getPayWay, pushPay, uploadFiles } from '@api';
import { Message } from '@arco-design/web-vue'; // 确保导入 Message 组件
import QRCode from 'qrcode';
import { useRouter } from 'vue-router';

export default {
  props: ['avatar', 'score', 'alipayImage'],
  setup (props) {
    const visible = ref(false);
    const form = reactive({
      avatar: props.avatar || '',
      score: props.score || 0,
      other: '',
      ali_img: []
    });

    const fileList = ref([]);

    const showRegister = reactive({ value: false });

    const uploadChange = (fileItemList) => {
      fileList.value = fileItemList.slice(0, 1);
    };

    const customRequest = async (options) => {
      const controller = new AbortController();
      const { onError, onSuccess, fileItem, name = 'file' } = options;
      const formData2 = new FormData();
      formData2.append(name, fileItem.file);

      try {
        const res = await uploadFiles(formData2, { controller });
        form2.pay_image = res.data.data.fullurl;
        console.log(res.data.data.fullurl);
        onSuccess(res);
      } catch (error) {
        onError(error);
      }
    };

    const router = useRouter();

    const goToShopTools = async (key) => {
      if (key) {
        Message.success('正在跳转');
        await router.push({ name: 'shopTools', params: { key } });
        visible.value = false;
      }
    };

    const pushMoney = async () => {
      try {
        const res = await pushPay({
          pay_id: form2.pay_id,
          ali_name: form.ali_name,
          ali_account: form.ali_account,
          pay_image: form2.pay_image,
          money: selectedPackage.value.price || 0
        });
        if (res.data.code === 20000 && selectedPackage.value.price !== 0) {
          Message.success(res.data.msg);
        } else {
          Message.warning(res.data.msg);
        }
      } catch (error) {
        Message.error('提交失败：', error);
      }
    };

    const getBannerImg = async () => {
      try {
        const infoData = await getUserInfo();
        form.username = infoData.data.username;
        form.memberEndTime = infoData.data.member_end_time;
        form.avatar = infoData.data.avatar;
        form.score = infoData.data.score;
      } catch (error) {
        console.log(error);
      }
    };

    const form2 = reactive({
      type: '',
      aliLogo: '',
      ali_name: '',
      ali_account: '',
      pay_id: '',
      pay_image: ''
    });

    const getPayWayInfo = async () => {
      try {
        const payWayRes = await getPayWay();
        if (!payWayRes || !payWayRes.data || !payWayRes.data.data) {
          throw new Error('Invalid payWayRes data structure');
        }
        form2.type = payWayRes.data.data.recharge_type;
        const qrUrl = payWayRes.data.data.ali_logo;
        form2.aliLogo = await QRCode.toDataURL(qrUrl);
        form2.ali_name = payWayRes.data.data.ali_name;
        form2.ali_account = payWayRes.data.data.ali_account;
        form2.pay_id = payWayRes.data.data.pay_id;
      } catch (error) {
        Message.error('不存在收款账号，请联系管理员');
      }
    };

    const selectedPackage = ref(null);
    const packages = ref([]);

    const handleClick = () => {
      const token = localStorage.getItem('token');
      if (!token) {
        showRegister.value = true;
        Message.warning('请先登录');
      } else {
        visible.value = true;
        fetchPackages();
        getBannerImg();
        getPayWayInfo();
      }
    };

    const fetchPackages = async () => {
      try {
        const payRes = await getPayInfo();
        const data = payRes.data.data;

        packages.value = [
          {
            id: 1,
            price: data.money_1,
            points: data.recharge_1,
            bonus: data.send_1
          },
          {
            id: 2,
            price: data.money_2,
            points: data.recharge_2,
            bonus: data.send_2
          },
          {
            id: 3,
            price: data.money_3,
            points: data.recharge_3,
            bonus: data.send_3
          },
          {
            id: 4,
            price: data.money_4,
            points: data.recharge_4,
            bonus: data.send_4
          },
          {
            id: 5,
            price: data.money_5,
            points: data.recharge_5,
            bonus: data.send_5
          }
        ];
      } catch (error) {
        Message.error('获取套餐信息失败', error);
      }
    };

    const selectPackage = (packageItem) => {
      if (packageItem.id === null) {
        selectedPackage.value = {
          id: null,
          price: form.other || 0,
          points: 0,
          bonus: 0
        };
      } else {
        selectedPackage.value = packageItem;
      }
    };

    const getPaymentCode = async () => {
      const amount = selectedPackage.value?.price || form.other;
      if (amount) {
        try {
          const response = await getPayQr({ money: amount });
          if (response && response.data && response.data.data) {
            const qrUrl = response.data.data.ali_logo;
            form.ali_img = await QRCode.toDataURL(qrUrl);
          } else {
            Message.error('支付二维码数据格式错误');
          }
        } catch (error) {
          Message.error('获取支付二维码失败: ' + error.message);
        }
      } else {
        Message.error('请先选择金额');
      }
    };

    return {
      visible,
      form,
      selectedPackage,
      packages,
      handleClick,
      selectPackage,
      getPaymentCode,
      getBannerImg,
      goToShopTools,
      form2,
      pushMoney,
      customRequest,
      fileList,
      uploadChange,
      showRegister
    };
  }
};
</script>

<style>
.charge .arco-modal-body {
  position: relative;
  background: linear-gradient(to bottom, rgba(255, 165, 0, 0.05) 90%, rgba(255, 255, 255, 1)100%);
  padding: 24px 20px;
  overflow: auto;
  color: var(--color-text-1);
  font-size: 14px;
}
</style>
<style scoped>
.arco-modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  padding: 0 20px;
  background: rgba(255, 247, 238);
  border-bottom: 1px solid var(--color-neutral-3);
}

.recharge-content {
  padding: 20px;
  /* 添加内边距以改善视觉效果 */
  border-radius: 10px;
  /* 圆角 */
}

.integral-item.active {
  border: 2px solid #fd802e;
  /* 激活时的边框颜色 */
  background-color: rgba(255, 155, 13, 0.25);
  /* 激活时的背景颜色 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* 激活时的阴影效果 */
}

.integral-item {
  transition: background-color 0.3s ease, border 0.3s ease;
  /* 平滑过渡效果 */
  background-size: cover;
  /* 确保背景图像覆盖整个元素 */
  background-position: center;
  /* 背景居中 */
}
</style>
