<template>
  <div class="flex-1">
    <div class="mb-5 bg-white rounded-lg px-5 pb-5">
      <div class="flex justify-start items-center h-[68px] border-b-[1px] border-[#e5e5e5] capitalize"
        style="padding: 10px;">
        <div class="flex-auto flex justify-start items-center">
          <div class="font-bold text-[#fe802d] text-2xl mr-4" style="color: #fe802d">{{ title }}</div>
          <div class="flex-1">
            <div class="tool-header-content">
              <p>优惠套餐：购买套餐可低至 0.5 积分/次</p>
            </div>
          </div>
        </div>
        <div class="tool-header-right text-base text-[#333]">
          <div class="flex justify-start items-center mr-3">
            <img class="w-6 h-6 mr-2" src="/zhaoyaojing_files/biaoti-icon1.png" alt="" />
            <span>单次：0积分</span>
          </div>
          <div class="flex justify-start items-center">
            <img class="w-6 h-6 mr-2" src="/zhaoyaojing_files/biaoti-icon2.png" alt="" />
            <span>会员当日次数：0/0次</span>
          </div>
        </div>
      </div>

      <el-form class="el-form--label-left mt-10">
        <el-form-item label="打标方式" class="asterisk-left">
          <el-radio-group v-model="selectedId" style="margin-left: 40px;">
            <el-radio :label="1">无痕秒单</el-radio>
            <el-radio :label="2">批量无痕秒单</el-radio>
          </el-radio-group>
        </el-form-item>

        <div v-for="(link, index) in links" :key="index">
          <div :class="{'border-b': index >= 1, 'border-t': index >= 1}">
          <el-form-item label="商品链接" class="asterisk-left flex justify-start items-center mt-10">
            <el-input v-model="link.url" placeholder="请输入商品链接" class="flex-1" style="margin-left: 40px;height: 40px;"></el-input>
            <el-button type="outline" class="ml-5 border-[#fe802d]" style="border-color: #FEC799;background-color: rgb(255, 244, 235);color: #fe802d;" @click="addLink(link)" size="large">获取SKU</el-button>
            <div class="text-red w-[310px]" style="margin-right:40px ;">（获取SKU，额外收取2积分）</div>
          </el-form-item>

          <el-form-item label="数量" class="asterisk-left flex items-center mt-10">
            <el-input-number v-model="link.number" :min="1" :max="100" style="margin-left: 70px;height: 40px;"></el-input-number>
            <a-button v-if="index >=1 " size="large" type="text" class="ml-2" @click="removeLink(index)" style=" color: #fe802d;font-size: 24px;">
            <template #icon>
              <icon-delete/>
            </template>
            </a-button>
          </el-form-item>
        </div>
        </div>

        <el-form-item class="asterisk-left">
          <el-button type="text" icon="el-icon-plus" style="margin-top: 15px;margin-left: 90px;font-size: 16px;" @click="addLinkField"><icon-plus-circle style="font-size: 18px;"/> <span style="padding-left: 10px;">加链接，最多添加20个</span></el-button>
        </el-form-item>

        <el-form-item class="asterisk-left">
          <div class="w-full flex justify-center items-center">
            <el-button type="primary" class="w-[200px]" @click="addLink" style="height: 40px;">生成二维码</el-button>
          </div>
        </el-form-item>
        <a-image :src="qrData.imgSrc"></a-image>
      </el-form>
    </div>

    <div class="mb-6">
      <div class="bg-white rounded-lg px-5 pb-5">
        <div class="flex justify-start items-center h-[68px] border-[#e5e5e5] capitalize border-none">
          <div class="flex-auto flex justify-start items-center">
            <div>
              <div class="flex justify-start items-center"><img src="../../assets/img/zyj-icon1-jilu.png" alt=""><span
                  class="text-xl text-[#333] font-bold p-2">生成记录</span></div>
            </div>
            <div class="font-bold text-[#fe802d] text-2xl mr-4"></div>
            <div class="flex-1"></div>
          </div>
          <div></div>
        </div>
        <a-table :columns="columns" :data="inviteDetails" row-key="id" pagination="false">
          <template #qrCode="{ record }">
            <QRcodeOpen :url="record.content" />
          </template>
        </a-table>
        <el-pagination :total="total" v-model:current-page="currentPage" :page-size="pageSize"
          @size-change="handlePageSizeChange" @current-change="handlePageChange"></el-pagination>
      </div>
    </div>
    <div class="mb-6">
      <div class="bg-white rounded-lg px-5 pb-5">
        <el-tabs v-model="activeTab" class="mt-4" style="text-align:left">
          <el-tab-pane label="功能介绍" class="tip-text">
            <p>无痕秒单就是显示成交关键词，但是成交来源里面什么都不显示，没有是任何痕迹，快速带动关键词搜索权重，获取更多展示</p>
            <p>对于店铺新品破零或者只是增加商品的基础销量权重梦无痕下单直接越过浏览过程，直接下单</p>
            <p>生意参谋后台数据只显示成交关键词，成交来源里面什么都不显示，没有任何痕迹，这样可以快速带动关键词搜索权重，提高店铺流量</p>
          </el-tab-pane>
          <el-tab-pane label="使用场景" class="tip-text">
            <p>1.对于店铺新品快速提升基础销量权重，无痕BD就是直接越过留来你过程，直接下单，没有任何痕迹对新品更加安全</p>
            <p>2.对于近期店铺需要谨慎BD使用无痕下单安全性更高，每天一两百单内非常安全（让用户直接下单不要浏览自己的宝贝，这样会非常安全）不计入访客，转化率会越高，甚至超过100%无搜索权重，只累计基础销量权重</p>
            <p>3.无痕下单的作用是为了增加权重获取更多流量，是为了做店铺运营，将流量转化为订单</p>
            <p>4.具体的无痕BD就是直接越过浏览过程,直接下单，没有任何痕迹，即使通过生意参谋流量路径，访客人数等数据都无法显示，这样可以快速带动关键词搜索权重，提高店铺流量</p>
            <p>5.新品商家，帮助店铺商品快速破零，获得更多的流量和曝光</p>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, reactive, onMounted, watch } from 'vue';
import { noTraceSupplement, getNoTraceLog } from '@api';
import QRcodeOpen from './component/showQrCode.vue';
import { Message } from '@arco-design/web-vue';

export default {
  components: {
    QRcodeOpen
  },
  props: {
    id: {
      type: [Number, Object], // 根据需要调整类型
      required: true
    }
  },
  setup (props) {
    // reactive and ref for managing state
    const links = ref([{ url: '', number: 1 }]);
    const form = reactive({
      id: props.id
    });
    const selectedId = ref(form.id || 1);
    const inviteDetails = ref([]);
    const pageSize = ref(20);
    const currentPage = ref(1);

    // Define the columns for the table
    const columns = [
      {
        title: 'SKU',
        dataIndex: 'sku',
        key: 'product_id',
        render: (text) => {
          const paramField = text.record ? text.record.param : null;

          if (typeof paramField === 'string') {
            try {
              const parsed = JSON.parse(paramField);
              return parsed.product_id || '无商品ID';
            } catch (error) {
              console.error('解析错误:', error);
              return '解析错误';
            }
          }
          return '无参数';
        }
      },
      {
        title: '商品ID',
        dataIndex: 'url',
        align: 'center'
      },
      {
        title: '生成时间',
        dataIndex: 'create_time',
        key: 'create_time',
        sortable: { sortDirections: ['descend'], align: 'center' },
        align: 'center'
      },
      {
        title: '操作',
        dataIndex: 'content',
        key: 'content',
        align: 'center',
        slotName: 'qrCode'
      }
    ];

    // computed property for dynamic title based on selectedId
    const title = computed(() => {
      return selectedId.value === 1 ? '淘宝无痕做单' : '批量淘宝无痕秒单';
    });

    // function to add new link fields
    const addLinkField = () => {
      if (links.value.length < 20) {
        links.value.push({ url: '', number: 1 });
      } else {
        alert('最多添加20个链接');
      }
    };

    // function to remove a link field
    const removeLink = (index) => {
      links.value.splice(index, 1);
    };

    // reactive object to hold QR data
    const qrData = reactive({ imgSrc: '' });

    // function to handle adding a link and generating QR code
    const addLink = async () => {
      const payload = {
        list: links.value.map((link) => ({
          product_id: link.url,
          count: link.number
        }))
      };

      try {
        const accRes = await noTraceSupplement(payload);
        if (accRes.data.code !== 20000) {
          Message.warning(accRes.data.msg);
        } else {
          Message.success(accRes.data.msg);
        }
        await getList();
      } catch (error) {
        console.error('获取SKU时出错:', error);
      }
    };

    // function to fetch list
    const getList = async () => {
      try {
        const getLog = await getNoTraceLog({
          size: pageSize.value,
          page: currentPage.value,
          platform_id: 1
        });
        inviteDetails.value = getLog.data.data.list;
      } catch (error) {
        console.error('获取列表时出错:', error);
      }
    };

    // watch for changes in props.id and update selectedId accordingly
    watch(
      () => props.id,
      (newId) => {
        selectedId.value = newId || 1; // update selectedId when id changes
      }
    );

    onMounted(() => {
      getList();
    });

    return {
      links,
      selectedId,
      columns,
      title,
      addLinkField,
      removeLink,
      qrData,
      addLink,
      pageSize,
      currentPage,
      inviteDetails
    };
  }
};
</script>

<style scoped>
/* 在此处添加你的样式 */
</style>
