<style scoped>
.arco-btn-text:hover,
.arco-btn-text[type='button']:hover,
.arco-btn-text[type='submit']:hover {
  background-color: #fff6e6 !important;
  border-color: transparent;
}
</style>
<template>
  <a-button @click="handleClick"
    type="text" status="warning" style="width: 100%;">充值使用</a-button>
  <a-modal v-model:visible="visible" @cancel="handleCancel" :on-before-ok="handleBeforeOk" :simple="true" width="auto">
    <a-row style="padding-bottom: 3rem">
      <a-col>
        <span style="padding-right: 2rem">佣金余额:</span>
        <a-tag color="orangered">{{ form.score }}</a-tag>
      </a-col>
    </a-row>
    <a-row>
      <a-col>
        <span style="padding-right: 2rem">充值金额:</span>
        <a-input :style="{ width: '320px' }" allow-clear v-model="form.money" />
      </a-col>
    </a-row>
    <!-- <a-row>
      <a-col>
        <a-button @click="showQr">充值</a-button>
      </a-col>
    </a-row> -->
  </a-modal>
</template>

<script>
import { ref, reactive } from 'vue';
import { getUserInfo, commissionToMoney } from '@api';
import { Message } from '@arco-design/web-vue';

export default {
  setup () {
    const visible = ref(false);
    const form = reactive({
      score: '',
      money: '',
      url: ''
    });

    const getInfo = async () => {
      try {
        const res = await getUserInfo();
        form.score = res.data.commission;
      } catch (error) {
        console.log(error);
      }
    };

    const handleClick = () => {
      visible.value = true;
      getInfo();
    };
    const handleBeforeOk = async () => {
      try {
        const res = await commissionToMoney({ money: form.money });
        if (res.data.code !== 20000) {
          Message.warning(res.data.msg)
        } else {
          await new Promise((resolve) => setTimeout(resolve, 3000));
          return true;
        }
      } catch (error) {
        Message.error(error);
      }
    };
    const handleCancel = () => {
      visible.value = false;
    };

    return {
      visible,
      form,
      handleClick,
      handleBeforeOk,
      handleCancel,
      getInfo
    }
  }
}
</script>
