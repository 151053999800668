<template>
  <div class="flex-1">
    <div class="mb-6">
      <div class="bg-white rounded-lg px-5 pb-5">
        <!-- 标题区域 -->
        <div class="flex justify-start items-center h-[68px] border-b-[1px] border-[#e5e5e5] capitalize">
          <div class="flex-auto flex justify-start items-center">
            <div></div>
            <div class="font-bold text-[#fe802d] text-2xl mr-4" style="color:#fe802d;">旺旺号</div>
            <div class="flex-1">
              <div class="flex justify-start items-center">
                <span class="px-4 border-l border-[#e5e5e5] font-500 text-[#333] text-xl">QQ号/微信号</span>
                <div class="tool-header-content">
                  <p>优惠套餐：购买套餐可低至 0.5 积分/次</p>
                </div>
              </div>
            </div>
          </div>
          <div class="tool-header-right text-base text-[#333]">
            <div class="flex justify-start items-center mr-3">
              <img class="w-6 h-6 mr-2" src="/zhaoyaojing_files/biaoti-icon1.png" alt="" />
              <span>单次：2积分</span>
            </div>
            <div class="flex justify-start items-center">
              <img class="w-6 h-6 mr-2" src="/zhaoyaojing_files/biaoti-icon2.png" alt="" />
              <span>会员当日次数：{{ memberInfo.dailyLimit }}/{{ memberInfo.maxDailyLimit }}次</span>
            </div>
          </div>
        </div>
        <!-- 搜索栏 -->
        <div class="mt-5 mb-3 flex justify-center items-center">
          <el-input v-model="searchInput" placeholder="输入淘宝号" class="!w-[60%] m-2" size="large" clearable></el-input>
          <a-space>
          <el-button type="primary" size="large" @click="handleSearch">查询</el-button>
          <a-button type="outline" status="warning"  size="large" style="height: 38px; border-radius: 5px;background-color: rgb(255, 244, 235)" plain @click="goToShopTools(12)">黑号打标</a-button>
        </a-space>
        </div>
      </div>
    </div>

    <div class="mb-6">
      <div class="bg-white rounded-lg px-5 pb-5">
        <div class="flex justify-start items-center h-[68px] border-[#e5e5e5] capitalize border-none">
          <div class="flex-auto flex justify-start items-center">
            <div>
              <div class="flex justify-start items-center">
                <img src="../../assets/img/dsgj-icon2.png" alt="" />
                <span class="text-xl text-[#333] font-bold px-1">基本信息</span>
              </div>
            </div>
          </div>
        </div>

        <a-descriptions bordered column="4">
          <a-descriptions-item v-for="column in columns" :key="column.dataIndex" :label="column.title">
            {{ tableData.length > 0 ? tableData[0][column.dataIndex] : '' }}
          </a-descriptions-item>
        </a-descriptions>
      </div>
    </div>

    <div class="mb-6">
      <div class="bg-white rounded-lg px-5 pb-5 pb-4">

        <div class="flex justify-start items-center h-[68px] border-[#e5e5e5] capitalize border-none">
          <div class="flex-auto flex justify-start items-center">
            <div>
              <div class="flex justify-start items-center">
                <img src="../../assets/img/dsgj-icon4.png" alt="" />
                <span class="text-xl text-[#333] font-bold px-1">标记情况</span>
              </div>
            </div>
            <div class="font-bold text-[#fe802d] text-2xl mr-4"></div>
            <div class="flex-1"></div>
          </div>
        </div>

        <a-table :columns="tipColumns" :data="tableData" stripe bordered>
        </a-table>
      </div>
    </div>

    <div class="mb-6">
      <div class="bg-white rounded-lg px-5 pb-5 pb-4">

        <div class="flex justify-start items-center h-[68px] border-[#e5e5e5] capitalize border-none">
          <div class="flex-auto flex justify-start items-center">
            <div>
              <div class="flex justify-start items-center">
                <img src="../../assets/img/dsgj-icon8.png" alt="" />
                <span class="text-xl text-[#333] font-bold px-1">近期购买记录</span>
              </div>
            </div>
            <div class="font-bold text-[#fe802d] text-2xl mr-4"></div>
            <div class="flex-1"></div>
          </div>
        </div>
        <a-table :columns="buyColumns" :data="buyData" bordered>
        </a-table>
      </div>
    </div>

    <div class="mb-6">
      <div class="bg-white rounded-lg px-5 pb-5 pb-4">
        <div class="flex justify-start items-center h-[68px] border-[#e5e5e5] capitalize border-none">
          <div class="flex-auto flex justify-start items-center">
            <div>
              <div class="flex justify-start items-center"><img src="../../assets/img/dsgj-icon2.png" alt="" /><span
                  class="text-xl text-[#333] font-bold px-1">安全等级</span></div>
            </div>
            <div class="font-bold text-[#fe802d] text-2xl mr-4"></div>
            <div class="flex-1"></div>
          </div>
          <div></div>
        </div>
        <div>
          <div class="flex flex-col justify-center items-center mb-3">
            <div class="w-[80px] h-[80px]"><img class="w-full h-full" src="../../assets/img/dsgj-icon11.png" alt="">
            </div>
            <div>
              <div class="arco-rate arco-rate-readonly">
                <div class="arco-rate-character" role="radio" aria-checked="false" aria-setsize="5" aria-posinset="1">
                  <div class="arco-rate-character-left"><svg viewBox="0 0 48 48" fill="none"
                      xmlns="http://www.w3.org/2000/svg" stroke="currentColor" class="arco-icon arco-icon-star-fill"
                      stroke-width="4" stroke-linecap="butt" stroke-linejoin="miter">
                      <path
                        d="M22.683 5.415c.568-1.043 2.065-1.043 2.634 0l5.507 10.098a1.5 1.5 0 0 0 1.04.756l11.306 2.117c1.168.219 1.63 1.642.814 2.505l-7.902 8.359a1.5 1.5 0 0 0-.397 1.223l1.48 11.407c.153 1.177-1.058 2.057-2.131 1.548l-10.391-4.933a1.5 1.5 0 0 0-1.287 0l-10.39 4.933c-1.073.51-2.284-.37-2.131-1.548l1.48-11.407a1.5 1.5 0 0 0-.398-1.223L4.015 20.89c-.816-.863-.353-2.286.814-2.505l11.306-2.117a1.5 1.5 0 0 0 1.04-.756l5.508-10.098Z"
                        fill="currentColor" stroke="none"></path>
                    </svg></div>
                  <div class="arco-rate-character-right"><svg viewBox="0 0 48 48" fill="none"
                      xmlns="http://www.w3.org/2000/svg" stroke="currentColor" class="arco-icon arco-icon-star-fill"
                      stroke-width="4" stroke-linecap="butt" stroke-linejoin="miter">
                      <path
                        d="M22.683 5.415c.568-1.043 2.065-1.043 2.634 0l5.507 10.098a1.5 1.5 0 0 0 1.04.756l11.306 2.117c1.168.219 1.63 1.642.814 2.505l-7.902 8.359a1.5 1.5 0 0 0-.397 1.223l1.48 11.407c.153 1.177-1.058 2.057-2.131 1.548l-10.391-4.933a1.5 1.5 0 0 0-1.287 0l-10.39 4.933c-1.073.51-2.284-.37-2.131-1.548l1.48-11.407a1.5 1.5 0 0 0-.398-1.223L4.015 20.89c-.816-.863-.353-2.286.814-2.505l11.306-2.117a1.5 1.5 0 0 0 1.04-.756l5.508-10.098Z"
                        fill="currentColor" stroke="none"></path>
                    </svg></div>
                </div>
                <div class="arco-rate-character" role="radio" aria-checked="false" aria-setsize="5" aria-posinset="2">
                  <div class="arco-rate-character-left"><svg viewBox="0 0 48 48" fill="none"
                      xmlns="http://www.w3.org/2000/svg" stroke="currentColor" class="arco-icon arco-icon-star-fill"
                      stroke-width="4" stroke-linecap="butt" stroke-linejoin="miter">
                      <path
                        d="M22.683 5.415c.568-1.043 2.065-1.043 2.634 0l5.507 10.098a1.5 1.5 0 0 0 1.04.756l11.306 2.117c1.168.219 1.63 1.642.814 2.505l-7.902 8.359a1.5 1.5 0 0 0-.397 1.223l1.48 11.407c.153 1.177-1.058 2.057-2.131 1.548l-10.391-4.933a1.5 1.5 0 0 0-1.287 0l-10.39 4.933c-1.073.51-2.284-.37-2.131-1.548l1.48-11.407a1.5 1.5 0 0 0-.398-1.223L4.015 20.89c-.816-.863-.353-2.286.814-2.505l11.306-2.117a1.5 1.5 0 0 0 1.04-.756l5.508-10.098Z"
                        fill="currentColor" stroke="none"></path>
                    </svg></div>
                  <div class="arco-rate-character-right"><svg viewBox="0 0 48 48" fill="none"
                      xmlns="http://www.w3.org/2000/svg" stroke="currentColor" class="arco-icon arco-icon-star-fill"
                      stroke-width="4" stroke-linecap="butt" stroke-linejoin="miter">
                      <path
                        d="M22.683 5.415c.568-1.043 2.065-1.043 2.634 0l5.507 10.098a1.5 1.5 0 0 0 1.04.756l11.306 2.117c1.168.219 1.63 1.642.814 2.505l-7.902 8.359a1.5 1.5 0 0 0-.397 1.223l1.48 11.407c.153 1.177-1.058 2.057-2.131 1.548l-10.391-4.933a1.5 1.5 0 0 0-1.287 0l-10.39 4.933c-1.073.51-2.284-.37-2.131-1.548l1.48-11.407a1.5 1.5 0 0 0-.398-1.223L4.015 20.89c-.816-.863-.353-2.286.814-2.505l11.306-2.117a1.5 1.5 0 0 0 1.04-.756l5.508-10.098Z"
                        fill="currentColor" stroke="none"></path>
                    </svg></div>
                </div>
                <div class="arco-rate-character" role="radio" aria-checked="false" aria-setsize="5" aria-posinset="3">
                  <div class="arco-rate-character-left"><svg viewBox="0 0 48 48" fill="none"
                      xmlns="http://www.w3.org/2000/svg" stroke="currentColor" class="arco-icon arco-icon-star-fill"
                      stroke-width="4" stroke-linecap="butt" stroke-linejoin="miter">
                      <path
                        d="M22.683 5.415c.568-1.043 2.065-1.043 2.634 0l5.507 10.098a1.5 1.5 0 0 0 1.04.756l11.306 2.117c1.168.219 1.63 1.642.814 2.505l-7.902 8.359a1.5 1.5 0 0 0-.397 1.223l1.48 11.407c.153 1.177-1.058 2.057-2.131 1.548l-10.391-4.933a1.5 1.5 0 0 0-1.287 0l-10.39 4.933c-1.073.51-2.284-.37-2.131-1.548l1.48-11.407a1.5 1.5 0 0 0-.398-1.223L4.015 20.89c-.816-.863-.353-2.286.814-2.505l11.306-2.117a1.5 1.5 0 0 0 1.04-.756l5.508-10.098Z"
                        fill="currentColor" stroke="none"></path>
                    </svg></div>
                  <div class="arco-rate-character-right"><svg viewBox="0 0 48 48" fill="none"
                      xmlns="http://www.w3.org/2000/svg" stroke="currentColor" class="arco-icon arco-icon-star-fill"
                      stroke-width="4" stroke-linecap="butt" stroke-linejoin="miter">
                      <path
                        d="M22.683 5.415c.568-1.043 2.065-1.043 2.634 0l5.507 10.098a1.5 1.5 0 0 0 1.04.756l11.306 2.117c1.168.219 1.63 1.642.814 2.505l-7.902 8.359a1.5 1.5 0 0 0-.397 1.223l1.48 11.407c.153 1.177-1.058 2.057-2.131 1.548l-10.391-4.933a1.5 1.5 0 0 0-1.287 0l-10.39 4.933c-1.073.51-2.284-.37-2.131-1.548l1.48-11.407a1.5 1.5 0 0 0-.398-1.223L4.015 20.89c-.816-.863-.353-2.286.814-2.505l11.306-2.117a1.5 1.5 0 0 0 1.04-.756l5.508-10.098Z"
                        fill="currentColor" stroke="none"></path>
                    </svg></div>
                </div>
                <div class="arco-rate-character" role="radio" aria-checked="false" aria-setsize="5" aria-posinset="4">
                  <div class="arco-rate-character-left"><svg viewBox="0 0 48 48" fill="none"
                      xmlns="http://www.w3.org/2000/svg" stroke="currentColor" class="arco-icon arco-icon-star-fill"
                      stroke-width="4" stroke-linecap="butt" stroke-linejoin="miter">
                      <path
                        d="M22.683 5.415c.568-1.043 2.065-1.043 2.634 0l5.507 10.098a1.5 1.5 0 0 0 1.04.756l11.306 2.117c1.168.219 1.63 1.642.814 2.505l-7.902 8.359a1.5 1.5 0 0 0-.397 1.223l1.48 11.407c.153 1.177-1.058 2.057-2.131 1.548l-10.391-4.933a1.5 1.5 0 0 0-1.287 0l-10.39 4.933c-1.073.51-2.284-.37-2.131-1.548l1.48-11.407a1.5 1.5 0 0 0-.398-1.223L4.015 20.89c-.816-.863-.353-2.286.814-2.505l11.306-2.117a1.5 1.5 0 0 0 1.04-.756l5.508-10.098Z"
                        fill="currentColor" stroke="none"></path>
                    </svg></div>
                  <div class="arco-rate-character-right"><svg viewBox="0 0 48 48" fill="none"
                      xmlns="http://www.w3.org/2000/svg" stroke="currentColor" class="arco-icon arco-icon-star-fill"
                      stroke-width="4" stroke-linecap="butt" stroke-linejoin="miter">
                      <path
                        d="M22.683 5.415c.568-1.043 2.065-1.043 2.634 0l5.507 10.098a1.5 1.5 0 0 0 1.04.756l11.306 2.117c1.168.219 1.63 1.642.814 2.505l-7.902 8.359a1.5 1.5 0 0 0-.397 1.223l1.48 11.407c.153 1.177-1.058 2.057-2.131 1.548l-10.391-4.933a1.5 1.5 0 0 0-1.287 0l-10.39 4.933c-1.073.51-2.284-.37-2.131-1.548l1.48-11.407a1.5 1.5 0 0 0-.398-1.223L4.015 20.89c-.816-.863-.353-2.286.814-2.505l11.306-2.117a1.5 1.5 0 0 0 1.04-.756l5.508-10.098Z"
                        fill="currentColor" stroke="none"></path>
                    </svg></div>
                </div>
                <div class="arco-rate-character" role="radio" aria-checked="false" aria-setsize="5" aria-posinset="5">
                  <div class="arco-rate-character-left"><svg viewBox="0 0 48 48" fill="none"
                      xmlns="http://www.w3.org/2000/svg" stroke="currentColor" class="arco-icon arco-icon-star-fill"
                      stroke-width="4" stroke-linecap="butt" stroke-linejoin="miter">
                      <path
                        d="M22.683 5.415c.568-1.043 2.065-1.043 2.634 0l5.507 10.098a1.5 1.5 0 0 0 1.04.756l11.306 2.117c1.168.219 1.63 1.642.814 2.505l-7.902 8.359a1.5 1.5 0 0 0-.397 1.223l1.48 11.407c.153 1.177-1.058 2.057-2.131 1.548l-10.391-4.933a1.5 1.5 0 0 0-1.287 0l-10.39 4.933c-1.073.51-2.284-.37-2.131-1.548l1.48-11.407a1.5 1.5 0 0 0-.398-1.223L4.015 20.89c-.816-.863-.353-2.286.814-2.505l11.306-2.117a1.5 1.5 0 0 0 1.04-.756l5.508-10.098Z"
                        fill="currentColor" stroke="none"></path>
                    </svg></div>
                  <div class="arco-rate-character-right"><svg viewBox="0 0 48 48" fill="none"
                      xmlns="http://www.w3.org/2000/svg" stroke="currentColor" class="arco-icon arco-icon-star-fill"
                      stroke-width="4" stroke-linecap="butt" stroke-linejoin="miter">
                      <path
                        d="M22.683 5.415c.568-1.043 2.065-1.043 2.634 0l5.507 10.098a1.5 1.5 0 0 0 1.04.756l11.306 2.117c1.168.219 1.63 1.642.814 2.505l-7.902 8.359a1.5 1.5 0 0 0-.397 1.223l1.48 11.407c.153 1.177-1.058 2.057-2.131 1.548l-10.391-4.933a1.5 1.5 0 0 0-1.287 0l-10.39 4.933c-1.073.51-2.284-.37-2.131-1.548l1.48-11.407a1.5 1.5 0 0 0-.398-1.223L4.015 20.89c-.816-.863-.353-2.286.814-2.505l11.306-2.117a1.5 1.5 0 0 0 1.04-.756l5.508-10.098Z"
                        fill="currentColor" stroke="none"></path>
                    </svg></div>
                </div>
              </div>
            </div>
          </div>
          <p class="flex justify-start items-center leading-6"><span
              class="text-[#32cd32] flex justify-center items-center"><i
                class="w-1 h-1 inline-block bg-[#32cd32] rotate-45 mr-2"></i> 安全：</span>
            根据获取到的多个检测维度得出的结果，该淘宝账号安全性高，不是职业差评师和黑名单账号，未出现在恶意中差评或者对店铺宝贝降权的隐患。 </p>
          <p class="flex justify-start items-center leading-6"><span
              class="text-[#32cd32] flex justify-center items-center"><i
                class="w-1 h-1 inline-block bg-[#32cd32] rotate-45 mr-2"></i> 一般：</span>
            淘宝账号安全等级一般。需要在交易中具体查看账号近段时间的评价记录及购买记录，确实淘宝号是否适合做任务。 </p>
          <div class="flex justify-start items-center leading-6">
            <div class="text-[#b91e1e] w-[60px] flex justify-center items-center self-start"><i
                class="w-1 h-1 inline-block bg-[#b91e1e] rotate-45 mr-2"></i>
              <div>危险：</div>
            </div>
            <p> 这样的账号可能被淘宝列入虚假交易的名单，与之交易的订单会被电商平台监控，会大大增加被淘宝隐形降权甚至直接降权的危险。不排除也有可能是职业差评师，请谨慎交易！ </p>
          </div>
          <p class="flex justify-start items-center leading-6"><span
              class="text-[#b91e1e] flex justify-center items-center"><i
                class="w-1 h-1 inline-block bg-[#b91e1e] rotate-45 mr-2"></i> 极度危险：</span> 危险！！！！！！ </p>
        </div>
      </div>
    </div>

    <div class="mb-6">
      <div class="bg-white rounded-lg px-5 pb-5 pb-4">

        <div class="flex justify-start items-center h-[68px] bordepitalizer-[#e5e5e5] ca border-none">
          <div class="flex-auto flex justify-start items-center">
            <div>
              <div class="flex justify-start items-center">
                <img src="../../assets/img/dsgj-icon8.png" alt="" />
                <span class="text-xl text-[#333] font-bold px-1">查询记录</span>
              </div>
            </div>
            <div class="font-bold text-[#fe802d] text-2xl mr-4"></div>
            <div class="flex-1"></div>
          </div>
        </div>
        <a-table :columns="CheckColumns" :data="checkData" bordered>
        </a-table>
      </div>
    </div>

    <div class="mb-6">
      <div class="bg-white rounded-lg px-5 pb-5">
        <a-tabs type="line" size="large">
          <a-tab-pane title="平台建议">
            <div class="tip-text" style="text-align: left;">
              <p>1、信誉（建议三心起）</p>
              <p>2、淘龄（建议一年起，300多天）</p>
              <p>3、实名认证（必须认证，建议普通会员以上）</p>
              <p>4、看标记情况（不是0建议不要用）</p>
              <p>5、降权处置（被查过虚假交易，被删除过评价，建议不用）</p>
              <p>6、看一周被几个商家查过数据（一般超过10就注意是不是周超了）</p>
              <p>7、收到好评率低于95%建议不要用</p>
              <p>8、信誉区间查询（周均点数）周平均收货单数超过5，建议不要用</p>
            </div>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { Message } from '@arco-design/web-vue';
import { checkAccount, getCheckList } from '@api';
import { useRouter } from 'vue-router';

export default {
  setup () {
    const router = useRouter();

    const goToShopTools = (key) => {
      router.push({ name: 'shopTools', params: { key } });
      console.log(key);
    };
    const searchInput = ref('');
    const memberInfo = ref({
      dailyLimit: 0,
      maxDailyLimit: 1
    });
    const columns = ref([
      { title: '旺旺号', dataIndex: 'wangwang' },
      { title: '商家信誉', dataIndex: 'seller_reputation' },
      { title: '实名认证', dataIndex: 'is_real' },
      { title: '注册天数', dataIndex: 'has_day' },
      { title: '淘龄', dataIndex: 'tao_ling' },
      { title: '给别人的好评率', dataIndex: 'send_good_rate' },
      { title: '收到的好评率', dataIndex: 'get_good_rate' },
      { title: '性别', dataIndex: 'sex' },
      { title: '买家信誉', dataIndex: 'buyer_reputation' },
      { title: '周单量', dataIndex: 'seller_total_per' },
      { title: '会员等级', dataIndex: 'viplevel' },
      { title: '所在地区', dataIndex: 'gender' },
      { title: '淘气值', dataIndex: 'gender' },
      { title: '查询时间', dataIndex: 'get_time' },
      { title: '总单约为', dataIndex: 'gender' }
    ]);
    const tableData = ref([]);
    const tipColumns = ref([
      {
        title: '打标类型',
        dataIndex: '打标次数'
      },
      {
        title: '跑单',
        dataIndex: 'type_1'
      },
      {
        title: '骚扰',
        dataIndex: 'type_2'
      },
      {
        title: '骗子',
        dataIndex: 'type_3'
      },
      {
        title: '打假',
        dataIndex: 'type_4'
      },
      {
        title: '差评',
        dataIndex: 'type_5'
      },
      {
        title: '淘客下单',
        dataIndex: 'type_6'
      },
      {
        title: '降权',
        dataIndex: 'type_7'
      }
    ]);
    const tipData = ref([

    ]);
    const buyColumns = ref([
      {
        title: '起始时间',
        dataIndex: 'start_date'
      },
      {
        title: '结束时间',
        dataIndex: 'end_date'
      },
      {
        title: '区间天数',
        dataIndex: 'differ_day'
      },
      {
        title: '收货量',
        dataIndex: 'differ_xinyu'
      },
      {
        title: '日均收货量'
      },
      {
        title: '周均收货量'
      }
    ]);

    const CheckColumns = ref([
      {
        title: '旺旺号',
        dataIndex: 'wangwang'
      },
      {
        title: '查询时间',
        dataIndex: 'get_time'
      },
      {
        title: '安全等级',
        dataIndex: 'level'
      }
    ]);

    const buyData = ref([]);

    const checkData = ref([])
    const getTable = async () => {
      try {
        const getTableList = await getCheckList({ type: 1 });
        checkData.value = getTableList.data.data.list;
      } catch (error) {
        Message.warning('系统错误');
      }
    };

    const handleSearch = async () => {
      try {
        const searchInputValue = searchInput.value;
        if (searchInputValue !== null) {
          const searchInfo = await checkAccount({ wangwang: searchInputValue, type: 1 });
          if (searchInfo.data.code !== 20000) {
            Message.warning(searchInfo.data.msg);
          } else {
            Message.success(searchInfo.data.msg)
          }

          checkData.value = searchInfo.data.data.list;
          // 包裹在数组中
          tableData.value = [searchInfo.data.data];
          buyData.value = searchInfo.data.data.buy_info
          getTable();
        }
      } catch (error) {
        Message.warning('系统错误');
      }
    };
    getTable();

    return {
      searchInput,
      memberInfo,
      columns,
      tableData,
      tipColumns,
      tipData,
      buyColumns,
      buyData,
      CheckColumns,
      checkData,
      getTable,
      handleSearch,
      goToShopTools
    };
  }
}
</script>

<style scoped>
.capitalize {
  text-transform: capitalize;
}

.border-\[\#e5e5e5\] {
  --tw-border-opacity: 1;
  border-color: rgb(229 229 229 / var(--tw-border-opacity));
}

.border-none {
  border-style: none;
}

.border-b-\[1px\] {
  border-bottom-width: 1px;
}

.justify-start {
  justify-content: flex-start;
}

.items-center {
  align-items: center;
}

.h-\[68px\] {
  height: 68px;
}

.flex {
  display: flex;
}

.text-\[\#b91e1e\] {
  --tw-text-opacity: 1;
  color: rgb(185 30 30 / var(--tw-text-opacity));
}

.self-start {
  align-self: flex-start;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.w-\[60px\] {
  width: 60px;
}

.flex {
  display: flex;
}

.bg-\[\#b91e1e\] {
  --tw-bg-opacity: 1;
  background-color: rgb(185 30 30 / var(--tw-bg-opacity));
}

.text-\[\#32cd32\] {
  --tw-text-opacity: 1;
  color: rgb(50 205 50 / var(--tw-text-opacity));
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.flex {
  display: flex;
}

.rotate-45,
.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-45 {
  --tw-rotate: 45deg;
}

.w-1 {
  width: .25rem;
}

.h-1 {
  height: .25rem;
}

.inline-block {
  display: inline-block;
}

.mr-2 {
  margin-right: .5rem;
}

.bg-\[\#32cd32\] {
  --tw-bg-opacity: 1;
  background-color: rgb(50 205 50 / var(--tw-bg-opacity));
}

.rotate-45,
.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-45 {
  --tw-rotate: 45deg;
}

.w-1 {
  width: .25rem;
}

.h-1 {
  height: .25rem;
}

.inline-block {
  display: inline-block;
}

.mr-2 {
  margin-right: .5rem;
}
</style>
