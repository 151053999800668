import { defineStore } from 'pinia';

export const useAuthStore = defineStore('token', {
  state: () => ({
    token: localStorage.getItem('token') || null
  }),
  actions: {
    setToken (token) {
      console.log(1111);
      this.token = token;
      localStorage.setItem('token', token);
    },
    clearToken () {
      this.token = null;
      localStorage.removeItem('token');
    }
  }
});
