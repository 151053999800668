import http from './http'
import { useAuthStore } from '@/stores/auth';

const getAuthHeaders = () => {
  const authStore = useAuthStore();
  return {
    headers: {
      Authorization: `Bearer ${authStore.token}`
    }
  };
};

export const getInfo = () => {
  return http.get('/base/system/getInfo', getAuthHeaders());
}

export const send = (params) => {
  return http.post('/sms/send', params, getAuthHeaders());
}

export const getSendGiftList = (params) => {
  return http.post('/toolbox/getGiftList', params, getAuthHeaders());
}

export const getGiftExpress = () => {
  return http.get('/base/system/getGiftExpress', getAuthHeaders());
}

export const getGift = (params) => {
  return http.post('/base/system/getGift', params, getAuthHeaders());
}

export const getCheckQrList = (params) => {
  return http.post('/toolbox/getCheckQrList', params, getAuthHeaders());
}

export const getCheckQr = (params) => {
  return http.post('/toolbox/getCheckQr', params, getAuthHeaders());
}

export const checkAccount = (params) => {
  return http.post('/toolbox/checkAccount', params, getAuthHeaders());
}

export const getCheckList = (params) => {
  return http.post('/user/getCheckList', params, getAuthHeaders());
}

export const checkAccounts = (params) => {
  return http.post('/toolbox/checkAccounts', params, getAuthHeaders());
}

export const blackAccount = (params) => {
  return http.post('/toolbox/blackAccount', params, getAuthHeaders());
}

export const getBlackList = (params) => {
  return http.post('/user/getBlackList', params, getAuthHeaders());
}

export const getTbScreen = (params) => {
  return http.post('/toolbox/getTbScreen', params, getAuthHeaders());
}

export const tbScreen = (params) => {
  return http.post('/toolbox/tbScreen', params, getAuthHeaders());
}

export const getYlbbScreenType = (params) => {
  return http.post('/toolbox/getYlbbScreenType', params, getAuthHeaders());
}

export const getDyScreenType = (params) => {
  return http.post('/toolbox/getDyScreenType', params, getAuthHeaders());
}

export const dyScreen = (params) => {
  return http.post('/toolbox/dyScreen', params, getAuthHeaders());
}

export const ylbbScreen = (params) => {
  return http.post('/toolbox/ylbbScreen', params, getAuthHeaders());
}

export const albbkspgScreen = (params) => {
  return http.post('/toolbox/albbkspgScreen', params, getAuthHeaders());
}

export const pddWhbd = (params) => {
  return http.post('/toolbox/pddWhbd', params, getAuthHeaders());
}

export const getScreenList = (params) => {
  return http.post('/toolbox/getScreenList', params, getAuthHeaders());
}

export const noTraceSupplement = (params) => {
  return http.post('/toolbox/noTraceSupplement', params, getAuthHeaders());
}

export const getXiaoHaoList = (params) => {
  return http.post('/toolbox/getXiaoHaoList', params, getAuthHeaders());
}

export const getNoTraceLog = (params) => {
  return http.post('/toolbox/getNoTraceLog', params, getAuthHeaders());
}

export const getFlowCategory = (params) => {
  return http.post('/toolbox/getFlowCategory', params, getAuthHeaders());
}

export const getGiftCate = (params) => {
  return http.post('/toolbox/getGiftCate', params, getAuthHeaders());
}

export const sendGift = (params) => {
  return http.post('/toolbox/sendGift', params, getAuthHeaders());
}

export const updateSend = (params) => {
  return http.post('/user/updateSend', params, getAuthHeaders());
}

export const getPushType = (params) => {
  return http.post('/toolbox/getPushType', params, getAuthHeaders());
}

export const pushTask = (params) => {
  return http.post('/toolbox/pushTask', params, getAuthHeaders());
}

export const getFlowEntranceCate = (params) => {
  return http.post('/toolbox/getFlowEntranceCate', params, getAuthHeaders());
}

export const sendFlowEntrance = (params) => {
  return http.post('/toolbox/sendFlowEntrance', params, getAuthHeaders());
}

export const getFlowEntranceList = (params) => {
  return http.post('/toolbox/getFlowEntranceList', params, getAuthHeaders());
}

export const updateAli = (params) => {
  return http.post('/user/updateAli', params, getAuthHeaders());
}

export const pushMoney = (params) => {
  return http.post('/user/pushMoney', params, getAuthHeaders());
}

export const commissionToMoney = (params) => {
  return http.post('/user/commissionToMoney', params, getAuthHeaders());
}

export const getPddScreenType = (params) => {
  return http.post('/toolbox/getPddScreenType', params, getAuthHeaders());
}

export const pddScreen = (params) => {
  return http.post('/toolbox/pddScreen', params, getAuthHeaders());
}

export const getFlowCategorys = (params) => {
  return http.post('/toolbox/getFlowCategorys', params, getAuthHeaders());
}

export const register = (params) => {
  return http.post('/user/register', params, getAuthHeaders());
}

export const login = (params) => {
  return http.post('/user/login', params, getAuthHeaders());
}

export const buyMember = (params) => {
  return http.post('/user/buyMember', params, getAuthHeaders());
}

export const getUserInfo = async (params) => {
  const authStore = useAuthStore();
  try {
    const response = await http.post('/user/getUserInfo', params, {
      headers: {
        Authorization: `Bearer ${authStore.token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Logout failed:', error);
    throw error;
  }
};

export const getConsumeList = (params) => {
  return http.post('/user/getConsumeList', params, getAuthHeaders());
}

export const getPayList = (params) => {
  return http.post('/user/getPayList', params, getAuthHeaders());
}

export const getMemberList = (params) => {
  return http.post('/user/getMemberList', params, getAuthHeaders());
}

export const getInviteList = (params) => {
  return http.post('/user/getInviteList', params, getAuthHeaders());
}

export const getPushLog = (params) => {
  return http.post('/user/getPushLog', params, getAuthHeaders());
}

export const getPayInfo = (params) => {
  return http.post('/user/getPayInfo', params, getAuthHeaders());
}

export const getPayQr = (params) => {
  return http.post('/user/getPayQr', params, getAuthHeaders());
}

export const getUseTime = (params) => {
  return http.post('/user/getUseTime', params, getAuthHeaders());
}

export const getNoticeList = (params) => {
  return http.post('/base/system/getNoticeList', params, getAuthHeaders());
}

export const addFeedBack = (params) => {
  return http.post('/user/addFeedBack', params, getAuthHeaders());
}

export const getFeedList = (params) => {
  return http.post('/user/getFeedList', params, getAuthHeaders());
}

export const getFlowList = (params) => {
  return http.post('/toolbox/getFlowList', params, getAuthHeaders());
}

export const getMemberInfo = (params) => {
  return http.post('/user/getMemberInfo', params, getAuthHeaders());
}

export const getArticleList = (params) => {
  return http.post('/base/system/getArticleList', params, getAuthHeaders());
}

export const getApiList = (params) => {
  return http.post('/base/blade/getApiList', params, getAuthHeaders());
}

export const getApiInfo = (params) => {
  return http.post('/base/blade/getApiInfo', params, getAuthHeaders());
}

export const captcha = (params) => {
  return http.post('/common/captcha', params, getAuthHeaders());
}

export const resetpwd = (params) => {
  return http.post('/user/resetpwd', params, getAuthHeaders());
}

export const getAllPushList = (params) => {
  return http.post('/toolbox/getAllPushList', params, getAuthHeaders());
}

export const getWwScreenList = (params) => {
  return http.post('/toolbox/getWwScreenList', params, getAuthHeaders());
}

export const getPddScreenList = (params) => {
  return http.post('/toolbox/getPddScreenList', params, getAuthHeaders());
}

export const pushFlow = (params) => {
  return http.post('/toolbox/pushFlow', params, getAuthHeaders());
}

export const getPayWay = (params) => {
  return http.post('/user/getPayWay', params, getAuthHeaders());
}

export const pushPay = (params) => {
  return http.post('user/pushPay', params, getAuthHeaders());
}

export const uploadFiles = (params) => {
  return http.post('/common/upload', params, getAuthHeaders());
}

export const logout = async (params) => {
  const authStore = useAuthStore();
  try {
    const response = await http.post('/user/logout', params, {
      headers: {
        Authorization: `Bearer ${authStore.token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Logout failed:', error);
    throw error;
  }
};
